import React, { useState } from "react";
import { ApiServiceClass } from "../components/api-service.js";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    SvgIcon,
} from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';


export const DeleteEmployee = (props) => {
    const {
        userId, 
        requesterId,
        fetchData
    } = props;
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleOpen = () => setDialogOpen(true);
    const handleClose = () => setDialogOpen(false);

    const removeUser = async (userId, requesterId) => {
        // Call the deleteEmployee function if the user confirms
        console.log('removeUser')
        console.log(userId)
        console.log(requesterId)
        handleClose();
        try {
            var requestBody = {
                "UserId": userId,
                "RequesterUserId": requesterId,
            }
            const data = await ApiServiceClass.DeleteStaffMember(requestBody);
            toast.success('Employee Removed')
            fetchData();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        console.log('removeUser')
    }

    return (
        <>
            <Button
                startIcon={
                    <SvgIcon fontSize="small">
                        <DeleteIcon />
                    </SvgIcon>
                }
                variant="contained"
                size="xs"
                onClick={handleOpen}
                style={{ cursor: 'pointer', marginRight: '0px', backgroundColor: '#BB1B16' }}
            >
                Delete
            </Button>

            <Dialog open={isDialogOpen} onClose={handleClose}
                PaperProps={{ style: { borderRadius: '5px', width: '400px' } }}>
                <DialogTitle style={{ paddingBottom: '5px', textAlign: 'center' }}>
                    Remove User
                </DialogTitle>
                <DialogContent style={{
                    paddingBottom: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    Are you sure you want to remove this user?
                </DialogContent>
                <DialogActions style={{
                    paddingBottom: '25px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Button
                        onClick={() => removeUser(userId, requesterId)}
                        color="primary"
                        style={{
                            width: '30%',
                            marginRight: '35px',
                            borderRadius: '5px'
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        style=
                        {{
                            width: '30%',
                            borderRadius: '5px'
                        }}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

DeleteEmployee.propTypes = {
    userId: PropTypes.number,
    requesterId: PropTypes.number,
    fetchData: PropTypes.func,
}