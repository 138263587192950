import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import LogoutIcon from "../tools/icons/logout-icon.svg";


const CenteredDialogActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "center",
});

const LogoutButton = ({navOpen}) => {
  const navigate = useNavigate();
  const [isLogoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const handleOpen = () => setLogoutDialogOpen(true);
  const handleClose = () => setLogoutDialogOpen(false);
  // console.log(navOpen)

  const handleLogout = () => {
    console.log("Logout button clicked!");
    localStorage.removeItem("companyDetails");
    localStorage.removeItem("loginData");
    navigate("/Login");
  };

  return (
    <>
      <ListItemButton onClick={handleOpen} variant="outlined">
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Center vertically
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"

            style={{ display: 'flex', alignItems: 'center' }}

          >
            <image href={LogoutIcon} width="20" height="20" alt="Logo" />
          </svg>

          {navOpen ? (<ListItemText
            primary="Sign Out"
            primaryTypographyProps={{
              fontFamily: "Poppins-Medium",
              color: "#171663",
              marginLeft: "20px",
              fontSize: "14px",
              alignItems: "center",
            }}/>) : null}
        </Box>
      </ListItemButton>

      <Dialog
        open={isLogoutDialogOpen}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: "5px", width: "400px" } }}
      >
        <DialogTitle style={{ paddingBottom: "5px", textAlign: "center" }}>
          Logout
        </DialogTitle>
        <DialogContent
          style={{
            paddingBottom: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Are you sure you want to logout?
        </DialogContent>
        <CenteredDialogActions
          style={{
            paddingBottom: "25px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleLogout}
            color="primary"
            style={{ width: "30%", marginRight: "35px", borderRadius: "5px" }}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ width: "30%", borderRadius: "5px" }}
          >
            No
          </Button>
        </CenteredDialogActions>
      </Dialog>
    </>
  );
};

export default LogoutButton;
