import React from "react";
import Styles from "../resources/styles";
import FontBox from "./sharedcomponents/FontBox";

function fontsContent({ onCancel, onSave }) {
  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Top Banner</div>
        <div style={Styles.content}>
          Font style
          <FontBox />
        </div>

        <div style={Styles.sectionTitle}>Main Content Section</div>
        <div style={Styles.content}>
          Font Style
          <FontBox />
        </div>
        <div style={Styles.sectionTitle}>Buttons</div>
        <div style={Styles.content}>
          Font Style
          <FontBox />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.cancelBtn} onClick={onCancel}>
          Reset
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default fontsContent;
