import QuestionMarkIcon from "@heroicons/react/24/solid/QuestionMarkCircleIcon"; // Import the question mark icon
import React from "react";
import {
    Tooltip,
} from "@mui/material";


const MyTooltip = ({ title }) => {
    return (
        <Tooltip title={title}>
            <QuestionMarkIcon
                style={{
                    // bottom: -8,
                    margin: "8px", // Add some margin for better spacing
                    cursor: "help",
                    width: 20, // Adjust the width as needed
                    height: 20, // Adjust the height as needed
                    color: "#c6c8cc", // Set the color to grey
                    display: "inline-block",
                }}
            />
        </Tooltip>

    )
};

export default MyTooltip;
