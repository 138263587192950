import React, { useState, useRef } from "react";
import appImage642x349 from "../tools/pngs/Branding613x305.png";
import {
    Box,
    ListItemIcon,
    Typography,
} from "@mui/material";
import '../theme/styles/font.css'


const DesignAppBackground = ({
    mainBackgroundColour,
    tabBackgroundColor,
    tabSelectedColor,
    tabDeselectedColor,
    setActiveTab = null,
    selectedTab = 1,
    children,
}) => {

    const generateListItemIcon = (index, path, text, iconFill, setActiveTab, selectedTab) => (
        <ListItemIcon
            key={index}
            sx={{
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                paddingLeft: '12px',
                paddingRight: '12px',
                cursor: 'pointer',
                color: selectedTab === index ? tabSelectedColor : tabDeselectedColor,
            }}
            onClick={() => setActiveTab && setActiveTab(index)}>
            <svg width="20" height="20" fill={selectedTab === index ? tabSelectedColor : tabDeselectedColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path d={path} />
            </svg>
            <p style={{ color: selectedTab === index ? tabSelectedColor : tabDeselectedColor, fontSize: '10px' }}>
                {text}
            </p>
        </ListItemIcon>
    );

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            border: "none",
        }}>
            <Box
                sx={{
                    position: "relative",
                    width: "305px",
                    height: "614px",
                    backgroundColor: mainBackgroundColour,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 0
                }}>
                <img
                    src={appImage642x349}
                    alt="App Background"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        zIndex: 10,
                    }} />

                {children}
                {/* Navigation Background */}
                <Box style={{
                    height: "80px",
                    backgroundColor: tabBackgroundColor,
                    width: "100%",
                    position: "absolute",
                    bottom: '0px',
                    zIndex: 4,
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                </Box>

                {/* Navigation Tab Control */}
                <Box
                    style={{
                        height: "50px",
                        backgroundColor: tabBackgroundColor,
                        width: "80%",
                        position: "absolute",
                        bottom: '30px',
                        zIndex: 15,
                        justifyContent: 'space-between',
                        left: '10%',
                        border: 'none',
                    }}>
                    <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
                        {[
                            {
                                path: "M280.4 148.3L96 300.1V464a16 16 0 0 0 16 16l112.1-.3a16 16 0 0 0 15.9-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.6a16 16 0 0 0 16 16.1L464 480a16 16 0 0 0 16-16V300L295.7 148.3a12.2 12.2 0 0 0 -15.3 0zM571.6 251.5L488 182.6V44.1a12 12 0 0 0 -12-12h-56a12 12 0 0 0 -12 12v72.6L318.5 43a48 48 0 0 0 -61 0L4.3 251.5a12 12 0 0 0 -1.6 16.9l25.5 31A12 12 0 0 0 45.2 301l235.2-193.7a12.2 12.2 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0 -1.7-16.9z",
                                text: "Home",
                            },
                            {
                                path: "M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM192 268c0-6.6 5.4-12 12-12h64c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-64c-6.6 0-12-5.4-12-12v-40zm-32 136c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v8zm16-96c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h88c6.6 0 12 5.4 12 12v40zm176 96c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v8zm32-96c0 6.6-5.4 12-12 12h-64c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h64c6.6 0 12 5.4 12 12v40zm128 0c0 6.6-5.4 12-12 12h-88c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h88c6.6 0 12 5.4 12 12v40zm0-140c0 13.3-10.7 24-24 24h-80c-13.3 0-24-10.7-24-24v-48c0-13.3 10.7-24 24-24h80c13.3 0 24 10.7 24 24v48z",
                                text: "My Card",
                            },
                            {
                                path: "M0 252.1V48C0 21.5 21.5 0 48 0h204.1a48 48 0 0 1 33.9 14.1l211.9 211.9c18.7 18.7 18.7 49.1 0 67.9L293.8 497.9c-18.7 18.7-49.1 18.7-67.9 0L14.1 286.1A48 48 0 0 1 0 252.1zM112 64c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z",
                                text: "Offers",
                            },
                            {
                                path: "M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4 .6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z",
                                text: "Settings",
                            }
                        ].map((tab, index) =>
                            generateListItemIcon(index + 1, tab.path, tab.text, tab.fillColor, setActiveTab, selectedTab)
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default DesignAppBackground;
