import { ApiServiceClass } from "../components/api-service.js";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Navbar from "./navbar.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { StatsMainBox } from "../tools/material-kit/overview/stats-mainbox.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import * as React from "react";
import TextField from "@mui/material/TextField";
import QrCodeIcon from "@heroicons/react/24/solid/QrCodeIcon.js";
import Gift from "@heroicons/react/24/solid/GiftIcon.js";
import TicketIcon from "@heroicons/react/24/solid/TicketIcon.js";
import UserIcon from "@heroicons/react/24/solid/UserIcon.js";
import UserPlusIcon from "@heroicons/react/24/solid/UserPlusIcon.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { OverviewGraph } from "../sections/dashboard-graph.js";
import StatsGraph from "../components/stats-graph.js"; // Import your new component
import Divider from "@mui/material/Divider";
import { padding } from "@mui/system";
import { Link } from "react-router-dom";

import TabPanel, { TabPanels } from "../components/tab-panel.js";

export default function Statistics() {
  const [tabNumber, setTabNumber] = useState(3);
  const [basicStats, setBasicStats] = useState([]);
  const [selectedValue, setSelectedValue] = useState(2);
  const [graphDateFilter, setGraphDateFilter] = useState(2);
  const [dateFilterLabel, setDateFilterLabel] = useState("Today");
  const [dateFilterLabel2, setDateFilterLabel2] = useState("Today");
  const [axis, setAxis] = useState();
  const [graphValues, setGraphValues] = useState([]);
  const [graphLoading, setGraphLoading] = useState(true);

  const handleDropDownChange = async (event) => {
    try {
      setSelectedValue(event.target.value);
      const label = getLabelForValue(event.target.value);
      setDateFilterLabel(label);
      var requestBody = {
        CompanyId: companyDetails.Id,
        FilterType: event.target.value,
      };

      console.log("Sending request with requestBody: ", requestBody);
      const basicStats = await ApiServiceClass.GetBasicStatistics(requestBody);
      console.log("Received response: ", basicStats);

      setBasicStats(basicStats);
    } catch (error) {
      console.error("Error fetching basic statistics:", error);
    }
  };

  const handleTabChange = (event, newTabNumber) => {
    console.log("Tab changed. New tab number:", newTabNumber);
    setTabNumber(newTabNumber);
    event.preventDefault();
  };

  const handleGraphDateFilterChange = async (event) => {
    try {
      const label2 = getLabelForValue(event.target.value);
      console.log(
        "drop down changed to: " + event.target.value + " label: " + label2
      );
      await setGraphDateFilter(event.target.value);
      await setDateFilterLabel2(label2);
      await setAxis(updateAxis(event.target.value));
    } catch (error) {
      console.error("Error fetching basic statistics:", error);
    }
  };

  const updateAxis = (value) => {
    console.log("setting axis for " + value);
    switch (value) {
      case 1:
        return Array.from(
          { length: 24 },
          (_, index) => `${index.toString().padStart(2, "0")}:00`
        );
      case 2:
        return Array.from(
          { length: 24 },
          (_, index) => `${index.toString().padStart(2, "0")}:00`
        );
      case 3:
        return [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
      case 4:
        const daysInMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
        return Array.from({ length: daysInMonth }, (_, index) =>
          (index + 1).toString()
        );
      case 5:
        return Array.from({ length: 12 }, (_, index) => {
          const monthIndex = index + 1;
          return new Date(
            new Date().getFullYear(),
            monthIndex,
            0
          ).toLocaleString("default", { month: "short" });
        });
      case 6:
        return Array.from({ length: 12 }, (_, index) => {
          const monthIndex = index + 1;
          return new Date(
            new Date().getFullYear(),
            monthIndex,
            0
          ).toLocaleString("default", { month: "short" });
        });
      default:
        return ["00:00"];
    }
  };

  const getLabelForValue = (value) => {
    console.log("getting label for value: ", value);
    switch (value) {
      case 1:
        return "Yesterday";
      case 2:
        return "Today";
      case 3:
        return "This Week";
      case 4:
        return "This Month";
      case 5:
        return "This Year";
      case 6:
        return "Last Year";
      default:
        return "";
    }
  };

  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);
  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  let navigate = useNavigate();

  useEffect(() => {
    const firstPageLoad = async () => {
      if (companyDetails === null || loginData === null) {
        console.log("redirecting to /Login");
        navigate("/Login");
        return;
      }
      setAxis(updateAxis(graphDateFilter));
      await fetchData();

    };
    firstPageLoad();
  }, []);

  useEffect(() => {
    //setAxis(updateAxis(graphDateFilter));
    fetchDetailedGraph();
  }, [graphDateFilter, tabNumber]);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const fetchData = async () => {
    console.log(requestBody);
    try {
      var requestBody = {
        CompanyId: companyDetails.Id,
        FilterType: "1",
      };
      // Rewards
      const basicStats = await ApiServiceClass.GetBasicStatistics(requestBody);
      console.log(basicStats);
      setBasicStats(basicStats);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDetailedGraph = async () => {
    try {
      console.log(
        "Fetching detailed graph | date filter: " +
        graphDateFilter +
        " | datatype: " +
        tabNumber
      );
      setGraphLoading(true);
      var requestBody = {
        CompanyId: companyDetails.Id,
        DateOption: graphDateFilter,
        DataType: tabNumber,
      };
      //var data = { Results: { 3: 1 } };
      const data = await ApiServiceClass.GetDetailedGraph(requestBody);
      console.log("Response from fetch detailed graph : " + JSON.stringify(data.Results));
      processData(data);
      setGraphLoading(false);
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  const processData = (data) => {
    const currentDate = new Date();
    let results;

    if (graphDateFilter == 1 || graphDateFilter == 2) {
      results = new Array(24).fill(0);
    }

    if (graphDateFilter == 3) {
      results = new Array(7).fill(0);
    }

    if (graphDateFilter == 4) {
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      results = new Array(daysInMonth).fill(0);
    }

    if (graphDateFilter == 5 || graphDateFilter == 6) {
      results = new Array(12).fill(0);
    }

    if (!results) {
      console.error("Invalid axis value");
      return;
    }

    for (const key in data.Results) {
      if (Object.prototype.hasOwnProperty.call(data.Results, key)) {
        // Convert the key to a number (assuming keys are strings)
        const index = parseInt(key, 10);

        // Check if the index is valid and within the bounds of the results array
        if (!isNaN(index) && index > 0 && index < results.length) {
          if (graphDateFilter == 1 || graphDateFilter == 2) {
            results[index] = data.Results[key];
            //console.log("index: " + index + " results data " + key);
          } else {
            //console.log("index: " + index + " results data " + key - 1);
            results[index - 1] = data.Results[key];
          }
        }
      }
    }

    setGraphValues(results);
    console.log("graph values: " + results);
  };

  const lineStyle = {
    position: "sticky",
    width: "100%",
    borderRadius: "10px",
    // marginLeft: "0%",
    height: "2px", // Adjust the height of the line as needed
    background: "#d9dbde",
  };

  const DownloadsTable = ({ data }) => {
    return (
      <table style={{ borderCollapse: "collapse", width: "50%" }}>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{ border: "1px solid #ddd", padding: "8px" }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Navbar name="Statistics">
      <Box display="flex" paddingTop={4}>
        <FormControl style={{ minWidth: "200px", backgroundColor: "#ffffff" }}>
          {" "}
          <InputLabel
            id="dropdown-label"
            style={{ marginTop: "-10px", marginLeft: "-8px" }}
          >
            Show data for
          </InputLabel>
          <Select
            labelId="dropdown-label2"
            id="dropdown2"
            value={selectedValue}
            label="Show data for"
            onChange={handleDropDownChange}
            style={{ width: "100%", height: "48px" }}
          >
            <MenuItem value={1}>Yesterday</MenuItem>
            <MenuItem value={2}>Today</MenuItem>
            <MenuItem value={3}>This Week</MenuItem>
            <MenuItem value={4}>This Month</MenuItem>
            <MenuItem value={5}>This Year</MenuItem>
            <MenuItem value={6}>Last Year</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid
        item
        xs={6}
        sm={11}
        md={6}
        lg={50} // Set the number of columns for large screens
        container
        spacing={3}
        minWidth={10}
        paddingTop={3}
        paddingBottom={3}
        justifyContent="flex-start"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid item>
          <Card>
            <StatsMainBox
              title="Total Customers"
              tooltipText="The total amount of customers who have registered for your app."
              filterRange={dateFilterLabel}
              icon={UserIcon}
              iconColour="#d3e3f0"
              comparedToPreviousWeek={"10"}
              sx={{ height: "100%" }}
              value={basicStats.TotalCustomersCount}
            />
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <StatsMainBox
              title="Active Customers"
              tooltipText="Customers that have logged in/used the app."
              filterRange={dateFilterLabel}
              icon={UserIcon}
              iconColour="#32a85c"
              comparedToPreviousWeek={"10"}
              sx={{ height: "100%" }}
              value={basicStats.ActiveCustomersCount}
            />
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <StatsMainBox
              title="New Customers"
              tooltipText="New customers that have signed up."
              filterRange={dateFilterLabel}
              icon={UserPlusIcon}
              iconColour="#3265a8"
              comparedToPreviousWeek={"10"}
              sx={{ height: "100%" }}
              value={basicStats.NewCustomersCount}
            />
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <StatsMainBox
              title="Loyalty Codes scanned"
              tooltipText="The number of customer QR codes scanned."
              filterRange={dateFilterLabel}
              icon={QrCodeIcon}
              iconColour="#4287f5"
              comparedToPreviousWeek={"10"}
              sx={{ height: "100%" }}
              value={basicStats.ScannedCount}
            />
          </Card>
        </Grid>

        <Grid item>
          <Card>
            <StatsMainBox
              title="Rewards Claimed"
              tooltipText="The number of actual free rewards claimed."
              filterRange={dateFilterLabel}
              icon={Gift}
              iconColour="#eb75ae"
              comparedToPreviousWeek={"10"}
              sx={{ height: "100%" }}
              value={basicStats.RewardsClaimedCount}
            />
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <StatsMainBox
              title="Rewards ready to be claimed"
              tooltipText="The number of rewards earned but not yet claimed."
              filterRange={dateFilterLabel}
              icon={TicketIcon}
              iconColour="#aa75eb"
              comparedToPreviousWeek={"10"}
              sx={{ height: "100%" }}
              value={basicStats.UsersReadyToClaimRewardCount}
            />
          </Card>
        </Grid>
      </Grid>
      <div style={lineStyle} />
      <Grid
        container
        spacing={1}
        paddingTop={1}
        maxWidth="70%"
      >
        <Grid item>
          <FormControl
            style={{ minWidth: "200px", backgroundColor: "#ffffff" }}
          >
            <InputLabel
              id="dropdown-label2"
              style={{ marginTop: "-10px", marginLeft: "-10px" }}
            >
              Show data for
            </InputLabel>
            <Select
              labelId="dropdown-label2"
              id="dropdown2"
              value={graphDateFilter}
              label="Select a date range"
              onChange={handleGraphDateFilterChange}
              style={{ width: "100%", height: "48px" }}
            >
              <MenuItem value={1}>Yesterday</MenuItem>
              <MenuItem value={2}>Today</MenuItem>
              <MenuItem value={3}>This Week</MenuItem>
              <MenuItem value={4}>This Month</MenuItem>
              <MenuItem value={5}>This Year</MenuItem>
              <MenuItem value={6}>Last Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Box
            sx={{
              borderRadius: "10px",
              width: "100%",
              backgroundColor: "#FFFFFF",
              ml: "0%",
              mr: "10",
            }}
          >
            <Tabs
              value={tabNumber}
              onChange={handleTabChange}
              aria-label="Tabs"
            >
              <Tab
                className="tab"
                name="Tab2"
                value={3}
                label="Active Customers"
                sx={{ marginLeft: 3 }}
              />
              <Tab
                className="tab"
                name="Tab3"
                label="New Customers"
                value={1}
                sx={{ marginLeft: 3 }}
              />
              <Tab
                className="tab"
                name="Tab4"
                value={6}
                label="Codes Scanned"
                sx={{ marginLeft: 3 }}
              />
              <Tab
                className="tab"
                name="Tab5"
                value={4}
                label="Rewards Claimed"
                sx={{ marginLeft: 3, marginRight: 3 }}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>

      <Box
        style={{
          width: "100%",
          paddingTop: "10px",
          //backgroundColor: "#000000",
        }}
      >
        <TabPanel value={tabNumber} index={3} sx={{ color: "#000000" }}>
          <StatsGraph
            axis={axis}
            values={graphValues}
            CompanyId={companyDetails.Id}
            graphTitle="Active customers"
          />
        </TabPanel>
        <TabPanel value={tabNumber} index={1} sx={{ color: "#000000" }}>
          <StatsGraph
            axis={axis}
            values={graphValues}
            CompanyId={companyDetails.Id}
            graphTitle="New Customers"
          />
        </TabPanel>
        <TabPanel value={tabNumber} index={6} sx={{ color: "#000000" }}>
          <StatsGraph
            axis={axis}
            values={graphValues}
            CompanyId={companyDetails.Id}
            graphTitle="Codes Scanned"
          />
        </TabPanel>
        <TabPanel value={tabNumber} index={4} sx={{ color: "#000000" }}>
          <StatsGraph
            axis={axis}
            values={graphValues}
            CompanyId={companyDetails.Id}
            graphTitle="Rewards Claimed"
          />
        </TabPanel>
      </Box>
    </Navbar>
  );
}
