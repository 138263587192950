import React, { useState, useEffect, useRef } from "react";
import appImage642x349 from "../tools/pngs/Branding613x305.png";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Button,
  Box,
  SvgIcon,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TextField,
} from "@mui/material";
import MyColor from "../components/my-color";
import MyTooltip from "../components/my-tooltip";
import RenderCompanyLogo from "../components/logo.js";


export const DesignAppSplash = ({
  splashBackgroundColour,
  companyLogo,
  companyLogoFileType,
}) => {

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "test.2",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          border: "none",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "305px",
            height: "614px",
            backgroundColor: splashBackgroundColour,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 0
          }}
        >
          {/* phone */}
          <img
            src={appImage642x349}
            alt="App Background"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              zIndex: 1,
            }}
          />
          {/* Logo */}
          <RenderCompanyLogo 
            companyLogo={companyLogo}
            companyLogoFileType={companyLogoFileType}
            maxH={"150px"}
            maxW={"150px"}
          />

        </Box>
      </Box>
    </div>
  );
};


export const DesignFormSplash = ({
  companyLogoFileType,
  uploadedFileName,
  splashBackgroundColour,
  setSplashBackgroundColour,
  companyName,
  setCompanyName,
  handleFileUpload,
}) => {
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const colorPickerRef = useRef(null);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    // Close color picker if clicked outside the color picker box
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      setShowBackgroundColorPicker(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <Table size="small" sx={{ marginLeft: 0, width: '100%', }}>
        <TableBody>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              Company Name
              <TextField
                variant="outlined"
                fullWidth
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <MyColor
                name="Background Splash Colour"
                tooltip="The background colour for the screen shown briefly when opening the app"
                color={splashBackgroundColour}
                setColor={setSplashBackgroundColour}
                showColorPicker={showBackgroundColorPicker}
                setShowColorPicker={setShowBackgroundColorPicker}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '10px' }}>
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      style={{ display: 'none' }}
                    />
                    <Button
                      startIcon={
                        <SvgIcon fontSize="small">
                          <CloudUploadIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      size="xs"
                      onClick={() => document.querySelector('input[type=file]').click()}
                      style={{ cursor: 'pointer', marginRight: '0px', }}
                    >
                      Upload Logo
                    </Button>
                  </div>
                  {uploadedFileName}
                </div>
                <MyTooltip title='Upload a logo from your device, we accept png, webp, svg and jpg formats' />
              </div>
              {companyLogoFileType === 2 && (
                <p style={{ color: 'red', marginTop: '5px' }}>Warning: Use of jpeg and jpg not recommended</p>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
