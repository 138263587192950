import { useCallback, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Icon } from "../tools/icons/other-icon.svg";
export const SettingsOtherPanel = ({ title, subheader }) => {
  return (
    <div style={{ padding: "10px" }}>
      <Card sx={{ height: "1%" }}>
        <CardHeader
          title={title}
          subheader={subheader}
          avatar={<Icon style={{ width: "50px", height: "40px" }} />}
        />
        <Divider />
        <CardContent>
          <Stack spacing={3} sx={{ maxWidth: 400 }}>
            <label>
              View our{" "}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </label>
          </Stack>
        </CardContent>
        <Divider />
        {/* <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained">Update</Button>
        </CardActions> */}
      </Card>
    </div>
  );
};
