import React from 'react';
import { MenuItem, Select } from "@mui/material";
import { fontStyles } from '../theme/styles/font.js';
import MyTooltip from "./my-tooltip";


export const FontSelect = ({
    name,
    tooltip,
    availableFonts,
    font,
    setFont,
    fontStyle,
    setFontStyle
}) => {
    const handleChange = (event) => {
        const selectedFontName = event.target.value;
        // console.log("selectedFontName: ", selectedFontName)
        const font = availableFonts.find(font => font.FontFriendlyName === selectedFontName);
        // console.log("font: ", font)
        const fontId = font ? font.Id : null
        setFontStyle(fontId);
        setFont(selectedFontName);

        // console.log("getFontTechnical: ",getFontTechnical(availableFonts, fontStyle))
        // console.log("getFontFriendly: ", getFontFriendly(availableFonts, fontStyle))
    };

    return (
        <div>
            <style>{fontStyles}</style>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center',}}>
                <div>{name}</div>
                <MyTooltip title={tooltip} />
            </div>
            <div style={{ justifyContent: 'left', display: 'flex', textAlign: 'left'}}>
                <Select
                    value={getFontFriendly(availableFonts, fontStyle)}
                    onChange={handleChange}
                    style={{
                        width: '230px',
                        border: "1px solid black",
                        zIndex: 3,
                        height: '50px',
                        // fontFamily: font.FontTechnicalName,
                    }}
                >
                    {availableFonts.map((f, index) => (
                        <MenuItem
                            key={f.Id}
                            value={f.FontFriendlyName}
                            style={{ fontFamily: f.FontTechnicalName }}
                        >
                            {f.FontFriendlyName}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </div>
    );
}

export const getFontTechnical = (availableFonts, styleNo) => {
    const index = availableFonts.findIndex(font => font.Id === styleNo);
    const fontName = styleNo !== -1 ? availableFonts[index]?.FontTechnicalName : null;
    // console.log("getFont: styleNo: ", styleNo)
    return fontName;
};

export const getFontFriendly = (availableFonts, styleNo) => {
    const index = availableFonts.findIndex(font => font.Id === styleNo);
    const fontName = styleNo !== -1 ? availableFonts[index]?.FontFriendlyName : null;
    // console.log("getFontFriendly: styleNo: ", styleNo)
    return fontName
};

