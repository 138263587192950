import React, { useState } from "react";
import {
    Box,
    Typography,
} from "@mui/material";
import { getFontTechnical } from "../components/font-select.js"
import { fontStyles } from '../theme/styles/font.js';
import { ReactComponent as RightArrow } from '../tools/icons/rightarrow.svg';


export const DesignAppSettings = ({
    topBannerBackgroundColour,
    topBannerFontColour,
    topBannerFontStyle,

    availableFonts,
    mainBackgroundFontStyle,
}) => {

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                width: "100%",
                left: '0px',
                paddingTop: '20px',
            }}>
            <style>{fontStyles}</style>

            {/* Top Banner */}
            <Box
                style={{
                    height: "80px",
                    backgroundColor: topBannerBackgroundColour,
                    width: "100%",
                    position: "absolute",
                    border: 'none',
                    top: 0,
                    zIndex: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Typography className={getFontTechnical(availableFonts, topBannerFontStyle)}
                    style={{ 
                        color: topBannerFontColour, 
                        marginTop: '35px',
                        }}>
                    Settings
                </Typography>
            </Box>

            <Box sx={{
                top: "120px",
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                width: "98%",
            }}>
                <Box sx={{
                    ml: '25px',
                    textAlign: 'left',
                    fontFamily: 'Poppins-Medium'
                }}>
                    <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)}
                        sx={{ fontSize: '13px', height: '20px' }}>
                        Change Password
                    </Typography>
                    <RightArrow
                        width="20px"
                        height="20px"
                        style={{
                            position: 'absolute',
                            top: '0px',
                            right: '25px',
                            zIndex: 10,
                        }} />
                </Box>
                <Box sx={{ mt: '5px', mb: '5px', width: "100%", height: 0, borderBottom: '1px solid black' }} />
                <Box sx={{ ml: '25px', textAlign: 'left', fontFamily: 'Poppins-Medium', fontSize: '13px' }}>
                    <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)}
                        sx={{ fontSize: '13px', height: '20px' }}>
                        Update Personal Information
                    </Typography>
                    <RightArrow
                        width="20px"
                        height="20px"
                        strokeWidth="10"
                        style={{
                            position: 'absolute',
                            top: '31px',        // prev(0) + typography(20) + mt(5) + (line)1 + (mb)5
                            right: '25px',
                            zIndex: 10,
                            borderWidth: '4',
                            strokeWidth: '4',
                        }} />
                </Box>
                <Box sx={{ mt: '5px', mb: '5px', width: "100%", height: 0, borderBottom: '1px solid black' }} />
                <Box sx={{ ml: '25px', textAlign: 'left', fontFamily: 'Poppins-Medium' }}>
                    <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)}
                        sx={{ fontSize: '13px', height: '20px' }}>
                        Deactivate & delete personal data
                    </Typography>
                    <RightArrow
                        width="20px"
                        height="20px"
                        style={{
                            position: 'absolute',
                            top: '62px',
                            right: '25px',
                            zIndex: 10,
                        }} />
                </Box>
                <Box sx={{ mt: '5px', mb: '5px', width: "100%", height: 0, borderBottom: '1px solid black' }} />
                <Box sx={{ ml: '25px', textAlign: 'left', fontFamily: 'Poppins-Medium', fontSize: '13px' }}>
                    <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)}
                        sx={{ fontSize: '13px', height: '20px' }}>
                        Logout
                    </Typography>
                    <RightArrow
                        width="20px"
                        height="20px"
                        style={{
                            position: 'absolute',
                            top: '93px',
                            right: '25px',
                            zIndex: 10,
                        }} />
                </Box>
                <Box sx={{ mt: '5px', mb: '5px', width: "100%", height: 0, borderBottom: '1px solid black' }} />
            </Box>
        </Box>
    );
};
