export const fontStyles = `
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial.ttf') format('truetype');
}

.Arial {
  font-family: 'Arial', sans-serif;
}

@font-face {
  font-family: 'Arial-Black';
  src: url('../fonts/Arial-Black.ttf') format('truetype');
}

.Arial-Black {
  font-family: 'Arial-Black', sans-serif;
}

@font-face {
  font-family: 'Arial-Bold';
  src: url('../fonts/Arial-Bold.ttf') format('truetype');
}

.Arial-Bold {
  font-family: 'Arial-Bold', sans-serif;
}

@font-face {
  font-family: 'Arial-BoldItalic';
  src: url('../fonts/Arial-BoldItalic.ttf') format('truetype');
}

.Arial-BoldItalic {
  font-family: 'Arial-BoldItalic', sans-serif;
}

@font-face {
  font-family: 'Arial-Italic';
  src: url('../fonts/Arial-Italic.ttf') format('truetype');
}

.Arial-Italic {
  font-family: 'Arial-Italic', sans-serif;
}

@font-face {
  font-family: 'Britanica';
  src: url('../fonts/Britanica.ttf') format('truetype');
}

.Britanica {
  font-family: 'Britanica', serif;
}

@font-face {
  font-family: 'Cardo-Bold';
  src: url('../fonts/Cardo-Bold.ttf') format('truetype');
}

.Cardo-Bold {
  font-family: 'Cardo-Bold', serif;
}

@font-face {
  font-family: 'Cardo-Italic';
  src: url('../fonts/Cardo-Italic.ttf') format('truetype');
}

.Cardo-Italic {
  font-family: 'Cardo-Italic';
}

@font-face {
  font-family: 'Cardo-Regular';
  src: url('../fonts/Cardo-Regular.ttf') format('truetype');
}

.Cardo-Regular {
  font-family: 'Cardo-Regular', serif;
}

@font-face {
  font-family: 'Courier';
  src: url('../fonts/Courier.ttf') format('truetype');
}

.Courier {
  font-family: 'Courier', monospace;
}

@font-face {
  font-family: 'Courier-Bold';
  src: url('../fonts/Courier-Bold.ttf') format('truetype');
}

.Courier-Bold {
  font-family: 'Courier-Bold', monospace;
}

@font-face {
  font-family: 'Courier-BoldItalic';
  src: url('../fonts/Courier-BoldItalic.ttf') format('truetype');
}

.Courier-BoldItalic {
  font-family: 'Courier-BoldItalic', monospace;
}

@font-face {
  font-family: 'Courier-Italic';
  src: url('../fonts/Courier-Italic.ttf') format('truetype');
}

.Courier-Italic {
  font-family: 'Courier-Italic', monospace;
}

@font-face {
  font-family: 'Genera';
  src: url('../fonts/Genera.ttf') format('truetype');
}

.Genera {
  font-family: 'Genera', serif;
}

@font-face {
  font-family: 'Georgia';
  src: url('../fonts/Georgia.ttf') format('truetype');
}

.Georgia {
  font-family: 'Georgia', serif;
}

@font-face {
  font-family: 'Georgia-Bold';
  src: url('../fonts/Georgia-Bold.ttf') format('truetype');
}

.Georgia-Bold {
  font-family: 'Georgia-Bold', serif;
}

@font-face {
  font-family: 'Georgia-Italic';
  src: url('../fonts/Georgia-Italic.ttf') format('truetype');
}

.Georgia-Italic {
  font-family: 'Georgia-Italic', serif;
}

@font-face {
  font-family: 'Georgia-BoldItalic';
  src: url('../fonts/Georgia-BoldItalic.ttf') format('truetype');
}

.Georgia-BoldItalic {
  font-family: 'Georgia-BoldItalic', serif;
}

@font-face {
  font-family: 'Impact';
  src: url('../fonts/Impact.ttf') format('truetype');
}

.Impact {
  font-family: 'Impact', sans-serif;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('../fonts/Lato-Black.ttf') format('truetype');
}

.Lato-Black {
  font-family: 'Lato-Black', sans-serif;
}

@font-face {
  font-family: 'Lato-BlackItalic';
  src: url('../fonts/Lato-BlackItalic.ttf') format('truetype');
}

.Lato-BlackItalic {
  font-family: 'Lato-BlackItalic', sans-serif;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
}

.Lato-Bold {
  font-family: 'Lato-Bold', sans-serif;
}

@font-face {
  font-family: 'Lato-BoldItalic';
  src: url('../fonts/Lato-BoldItalic.ttf') format('truetype');
}

.Lato-BoldItalic {
  font-family: 'Lato-BoldItalic', sans-serif;
}

@font-face {
  font-family: 'Lato-Italic';
  src: url('../fonts/Lato-Italic.ttf') format('truetype');
}

.Lato-Italic {
  font-family : 'Lato-Italic', sans-serif;
}

@font-face {
  font-family: 'Lato-Light';
  src: url('../fonts/Lato-Light.ttf') format('truetype');
}

.Lato-Light {
  font-family: 'Lato-Light', sans-serif;
}

@font-face {
  font-family: 'Lato-LightItalic';
  src: url('../fonts/Lato-LightItalic.ttf') format('truetype');
}

.Lato-LightItalic {
  font-family: 'Lato-LightItalic', sans-serif;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
}

.Lato-Regular {
  font-family: 'Lato-Regular', sans-serif;
}

@font-face {
  font-family: 'Lato-Thin';
  src: url('../fonts/Lato-Thin.ttf') format('truetype');
}

.Lato-Thin {
  font-family: 'Lato-Thin', sans-serif;
}

@font-face {
  font-family: 'Lato-ThinItalic';
  src: url('../fonts/Lato-ThinItalic.ttf') format('truetype');
}

.Lato-ThinItalic {
  font-family: 'Lato-ThinItalic', sans-serif;
}

@font-face {
  font-family: 'Lucinda-Sans';
  src: url('../fonts/Lucinda-Sans.ttf') format('truetype');
}

.Lucinda-Sans {
  font-family: 'Lucinda-Sans', sans-serif;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}

.OpenSans-Bold {
  font-family: 'OpenSans-Bold', sans-serif;
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url('../fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

.OpenSans-BoldItalic {
  font-family: 'OpenSans-BoldItalic', sans-serif;
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

.OpenSans-ExtraBold {
  font-family: 'OpenSans-ExtraBold', sans-serif;
}

@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  src: url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}

.OpenSans-ExtraBoldItalic {
  font-family: 'OpenSans-ExtraBoldItalic', sans-serif;
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url('../fonts/OpenSans-Italic.ttf') format('truetype');
}

.OpenSans-Italic {
  font-family: 'OpenSans-Italic', sans-serif;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans-Light.ttf') format('truetype');
}

.OpenSans-Light {
  font-family: 'OpenSans-Light', sans-serif;
}

@font-face {
  font-family: 'OpenSans-LightItalic';
  src: url('../fonts/OpenSans-LightItalic.ttf') format('truetype');
}

.OpenSans-LightItalic {
  font-family: 'OpenSans-LightItalic', sans-serif;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

.OpenSans-Regular {
  font-family: 'OpenSans-Regular', sans-serif;
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../fonts/OpenSans-Semibold.ttf') format('truetype');
}

.OpenSans-Semibold {
  font-family: 'OpenSans-SemiBold', sans-serif;
}

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: url('../fonts/OpenSans-SemiboldItalic.ttf') format('truetype');
}

.OpenSans-SemiboldItalic {
  font-family: 'OpenSans-SemiBoldItalic', sans-serif;
}

@font-face {
  font-family: 'Oswald-Bold';
  src: url('../fonts/Oswald-Bold.ttf') format('truetype');
}

.Oswald-Bold {
  font-family: 'Oswald-Bold', sans-serif;
}

@font-face {
  font-family: 'Oswald-ExtraLight';
  src: url('../fonts/Oswald-ExtraLight.ttf') format('truetype');
}

.Oswald-ExtraLight {
  font-family: 'Oswald-ExtraLight', sans-serif;
}

@font-face {
  font-family: 'Oswald-Light';
  src: url('../fonts/Oswald-Light.ttf') format('truetype');
}

.Oswald-Light {
  font-family: 'Oswald-Light', sans-serif;
}

@font-face {
  font-family: 'Oswald-Medium';
  src: url('../fonts/Oswald-Medium.ttf') format('truetype');
}

.Oswald-Medium {
  font-family: 'Oswald-Medium', sans-serif;
}

@font-face {
  font-family: 'Oswald-Regular';
  src: url('../fonts/Oswald-Regular.ttf') format('truetype');
}

.Oswald-Regular {
  font-family: 'Oswald-Regular', sans-serif;
}

@font-face {
  font-family: 'Oswald-SemiBold';
  src: url('../fonts/Oswald-SemiBold.ttf') format('truetype');
}

.Oswald-SemiBold {
  font-family: 'Oswald-SemiBold', sans-serif;
}

@font-face {
  font-family: 'Palatino-Bold';
  src: url('../fonts/Palatino-Bold.ttf') format('truetype');
}

.Palatino-Bold {
  font-family: 'Palatino-Bold', serif;
}

@font-face {
  font-family: 'Palatino-BoldItalic';
  src: url('../fonts/Palatino-BoldItalic.ttf') format('truetype');
}

.Palatino-BoldItalic {
  font-family: 'Palatino-BoldItalic', serif;
}

@font-face {
  font-family: 'Palatino-Italic';
  src: url('../fonts/Palatino-Italic.ttf') format('truetype');
}

.Palatino-Italic {
  font-family: 'Palatino-Italic', serif;
}

@font-face {
  font-family: 'Palatino';
  src: url('../fonts/Palatino.ttf') format('truetype');
}

.Palatino {
  font-family: 'Palatino', serif;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
}

.Poppins-Light {
  font-family: 'Poppins-Light', serif;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.ttf') format('truetype');
}

.Poppins-Medium {
  font-family: 'Poppins-Medium', serif;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

.Poppins-Regular {
  font-family: 'Poppins-Regular', serif;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}

.Poppins-SemiBold {
  font-family: 'Poppins-SemiBold', serif;
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: url('../fonts/Quicksand-Bold.ttf') format('truetype');
}

.Quicksand-Bold {
  font-family: 'Quicksand-Bold', sans-serif;
}

@font-face {
  font-family: 'Quicksand-Light';
  src: url('../fonts/Quicksand-Light.ttf') format('truetype');
}

.Quicksand-Light {
  font-family: 'Quicksand-Light', sans-serif;
}

@font-face {
  font-family: 'Quicksand-Medium';
  src: url('../fonts/Quicksand-Medium.ttf') format('truetype');
}

.Quicksand-Medium {
  font-family: 'Quicksand-Medium', sans-serif;
}

@font-face {
  font-family: 'Quicksand-Regular';
  src: url('../fonts/Quicksand-Regular.ttf') format('truetype');
}

.Quicksand-Regular {
  font-family: 'Quicksand-Regular', sans-serif;
}

@font-face {
  font-family: 'Quicksand-SemiBold';
  src: url('../fonts/Quicksand-SemiBold.ttf') format('truetype');
}

.Quicksand-SemiBold {
  font-family: 'Quicksand-SemiBold', sans-serif;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../fonts/Roboto-Black.ttf') format('truetype');
}

.Roboto-Black {
  font-family: 'Roboto-Black', sans-serif;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../fonts/Roboto-BlackItalic.ttf') format('truetype');
}

.Roboto-BlackItalic {
  font-family: 'Roboto-BlackItalic', sans-serif;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}

.Roboto-Bold {
  font-family: 'Roboto-Bold', sans-serif;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../fonts/Roboto-BoldItalic.ttf') format('truetype');
}

.Roboto-BoldItalic {
  font-family: 'Roboto-BoldItalic', sans-serif;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../fonts/Roboto-Italic.ttf') format('truetype');
}

.Roboto-Italic {
  font-family: 'Roboto-Italic', sans-serif;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

.Roboto-Light {
  font-family: 'Roboto-Light', sans-serif;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../fonts/Roboto-LightItalic.ttf') format('truetype');
}

.Roboto-LightItalic {
  font-family: 'Roboto-LightItalic', sans-serif;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

.Roboto-Medium {
  font-family: 'Roboto-Medium', sans-serif;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
}

.Roboto-MediumItalic {
  font-family: 'Roboto-MediumItalic', sans-serif;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

.Roboto-Regular {
  font-family: 'Roboto-Regular', sans-serif;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../fonts/Roboto-Thin.ttf') format('truetype');
}

.Roboto-Thin {
  font-family: 'Roboto-Thin', sans-serif;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../fonts/Roboto-ThinItalic.ttf') format('truetype');
}

.Roboto-ThinItalic {
  font-family: 'Roboto-ThinItalic', sans-serif;
}

@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
}

.RobotoCondensed-Bold {
  font-family: 'RobotoCondensed-Bold', sans-serif;
}

@font-face {
  font-family: 'RobotoCondensed-BoldItalic';
  src: url('../fonts/RobotoCondensed-BoldItalic.ttf') format('truetype');
}

.RobotoCondensed-BoldItalic {
  font-family: 'RobotoCondensed-BoldItalic', sans-serif;
}

@font-face {
  font-family: 'RobotoCondensed-Italic';
  src: url('../fonts/RobotoCondensed-Italic.ttf') format('truetype');
}

.RobotoCondensed-Italic {
  font-family: 'RobotoCondensed-Italic', sans-serif;
}

@font-face {
  font-family: 'RobotoCondensed-Light';
  src: url('../fonts/RobotoCondensed-Light.ttf') format('truetype');
}

.RobotoCondensed-Light {
  font-family: 'RobotoCondensed-Light', sans-serif;
}

@font-face {
  font-family: 'RobotoCondensed-LightItalic';
  src: url('../fonts/RobotoCondensed-LightItalic.ttf') format('truetype');
}

.RobotoCondensed-LightItalic {
  font-family: 'RobotoCondensed-LightItalic', sans-serif;
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

.RobotoCondensed-Regular {
  font-family: 'RobotoCondensed-Regular', sans-serif;
}

@font-face {
  font-family: 'Tahoma';
  src: url('../fonts/Tahoma.ttf') format('truetype');
}

.Tahoma {
  font-family: 'Tahoma', sans-serif;
}

@font-face {
  font-family: 'Tahoma-Bold';
  src: url('../fonts/Tahoma-Bold.ttf') format('truetype');
}

.Tahoma-Bold {
  font-family: 'Tahoma-Bold', sans-serif;
}

@font-face {
  font-family: 'Times-New-Roman';
  src: url('../fonts/Times-New-Roman.ttf') format('truetype');
}

.Times-New-Roman {
  font-family: 'Times-New-Roman', serif;
}

@font-face {
  font-family: 'Times-New-Roman-Bold';
  src: url('../fonts/Times-New-Roman-Bold.ttf') format('truetype');
}

.Times-New-Roman-Bold {
  font-family: 'Times-New-Roman-Bold', serif;
}

@font-face {
  font-family: 'Times-New-Roman-BoldItalic';
  src: url('../fonts/Times-New-Roman-BoldItalic.ttf') format('truetype');
}

.Times-New-Roman-BoldItalic {
  font-family: 'Times-New-Roman-BoldItalic', serif;
}

@font-face {
  font-family: 'Times-New-Roman-Italic';
  src: url('../fonts/Times-New-Roman-Italic.ttf') format('truetype');
}

.Times-New-Roman-Italic {
  font-family: 'Times-New-Roman-Italic', serif;
}

@font-face {
  font-family: 'Trebuchet-Bold';
  src: url('../fonts/Trebuchet-Bold.ttf') format('truetype');
}

.Trebuchet-Bold {
  font-family: 'Trebuchet-Bold', sans-serif;
}

@font-face {
  font-family: 'Trebuchet-BoldItalic';
  src: url('../fonts/Trebuchet-BoldItalic.ttf') format('truetype');
}

.Trebuchet-BoldItalic {
  font-family: 'Trebuchet-BoldItalic', sans-serif;
}

@font-face {
  font-family: 'Trebuchet-Italic';
  src: url('../fonts/Trebuchet-Italic.ttf') format('truetype');
}

.Trebuchet-Italic {
  font-family: 'Trebuchet-Italic', sans-serif;
}

@font-face {
  font-family: 'Trebuchet';
  src: url('../fonts/Trebuchet.ttf') format('truetype');
}

.Trebuchet {
  font-family: 'Trebuchet', sans-serif;
}

@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana.ttf') format('truetype');
}

.Verdana {
  font-family: 'Verdana', sans-serif;
}

@font-face {
  font-family: 'Verdana-Bold';
  src: url('../fonts/Verdana-Bold.ttf') format('truetype');
}

.Verdana-Bold {
  font-family: 'Verdana-Bold', sans-serif;
}

@font-face {
  font-family: 'Verdana-BoldItalic';
  src: url('../fonts/Verdana-BoldItalic.ttf') format('truetype');
}

.Verdana-BoldItalic {
  font-family: 'Verdana-BoldItalic', sans-serif;
}

@font-face {
  font-family: 'Verdana-Italic';
  src: url('../fonts/Verdana-Italic.ttf') format('truetype');
}

.Verdana-Italic {
  font-family: 'Verdana-Italic', sans-serif;
}
`;
