import Box from "@mui/material/Box";
const TabPanel = ({ value, index, children }) => (
    (() => {
      const tabPanelId = `tabpanel-${index}`;
  
      return (
        <div
          hidden={value !== index}
          id={tabPanelId}
          aria-labelledby={`tab-${index}`}
        >
          {value === index && (
            <Box paddingTop={0} paddingBottom={3}>
              {children}
            </Box>
          )}
        </div>
      );
    })()
  );
  
  export default TabPanel;
  