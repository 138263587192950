import React from 'react';

const TermsAndConditions = () => {
  const acceptTerms = () => {
    alert("Terms and conditions accepted!");
    // Add additional logic here if needed, e.g., redirecting to another page.
  };

  return (
    <div id="termsAndConditions" style={styles.container}>
      <h2 style={styles.heading}>Terms and Conditions</h2>

      <section style={styles.section}>
        <h3 style={styles.subHeading}>Privacy Policy</h3>
        <p style={styles.text}>
          <em>Last updated January 09, 2024</em>

          This privacy notice for Reward Space ('we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:
          <ul>
            <li>Visit our website at <a href="https://reward-space.co.uk">https://reward-space.co.uk</a>, or any website of ours that links to this privacy notice</li>
            <li>Download and use our mobile application (Reward Space), or any other application of ours that links to this privacy notice</li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
          </ul>
        </p>

        <div style={styles.infoBox}>
          <strong>Questions or concerns? </strong>
          Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:support@reward-space.co.uk">support@reward-space.co.uk</a>.
        </div>

<h3 style={styles.subHeading}>SUMMARY OF KEY POINTS</h3>
This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our [table of contents](#table-of-contents) below to find the section you are looking for.
<ul>
  <li>What personal information do we process?</li>
  <li>Do we process any sensitive personal information?</li>
  <li>Do we receive any information from third parties?</li>
  <li>How do we process your information?</li>
  <li>In what situations and with which parties do we share personal information?</li>
  <li>How do we keep your information safe?</li>
  <li>What are your rights?</li>
  <li>How do you exercise your rights?</li>
  <li>Want to learn more about what we do with any information we collect?</li>
</ul>

<h3 style={styles.subHeading}>TABLE OF CONTENTS</h3>
<ol>
  <li><a href="#1-what-information-do-we-collect">WHAT INFORMATION DO WE COLLECT?</a></li>
  <li><a href="#2-how-do-we-process-your-information">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
  <li><a href="#3-what-legal-bases-do-we-rely-on-to-process-your-information">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></li>
  <li><a href="#4-when-and-with-whom-do-we-share-your-personal-information">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
  <li><a href="#5-do-we-use-cookies-and-other-tracking-technologies">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
  <li><a href="#6-how-long-do-we-keep-your-information">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
  <li><a href="#7-how-do-we-keep-your-information-safe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
  <li><a href="#8-do-we-collect-information-from-minors">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
  <li><a href="#9-what-are-your-privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
  <li><a href="#10-controls-for-do-not-track-features">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
  <li><a href="#11-do-we-make-updates-to-this-notice">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
  <li><a href="#12-how-can-you-contact-us-about-this-notice">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
  <li><a href="#13-how-can-you-review,-update,-or-delete-the-data-we-collect-from-you">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
</ol>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>1. WHAT INFORMATION DO WE COLLECT?</h3>
  <div style={styles.subSection}>
    <p>
      <strong>Personal information you disclose to us</strong>
    </p>
    <p style={styles.shortDescription}>
      <em>In Short:</em> We collect personal information that you provide to us.
    </p>
    <p>
      We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and services, when you participate in activities on the Services, or otherwise when you contact us.
    </p>
    <p style={styles.shortDescription}>
      <em>Personal Information Provided by You:</em> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
    </p>
    <ul style={styles.list}>
      <li>Names</li>
      <li>Phone numbers</li>
      <li>Email addresses</li>
      <li>Job titles</li>
      <li>Usernames</li>
      <li>Passwords</li>
    </ul>
    <p>
      <em>Sensitive Information:</em> We do not process sensitive information.
    </p>
    <p>
      <em>Payment Data:</em> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Vendor NAME TBD. You may find their privacy notice link(s) here: <a href="http://www.example.com">http://www.example.com</a>.
    </p>
    <p>
      <em>Application Data:</em> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
    </p>
    <ul style={styles.list}>
      <li>
        <strong>Push Notifications:</strong> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
      </li>
    </ul>
    <p>
      This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
    </p>
  </div>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> We process your information to provide, improve, and administer our services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.
  </p>
  <p>
    We process your personal information for a variety of reasons, depending on how you interact with our services, including:
  </p>
  <ul style={styles.list}>
    <li>
      <strong>Facilitate Account Creation and Authentication:</strong> We may process your information to allow you to create and log in to your account, as well as to keep your account in working order.
    </li>
    <li>
      <strong>Save or Protect an Individual's Vital Interest:</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
    </li>
  </ul>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law. This may include obtaining your consent, complying with laws, providing services to enter into or fulfill our contractual obligations, protecting your rights, or fulfilling our legitimate business interests.
  </p>
  <p>
    The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on to process your personal information. As such, we may rely on the following legal bases to process your personal information:
  </p>
  <ul style={styles.list}>
    <li>
      <strong>Consent:</strong> We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <a href="#9-what-are-your-privacy-rights">withdrawing your consent</a>.
    </li>
    <li>
      <strong>Legal Obligations:</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as cooperating with a law enforcement body or regulatory agency, exercising or defending our legal rights, or disclosing your information as evidence in litigation in which we are involved.
    </li>
    <li>
      <strong>Vital Interests:</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
    </li>
  </ul>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> We may share information in specific situations described in this section and/or with the following third parties.
  </p>
  <p>
    We may need to share your personal information in the following situations:
  </p>
  <ul style={styles.list}>
    <li>
      <strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
    </li>
  </ul>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> We may use cookies and other tracking technologies to collect and store your information.
  </p>
  <p>
    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our <a href="#5-do-we-use-cookies-and-other-tracking-technologies?" style={styles.link}>Cookie Notice</a>.
  </p>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
  </p>
  <p>
    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep your personal information for longer than the period of time in which users have an account with us.
  </p>
  <p>
    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
  </p>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> We aim to protect your personal information through a system of organizational and technical security measures.
  </p>
  <p>
    We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure. Therefore, we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
  </p>
  <p>
    Although we will do our best to protect your personal information, the transmission of personal information to and from our Services is at your own risk. For enhanced security, it is recommended to access the Services within a secure environment.
  </p>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>8. DO WE COLLECT INFORMATION FROM MINORS?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> We do not knowingly collect data from or market to children under 18 years of age.
  </p>
  <p>
    We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:support@reward-space.co.uk">support@reward-space.co.uk</a>.
  </p>
</section>

<section style={styles.section}>
  <h3 style={styles.subHeading}>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
  <p style={styles.shortDescription}>
    <em>In Short:</em> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Switzerland, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
  </p>
  <p>
    In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable data protection laws. These may include the right:
  </p>
  <ol style={styles.orderedList}>
    <li>to request access and obtain a copy of your personal information,</li>
    <li>to request rectification or erasure;</li>
    <li>to restrict the processing of your personal information;</li>
    <li>if applicable, to data portability; and</li>
    <li>not to be subject to automated decision-making.</li>
  </ol>
  <p>
    In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
  </p>
  <p>
    We will consider and act upon any request in accordance with applicable data protection laws. If you are located in the EEA or UK and believe we are unlawfully processing your personal information, you have the right to complain to your Member State data protection authority or UK data protection authority. If you are in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
  </p>
  <p>
    <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, you have the right to withdraw it at any time. Contact us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below or update your preferences. Please note that this will not affect the lawfulness of the processing before withdrawal, nor will it affect processing conducted in reliance on lawful processing grounds other than consent.
  </p>
  <p>
    <strong>Opting out of marketing and promotional communications:</strong> Unsubscribe from our marketing emails at any time by clicking on the unsubscribe link in the emails we send or by contacting us using the details in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you for service-related purposes.
  </p>
  <p>
    <strong>Account Information:</strong> Review or change your account information or terminate your account anytime by logging in to your account settings and updating your user account. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. Some information may be retained in our files to prevent fraud, troubleshoot problems, assist with investigations, enforce legal terms, and comply with applicable legal requirements.
  </p>
  <p>
    <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject cookies. However, this may affect your
  </p>
</section>

    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
  },
  heading: {
    color: '#333',
    borderBottom: '2px solid #333',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '20px',
  },
  subHeading: {
    color: '#333',
    borderBottom: '1px solid #ccc',
    paddingBottom: '5px',
    marginBottom: '10px',
  },
  text: {
    lineHeight: '1.6',
    textAlign: 'justify',
    fontSize: '14px',
  },
  infoBox: {
    backgroundColor: '#e0f7fa',
    padding: '10px',
    marginBottom: '20px',
  },
};

export default TermsAndConditions;