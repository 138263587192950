import PropTypes from "prop-types";
import ArrowDownIcon from "@heroicons/react/24/solid/ArrowDownIcon";
import ArrowUpIcon from "@heroicons/react/24/solid/ArrowUpIcon";
import ArrowPathIcon from "@heroicons/react/24/solid/ArrowPathIcon";
import QuestionMarkIcon from "@heroicons/react/24/solid/QuestionMarkCircleIcon"; // Import the question mark icon

import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  createTheme,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { Filter } from "@mui/icons-material";

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins-Medium", sans-serif',
  },
});

export const StatsMainBox = (props) => {
  var {
    title,
    tooltipText,
    filterRange,
    icon,
    iconColour,
    comparedToPreviousWeek,
    positive = false,
    sx,
    value,
  } = props;

  positive = checkValue(comparedToPreviousWeek);

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ position: "relative", width: 350 /* Your other styles */ }}>
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Stack spacing={2}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">{value}</Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: iconColour,
                height: 56,
                width: 56,
              }}
            >
              <SvgIcon component={icon} fontSize="large" />
            </Avatar>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
            <Typography color="text.secondary" variant="caption">
              {filterRange}
            </Typography>
          </Stack>
        </CardContent>
        {/* Positioning the question mark icon at the bottom right */}
        <Tooltip title={tooltipText} arrow>
          <QuestionMarkIcon
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              margin: "8px", // Add some margin for better spacing
              cursor: "help",
              width: 20, // Adjust the width as needed
              height: 20, // Adjust the height as needed
              color: "#c6c8cc", // Set the color to grey
              display: 'inline-block'
            }}
          />
        </Tooltip>
      </Card>
    </ThemeProvider>
  );
};

const checkValue = (value) => {
  return value >= 1;
};

const colorThresholds = {
  red: -100,
  green: 100,
};

const calculateColor = (value) => {
  const normalizedValue = Math.min(
    1,
    Math.max(
      0,
      (value - colorThresholds.red) /
        (colorThresholds.green - colorThresholds.red)
    )
  );

  // Introduce a factor to determine how much black is mixed with the color
  const blackFactor = 0.5; // Adjust this value to control the amount of black

  // Calculate the red and green components with more black
  const red = Math.round(255 * (1 - normalizedValue) * (1 - blackFactor));
  const green = Math.round(255 * normalizedValue * (1 - blackFactor));

  const blue = 0;
  return `rgb(${red}, ${green}, ${blue})`;
};

StatsMainBox.prototypes = {
  difference: PropTypes.number,
  icon: PropTypes.elementType.isRequired,
  iconColour: PropTypes.string,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
};
