import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const NewsSection = ({ newsItems }) => {
  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderNewsItem = (item, index) => {
    const isExpanded = expanded[index] || false;
    const words = item.content.split(" ");
    const previewText = words.slice(0, 40).join(" ");
    const remainingText = words.slice(40).join(" ");

    return (
      <div key={index} style={{ padding: "12px 2px" }}>
        <div
          style={{
            marginBottom: "12px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            marginLeft: "-2px",
          }}
        >
          <span
            style={{
              backgroundColor:
                item.category === "News"
                  ? "rgba(33, 128, 126, 0.9)"
                  : "rgba(230, 69, 62, 0.9)",
              color: "#ffffff",
              borderRadius: "10px",
              padding: "2px 10px",
              marginRight: "6px",
            }}
          >
            {item.category}
          </span>
          <span
            style={{
              color: "rgba(153, 159, 159, 0.8)",
              fontSize: "12px",
            }}
          >
            {item.date}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            fontSize: "16px",
            color: "#171663",
            fontWeight: "bold",
          }}
        >
          {item.title}
        </div>
        <div
          style={{
            marginBottom: "5px",
            fontSize: "14px",
            color: "#171663",
          }}
        >
          {previewText}
          {isExpanded && remainingText}
          {!isExpanded && remainingText && "..."}
          {!isExpanded && remainingText && (
            <span>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleExpandClick(index);
                }}
                style={{
                  color: "#2d62f9",
                  marginLeft: "8px",
                }}
              >
                Read more
              </a>
              <IconButton
                onClick={() => handleExpandClick(index)}
                style={{ padding: 0, verticalAlign: "middle" }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </span>
          )}
          {isExpanded && (
            <span>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleExpandClick(index);
                }}
                style={{
                  color: "blue",
                  marginLeft: "8px",
                }}
              >
                Read less
              </a>
              <IconButton
                onClick={() => handleExpandClick(index)}
                style={{ padding: 0, verticalAlign: "middle" }}
              >
                <ExpandLessIcon />
              </IconButton>
            </span>
          )}
        </div>
      </div>
    );
  };

  const sortedNewsItems = [...newsItems].reverse();

  return (
    <div
      style={{
        border: "1px solid white",
        padding: "10px",
        paddingLeft: "15px",
        marginLeft: "32px",
        backgroundColor: "white",
        borderRadius: "12px",
        height: "475px", // Set a fixed height for the scrollable area
        overflowY: "auto", // Enable vertical scrolling
        maxWidth: "48.5%",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          color: "#171663",
          fontSize: "16px",
          paddingLeft: "0px",
          paddingBottom: "2px",
        }}
      >
        Whats new?
      </div>
      <div style={{ paddingLeft: "0" }}>
        {sortedNewsItems.map((item, index) => (
          <div key={index}>
            {renderNewsItem(item, index)}
            {index < sortedNewsItems.length - 1 && (
              <div
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  margin: "0",
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsSection;
