import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logoSvg from "../tools/icons/logo.svg";
import "../theme/index.css";
import { ApiServiceClass } from "../components/api-service.js";
import ReCAPTCHA from "react-google-recaptcha";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Register() {
  let navigate = useNavigate();
  const [tabNumber, setTabNumber] = React.useState(0);
  // const [fileAsByteArray, setFileAsByteArray] = useState(null);
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleCompleteUserDetails = (event, newTabNumber) => {
    // Perform additional validation or submit the form if validation passes
    var isFormValid = Object.values(errors).every((error) => error === "");
    if (isAnyTextFieldEmptyOrNull()) {
      isFormValid = false;
      console.log("Empty fields");
    }
    if (isFormValid) {
      // Validation passed, you can submit the form or perform further actions
      // console.log("Form submitted with data:", textFields); Security warning
      setTabNumber(newTabNumber);
    } else {
      // Validation failed, display an error or take appropriate action
      console.log("Form contains validation errors", errors);
      toast.error("Please check all fields", { autoClose: 1000 });
    }
  };

  const isBusinessDetailsInvalid = () => {
    var isFormValid = Object.values(errors).every((error) => error === "");
    if (!isFormValid) {
      toast.error("Please check all fields", { autoClose: 1000 });
      return true
    }

    if (textFields.businessname == null || textFields.businessname === "") return true;

    if (textFields.websiteurl == null || textFields.websiteurl === "") return true;
  };

  const isAnyTextFieldEmptyOrNull = () => {
    if (textFields.email == null || textFields.email === "") return true;

    if (textFields.forename == null || textFields.forename === "") return true;

    if (textFields.password == null || textFields.password === "") return true;
  };

  const [textFields, setTextFields] = useState({
    forename: "",
    surname: "",
    email: "",
    password: "",
    password2: "",
    businessname: "",
    businessaddress: "",
    websiteurl: "",
  });

  const validateField = (fieldName, value, validationRegex, errorMessage) => {
    // console.log('Validating ${fieldName} with value: ${value} and regex: ${validationRegex}');
    if (validationRegex && validationRegex.test) {
      if (!validationRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: errorMessage || "Invalid input",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: "",
        }));
      }
    } else {
      // If validationRegex is not provided or null, clear the error
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    }
  };

  // Function to handle text field changes
  const handleTextFieldChange =
    (fieldName, validationRegex, errorMessage) => (event) => {
      // Update the value of the specified text field
      // console.log("field name: ", fieldName + " event: " + event);
      setTextFields((prevTextFields) => ({
        ...prevTextFields,
        [fieldName]: event.target.value,
      }));

      if (fieldName !== "password2")
        validateField(
          fieldName,
          event.target.value,
          validationRegex,
          errorMessage
        );
    };

  useEffect(() => {
    validatePasswords();
  }, [textFields.password, textFields.password2]);

  const validatePasswords = () => {
    const passwordValue = textFields.password;
    const confirmPasswordValue = textFields.password2;

    if (passwordValue !== confirmPasswordValue && textFields.password2 !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password2: "Passwords do not match",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password2: "",
      }));
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  // Function to handle checkbox changes
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox status
  };

  const verifyCaptcha = async (event) => {
    try {
      const request = {
        captcha: captchaValue,
      };

      console.log("calling captcha: ", request);
      const response = await ApiServiceClass.VerifyCaptcha(request);
      if (response.status === 200) {
        console.log("Success: Captcha verified successfully");
        return true; // Return true indicating successful captcha verification
      } else {
        console.log("Error: Captcha verification failed");
        toast.error("Captcha verification failed");
        return false; // Return false indicating failed captcha verification
      }
    } catch (error) {
      console.error("Error during captcha verification:", error);
      toast.error("Error during captcha verification");
      return false; // Return false indicating failed captcha verification due to error
    }
  };

  const handleRegister = async () => {
    var invalid = isBusinessDetailsInvalid();
    if (invalid) {return false}
    var captcha = await verifyCaptcha();
    if (captcha && !invalid) {
      try {
        const payload = {
          FirstName: textFields.forename,
          EmailAddress: textFields.email,
          LastName: textFields.surname,
          Password: textFields.password,
          CompanyName: textFields.businessname,
          WebsiteUrl: textFields.websiteurl,
        };
        console.log(payload);
        const apiUrl = process.env.REACT_APP_API_BASE_URL + "/RegisterCompany";
        const response = await axios.post(apiUrl, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });
        if (response.status === 200) {
          // console.log("Register success: " + JSON.stringify(response));
          navigate("/login", { state: { fromRegister: true } });
        } else {
          toast.error("Invalid username or password");
        }
      } catch (error) {
        if (error.message.includes("Failed") && error.message.includes("409")) {
          toast.error("Email is already in use");
        } else {
          toast.error("Error");
          console.error("Error:", error);
        }
        throw error;
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="100%"
      className="background-container-wave"
      sx={{ paddingBottom: "2%" }}
    >
      <Link to="/home" style={{ textDecoration: "none", color: "inherit" }}>
        <Box
          sx={{
            padding: 3,
            width: "100%",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Center vertically
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            style={{ marginRight: "10px" }} // Adjusted margin between the SVG and text
          >
            <image href={logoSvg} width="45" height="45" alt="Logo" />
          </svg>

          <span
            style={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "25px",
              color: "#0260FE",
              whiteSpace: "nowrap",
            }}
          >
            Reward Space
          </span>
        </Box>
      </Link>
      <Box
        sx={{
          paddingTop: 3,
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: "0",
          display: "flex",
          maxWidth: "sm",
          margin: "auto",
          bgcolor: "#FFFFFF",
          border: "2px solid #2C3E50",
          borderRadius: "15px",
          flexDirection: "column",
          alignItems: "center",
          "@media (max-width: 768px)": {
            maxWidth: "90%", // Adjust the percentage for smaller screens
          },
        }}
      >
        <span
          style={{
            fontSize: "20px",
            whiteSpace: "nowrap",
            // backgroundColor:'#eeeeee'
            // backgroundColor:'#eeeeee'
          }}
        >
          Register Account
        </span>

        <Box sx={{ width: "100%", backgrounColor: "#444444" }}>
          <Box sx={{ borderColor: "divider" }}>
            <div className="tabsContainer">
              <Tabs
                value={tabNumber}
                onChange={isBusinessDetailsInvalid}
                aria-label="Register Tabs"
              >
                <Tab
                  className="tab"
                  name="Tab1"
                  label="User Details"
                  {...a11yProps(0)}
                // style={{ display: tabNumber === 0 ? "none" : "" }}
                />
                <Tab
                  className="tab"
                  name="Tab2"
                  label="Business Details"
                  {...a11yProps(1)}
                // style={{ display: tabNumber === 0 ? "none" : "" }}
                />
              </Tabs>
            </div>
          </Box>
          <CustomTabPanel
            value={tabNumber}
            index={0}
            sx={{ backgroundColor: "#444444" }}
          >
            <Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="forname"
                label="Forname"
                name="forname"
                autoFocus
                onChange={handleTextFieldChange(
                  "forename",
                  /^[a-zA-Z]+$/,
                  "Invalid first name"
                )}
                value={textFields.forename}
                error={!!errors.forename}
                helperText={errors.forename}
              />
              <TextField
                margin="normal"
                fullWidth
                id="surname"
                label="Surname"
                name="Surname"
                onChange={handleTextFieldChange("surname", null, null)}
                value={textFields.surname}
                error={!!errors.surname}
                helperText={errors.surname}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={handleTextFieldChange(
                  "email",
                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  "Invalid email format"
                )}
                value={textFields.email}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleTextFieldChange(
                  "password",
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  "Passwords must be atleast 8 characters with an uppercase"
                )}
                value={textFields.password}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Confirm Password"
                type="password"
                onChange={handleTextFieldChange(
                  "password2",
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  "Invalid password"
                )}
                value={textFields.password2}
                error={!!errors.password2}
                helperText={errors.password2}
              />
              <Button
                onClick={() => handleCompleteUserDetails(null, 1)}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 2,
                  width: "50%",
                }}
              >
                Next
              </Button>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabNumber} index={1}>
            <Box>
              <TextField
                margin="normal"
                required
                fullWidth
                name="businessname"
                label="Business name"
                type="businessname"
                id="businessname"
                onChange={handleTextFieldChange(
                  "businessname",
                  /.{2,}/,
                  "Invalid business name"
                )}
                value={textFields.businessname}
                error={!!errors.businessname}
                helperText={errors.businessname}
              />

              {/* <TextField
                margin="normal"
                required
                fullWidth
                name="businessaddress"
                label="Business address"
                type="businessddress"
                id="businessddress"
                onChange={handleTextFieldChange(
                  "businessddress",
                  /.{2,}/,
                  "Invalid business name"
                )}
                value={textFields.businessaddress}
                error={!!errors.businessaddress}
                helperText={errors.businessaddress}
              /> */}

              <TextField
                margin="normal"
                fullWidth
                required
                name="websiteurl"
                label="Website URL"
                type="websiteurl"
                id="websiteurl"
                onChange={handleTextFieldChange(
                  "websiteurl",
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)*\/?$/,
                  "Invalid website url"
                )}
                value={textFields.websiteurl}
                error={!!errors.websiteurl}
                helperText={errors.websiteurl}
              />

              <label style={{fontSize: '12px'}}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />{" "}
                I have read and agree to the{" "}
                <a
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleCheckboxChange}
                >
                  terms and conditions
                </a>
              </label>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4%",
              }}
            >
              <ReCAPTCHA
                sitekey="6LcGsgwqAAAAAK28rAWl89KR5j7xBHdBOQvkNpJW"
                onChange={handleCaptchaChange}
              />
            </div>
            <Button
              disabled={!isChecked || !captchaValue}
              type="submit"
              onClick={handleRegister}
              variant="contained"
              sx={{
                mt: 2,
                mb: 4,
                width: "50%",
              }}
            >
              Register
            </Button>
          </CustomTabPanel>
        </Box>
      </Box>
    </Container>
  );
}
