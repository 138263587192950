import logo from "../tools/icons/logo.svg";
import { Box } from "@mui/material";

const RenderCompanyLogo = ({ companyLogo, companyLogoFileType, maxH, maxW, style: customStyles }) => {
  let imageUrl = '';
  switch (companyLogoFileType) {
    case 1:
      imageUrl = companyLogo ? `data:image/svg+xml;base64,${companyLogo}` : '';
      break;
    case 2:
      imageUrl = companyLogo ? `data:image/jpeg;base64,${companyLogo}` : '';
      break;
    case 3:
      imageUrl = companyLogo ? `data:image/png;base64,${companyLogo}` : '';
      break;
    case 4:
      imageUrl = companyLogo ? `data:image/webp;base64,${companyLogo}` : '';
      break;
    default:
      imageUrl = '';
      break;
  }

  return (
    <Box style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    }}>
      <div style={customStyles}>
        {companyLogo ? (
          <div>
            <img
              src={imageUrl}
              style={{
                zIndex: 2,
                maxWidth: maxW,
                maxHeight: maxH
              }}
              alt="Company Logo"
            />
          </div>
        ) : (
          // Display a default logo when companyLogo is not available
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
              zIndex: 2,
              width: maxW,
              height: maxH,
              display: 'block',
              margin: '0 auto',
            }}>
            <image
              href={logo}
              width={maxW}
              height={maxH}
              alt="Logo"
            />
          </svg>
        )}
      </div>
    </Box>
  );
}

export default RenderCompanyLogo;
