import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./navbar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Confetti from "../tools/animation/confetti.json";
import lottie from "lottie-web";
import Button from "@mui/material/Button";
import ReactConfetti from "react-confetti";
import { ApiServiceClass } from "../components/api-service.js";

export default function SubscriptionComplete() {
  const rawCompanyDetails = localStorage.getItem("companyData");
  const [value, setValue] = useState(0);
  const companyDetails = JSON.parse(rawCompanyDetails);
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);
  const navigate = useNavigate();
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const lottieContainer = useRef(null);

  useEffect(() => {
    if (companyDetails === null || loginData === null) {
      console.log("redirecting to /Login");
      navigate("/Login");
    } else {
      const anim = lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: Confetti,
      });
      var storedData = localStorage.getItem("companyData");
      var newData = JSON.parse(storedData);
      newData.IsDemoMode = false;
      localStorage.setItem("companyData", JSON.stringify(newData));
      setIsConfettiActive(true);
      const timer = setTimeout(() => setIsConfettiActive(false), 5000);

      return () => {
        clearTimeout(timer);
        anim.destroy(); // Clean up the animation
      };
    }
  }, []);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Navbar
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Box
          ref={lottieContainer}
          sx={{
            width: "15%",
            height: "15%",
          }}
        ></Box>

        <Typography
          variant="h4"
          gutterBottom
          style={{
            background: "#FFC107",
            WebkitBackgroundClip: "text",
            color: "#0260FE",
            fontFamily: "Poppins-Medium",
            textAlign: "start",
            fontSize: "18px",
            marginTop: "16px",
          }}
        >
          Your subscription has been activated
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          style={{
            background: "#FFC107",
            WebkitBackgroundClip: "text",
            color: "#171663",
            fontFamily: "Poppins-Medium",
            textAlign: "start",
            fontSize: "28px",
            marginTop: "16px",
          }}
        >
          Thank you and welcome to Reward Space
        </Typography>

        <Button
          type="submit"
          href="/Register"
          fontSize="20px"
          variant="contained"
          sx={{
            fontFamily: "Poppins-SemiBold",
            mt: 4,
            mb: 4,
            height: "50px",
            width: "20%",
          }}
        >
          Track your Publication progress
        </Button>

        {isConfettiActive && <ReactConfetti />}
      </Box>
    </Navbar>
  );
}
