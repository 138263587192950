import { useCallback, useState } from "react";
import {
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  Stack,
  DialogActions,
  Typography,
  CardActions,
  IconButton,
  Select,
  MenuItem,
  SvgIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { CCollapse, CCard, CCardBody } from "@coreui/react";
import { ReactComponent as Icon } from "../tools/icons/credit-card-icon.svg";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js"; // Added this import
import { ApiServiceClass } from "../components/api-service.js";

const months = ["Month", ...Array(12).keys()].map((month) => {
  if (month === "Month") {
    return "Month";
  } else {
    const monthNumber = month < 9 ? "0" + (month + 1) : "" + (month + 1);
    return monthNumber;
  }
});

const years = [...Array(12).keys()].map((year) => {
  if (year === 0) {
    return "Year";
  } else {
    return 2024 + year - 1;
  }
});

export const SettingsPaymentsPanel = ({ title, subheader, subscriptionId }) => {
  const stripePromise = loadStripe(
    "pk_test_51Oc7yxKYFd22hVPvVglZgEhzzn3OB7A5K8cPrsV051daN4dxG6rIkNdGI32quSINOJ280o0VXn7iLQ5hZm7tXhmu00kO3f7KHF"
  );

  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);

  const EditPaymentSession = async () => {
    try {
      const requestBody = {
        CustomerId: "cus_QMylUXbFzJZA8p",
        SubscriptionId: "sub_1PWEo9KYFd22hVPvdoKLnboI",
      };

      const data = await ApiServiceClass.EditPaymentDetails(requestBody);
      console.log("Edit Payment Details Data:", data);

      if (!data.ClientSecret) {
        throw new Error("FAIL 2: Missing clientSecret in response data");
      }

      return data;
    } catch (error) {
      console.error("FAIL 4", error);
      throw error;
    }
  };

  const EditPaymentForm = () => {
    const fetchClientSecret = useCallback(async () => {
      try {
        const sessionData = await EditPaymentSession();
        return sessionData.ClientSecret;
      } catch (error) {
        console.error("Error fetching client secret:", error);
        throw error; // Rethrow the error to be caught by the caller
      }
    }, []);

    const options = { fetchClientSecret };
    return (
      <div id="checkout" style={{ width: "70%" }}>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    );
  };

  return (
    <div style={{ padding: "10px" }}>
      <Card sx={{ height: "1%" }}>
        <CardHeader
          title={title}
          subheader={subheader}
          avatar={<Icon style={{ width: "50px", height: "60px" }} />}
        />

        <Divider />
        <CardContent>
          <Stack direction="row" spacing={3}>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div
                    style={{
                      //backgroundColor: "#000000",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <EditPaymentForm />
                  </div>
                </div>
              </div>
            </div>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}></CardActions>
      </Card>
    </div>
  );
};
