import React, { useState } from "react";
//import UploadIcon from "../../resources/new-folder.png"; // Adjust the path to your actual image file
import UploadIcon from "../../resources/new-folder.png"; // Adjust the path to your actual image file

function ImageBox() {
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleUpload = () => {
    // Simulate image upload by setting the uploaded image source
    setUploadedImage(null); // Update this line with the actual image path or URL
    setImageUploaded(true);
  };

  const styles = getStyles(imageUploaded);

  return (
    <div style={styles.imageBox}>
      {!imageUploaded ? (
        <button style={styles.button} onClick={handleUpload}>
          <img src={UploadIcon} alt="upload" style={styles.icon} />
          Upload Image
        </button>
      ) : (
        <div style={styles.imageWrapper}>
          <img
            src={uploadedImage}
            style={styles.imageContainer}
            alt="uploaded"
          />
          <div style={styles.message}>File: image.png (4.5Mb)</div>
        </div>
      )}
    </div>
  );
}

const getStyles = (imageUploaded) => ({
  imageBox: {
    width: "50%",
    minHeight: "240px", // Ensure container has enough space
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    color: "white",
    backgroundColor: imageUploaded ? "transparent" : "#f0f0f0",
    overflow: "hidden",
    flexShrink: 0,
    //border: "2px solid black",
  },
  button: {
    width: "80%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    border: "2px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
    padding: "8px",
    boxSizing: "border-box",
  },
  icon: {
    width: "24px",
    height: "20px",
    marginRight: "10px",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "8px",
  },
  imageContainer: {
    maxWidth: "100%", // Image will scale down but not exceed container width
    maxHeight: "100%", // Set max height to keep the image contained
    objectFit: "contain", // Preserve aspect ratio
    borderRadius: "8px",
  },
  message: {
    marginTop: "5px",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "center",
    color: "#000000",
    height: "25px",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
  },
});

export default ImageBox;
