import React, { useState } from "react";
import {
    Box,
    Typography,
} from "@mui/material";
import { getFontTechnical } from "../components/font-select.js"
import { fontStyles } from '../theme/styles/font.js';


export const DesignAppOffers = ({
    topBannerBackgroundColour,
    topBannerFontColour,
    topBannerFontStyle,
    availableFonts,
}) => {

    return (
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
            <style>{fontStyles}</style>
            {/* Top Banner */}
            <Box
                style={{
                    height: "80px",
                    backgroundColor: topBannerBackgroundColour,
                    width: "100%",
                    position: "absolute",
                    border: 'none',
                    top: 0,
                    zIndex: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Typography className={getFontTechnical(availableFonts, topBannerFontStyle)}
                    style={{ color: topBannerFontColour, marginTop: '35px' }}>
                    My Offers
                </Typography>
            </Box>
            There are no current offers
        </Box>
    );
};
