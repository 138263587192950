import React, { useState } from "react";
import { Button } from "@mui/material";
import { SketchPicker } from "react-color";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";

function ColorsContent({ onCancel, onSave }) {
  const [color, setColor] = useState("#fff"); // Default color

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Top Banner</div>
        <div style={Styles.content}>
          Background Colour
          <ColourBox />
          Font Colour
          <ColourBox />
        </div>
        <div style={Styles.sectionTitle}>Main Content Section</div>
        <div style={Styles.content}>
          Background Font Colour
          <ColourBox />
          Font Colour
          <ColourBox />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.cancelBtn} onClick={onCancel}>
          Reset
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ColorsContent;
