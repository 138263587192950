import React, { useState, useRef, useEffect } from "react";
import Styles from "../../resources/styles";
import { SketchPicker } from "react-color";

function ColourBox({ onCancel, onSave }) {
  const [selectedColour, setSelectedColour] = useState("#000000"); // Default color
  const [showPicker, setShowPicker] = useState(false); // State to toggle the color picker
  const pickerRef = useRef(null); // Ref for the color picker element
  const boxRef = useRef(null); // Ref for the color box element

  const handleChangeComplete = (color) => {
    setSelectedColour(color.hex);
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleClickOutside = (event) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(event.target) &&
      boxRef.current &&
      !boxRef.current.contains(event.target)
    ) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  const isNearWhite = (color, threshold = 200) => {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const brightness = (r + g + b) / 3;

    return brightness > threshold;
  };

  const textColor = isNearWhite(selectedColour) ? "black" : "white";

  useEffect(() => {
    if (showPicker && boxRef.current) {
      const boxRect = boxRef.current.getBoundingClientRect();
      const pickerRect = pickerRef.current.getBoundingClientRect();

      setPickerPosition({
        left: boxRect.right + window.scrollX, // Position to the right of the box
        top:
          boxRect.top +
          window.scrollY -
          pickerRect.height / 2 +
          boxRect.height / 2, // Center vertically relative to the box
      });
    }
  }, [showPicker]);

  const [pickerPosition, setPickerPosition] = useState({ left: 0, top: 0 });

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <div
        ref={boxRef}
        style={{
          ...Styles.colorBox,
          backgroundColor: selectedColour,
          color: textColor,
        }}
        onClick={togglePicker}
        onMouseEnter={(e) =>
          (e.currentTarget.querySelector(".pen-icon").style.display = "block")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.querySelector(".pen-icon").style.display = "none")
        }
      >
        <div className="pen-icon" style={Styles.penIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path
              d="M.75,17.5A.751.751,0,0,1,0,16.75V12.569a.755.755,0,0,1,.22-.53L11.461.8a2.72,2.72,0,0,1,3.848,0L16.7,2.191a2.72,2.72,0,0,1,0,3.848L5.462,17.28a.747.747,0,0,1-.531.22ZM1.5,12.879V16h3.12l7.91-7.91L9.41,4.97ZM13.591,7.03l2.051-2.051a1.223,1.223,0,0,0,0-1.727L14.249,1.858a1.222,1.222,0,0,0-1.727,0L10.47,3.91Z"
              transform="translate(3.25 3.25)"
              fill={textColor}
            />
          </svg>
        </div>

        <div style={{ marginLeft: "5px" }}>{selectedColour}</div>
      </div>
      {showPicker && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "200px",
            zIndex: 9999,
            cursor: "crosshair", // Ensure crosshair cursor is used
          }}
          ref={pickerRef}
        >
          <SketchPicker
            color={selectedColour}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      )}
    </div>
  );
}

export default ColourBox;
