import { ApiServiceClass } from "../components/api-service.js";
import { VideoContainer } from "../components/youtube-embed.js";
import CircularProgress from "@mui/material/CircularProgress";
import { DesignAppSplash, DesignFormSplash } from "../sections/design-splash.js";
import { DesignAppLogin, DesignFormLogin } from "../sections/design-login.js";
import { DesignFormHome } from "../sections/design-home.js";
import { DesignFormCard } from "../sections/design-mycard.js";
import { DesignAppInteractive } from "../sections/design-interactive.js";
import appstore from "../tools/pngs/appstore-icon.png";
import googleplay from "../tools/pngs/googleplay-icon.png";
import {
  Box,
  Button,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import Navbar from "./navbar.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../theme/index.css'
import { toast } from "react-toastify";
import TabPanel from "../components/tab-panel.js";
import DesignAppBackground from "../components/design-app-background.js";
import DesignTab from "../components/design-tab.js";
import { stamps } from '../tools/stamps.js';


export default function Design() {
  const [tabNumber, setTabNumber] = useState(0);
  const [availableFonts, setAvailableFonts] = useState([]);

  const videoId = "My_opPDIA_M";
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [companyLogoFileType, setCompanyLogoFileType] = useState(4)
  const [companyLogo, setCompanyLogo] = useState(null)
  const [splashBackgroundColour, setSplashBackgroundColour] = useState("#ffffff")
  const [companyName, setCompanyName] = useState("Company Name")

  const [topBannerBackgroundColour, setTopBannerBackgroundColour] = useState("#00ff00");
  const [topBannerFontColour, setTopBannerFontColour] = useState("#000000");
  const [topBannerFontStyle, setTopBannerFontStyle] = useState(1);
  const [topBannerFont, setTopBannerFont] = useState("OpenSans Regular");

  const [mainBackgroundColour, setMainBackgroundColour] = useState("#ffffff");
  const [mainBackgroundFontColour, setMainBackgroundFontColour] = useState("#000000");
  const [mainBackgroundFont, setMainBackgroundFont] = useState("OpenSans Regular");
  const [mainBackgroundFontStyle, setMainBackgroundFontStyle] = useState(1);

  const [buttonFont, setButtonFont] = useState("OpenSans Regular");
  const [buttonFontStyle, setButtonFontStyle] = useState(1);
  const [buttonBackgroundColour, setButtonBackgroundColour] = useState("#00ff00");
  const [buttonFontColour, setButtonFontColour] = useState("#000000");

  const [tabBackgroundColor, setTabBackgroundColor] = useState("#ffffff");
  const [tabSelectedColor, setTabSelectedColor] = useState("#000000");
  const [tabDeselectedColor, setTabDeselectedColor] = useState("#888888");
  const [rewardCardBackgroundColor, setRewardCardBackgroundColor] = useState("#cccccc")
  const [rewardCardTextColor, setRewardCardTextColor] = useState("#000000")
  const [outlineButtonBorderColor, setOutlineButtonBorderColor] = useState("#00ff00")
  const [outlineButtonTextColor, setOutlineButtonTextColor] = useState("#ff0000")

  const [rewards, setRewards] = useState({});
  const [isReward, setIsReward] = useState(false);
  const [stampName, setStampName] = useState('star');
  const [stampColor, setStampColor] = useState("#0260fe");
  const [stampBackgroundColor, setStampBackgroundColor] = useState("#ff0000");
  const [rewardName, setRewardName] = useState('Loyalty Card');
  const [rewardSubtitle, setRewardSubtitle] = useState('Free Coffee');
  const [noPurchases, setNoPurchases] = useState(8);
  const [backgroundColor, setBackgroundColor] = useState('#cccccc');
  const [rewardTextColor, setRewardTextColor] = useState('#000000');

  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);
  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  const [isSaved, setIsSaved] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (companyDetails === null || loginData === null) {
      console.log("redirecting to /Login");
      navigate("/Login");
    }
    console.log(loginData.UserId)
    console.log('useEffect')

    const fetchData = async () => {
      console.log(companyDetails.Id)
      try {
        console.log(fetchData)
        const response = await ApiServiceClass.GetAvailableFonts();
        const sortedFonts = [...response].sort((a, b) => a.FontFriendlyName.localeCompare(b.FontFriendlyName));
        // const sortedFonts = availableFonts;
        setAvailableFonts(sortedFonts);
        getCompanyBasicTheme();
        getCompanyMainTheme();
        getRewardStamp();
        console.log(sortedFonts)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData()
  }, []);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const handleFileUpload = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      if (selectedFile.type === 'image/svg+xml') {
        setUploadedFileName(selectedFile.name);
        reader.onloadend = () => {
          const svgContent = reader.result;
          // Remove width and height attributes if they exist
          const updatedSvg = svgContent.replace(/width="(\d+)"/g, '')
            .replace(/height="(\d+)"/g, '');
          // Convert the updated SVG content to Base64
          const base64Encoded = btoa(updatedSvg);
          setCompanyLogoFileType(1);
          setCompanyLogo(base64Encoded);
          console.log('file type is svg')
        };
        reader.readAsText(selectedFile);

      } else if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/webp') {
        setUploadedFileName(selectedFile.name);
        reader.onloadend = () => {
          // Convert content to Base64
          const base64Encoded = btoa(reader.result);
          let fileType;
          if (selectedFile.type === 'image/jpeg') {
            fileType = 2;
          } else if (selectedFile.type === 'image/png') {
            fileType = 3;
          } else if (selectedFile.type === 'image/webp') {
            fileType = 4;
          }
          setCompanyLogoFileType(fileType);
          setCompanyLogo(base64Encoded);
          console.log('file type is jpeg, png or webp')
        };
        reader.readAsBinaryString(selectedFile);

      } else {
        // Handle other file types
        toast.error('File type must be SVG, JPG, PNG, or WebP');
      }
    } else {
      toast.error('No file selected');
    }
  };

  const getCompanyBasicTheme = async () => {
    try {
      const body = {
        "CompanyId": companyDetails.Id,
      }
      const data = await ApiServiceClass.GetCompanyBasicTheme(body);
      console.log(data);
      setCompanyLogoFileType(data.FileType)
      setCompanyLogo(data.CompanyLogo)
      setSplashBackgroundColour(data.SplashScreenBackgroundColour)
      setCompanyName(data.CompanyName)
    } catch (error) {
      console.error("error in getCompanyBasicTheme", error)
    }
  }

  const getRewardStamp = async () => {
    try {
      var requestBody = {
        "CompanyId": companyDetails.Id,
        "RequesterUserId": loginData.UserId
      }
      const data = await ApiServiceClass.GetRewardDetails(requestBody);
      console.log('data')
      console.log(data);
      setRewards(data);
      setIsReward(true);

      setNoPurchases(data.PurchasesRequired)
      setRewardName(data.Name)
      setRewardSubtitle(data.SubTitle)
      setBackgroundColor(data.BackgroundColor)
      setStampBackgroundColor(data.StampBackgroundColor)
      setStampColor(data.StampColor)
      setStampName(data.SelectedStampName)
      setRewardTextColor(data.TextColor)

    } catch (error) {
      console.error('Error fetching data:', error);
      setIsReward(false)
      console.log('no reward found')
    }
  };

  const saveReward = async () => {
    setIsSaved(true)
    try {
      if (isReward) {
        var requestBody = {
          "RequesterUserId": loginData.UserId,
          "CompanyId": companyDetails.Id,
          "RewardId": rewards.Id,
          "NumberOfPurchasesRequired": noPurchases,
          "Description": rewardName,
          "SubTitle": rewardSubtitle,
          "BackgroundColor": backgroundColor,
          "StampBackgroundColor": stampBackgroundColor,
          "StampColor": stampColor,
          "SelectedStampName": stampName,
          "StampTextColor": rewardTextColor,
          "OutlineButtonBorderColor": outlineButtonBorderColor,
          "OutlineButtonTextColor": outlineButtonTextColor
        };
        console.log(requestBody)
        const data = await ApiServiceClass.EditStampReward(requestBody);
      }
      else {
        var requestBody = {
          "RequesterUserId": loginData.UserId,
          "CompanyId": companyDetails.Id,
          "Description": rewardName,
          "NumberOfPurchasesRequired": noPurchases,
          "SubTitle": rewardSubtitle,
          "BackgroundColor": backgroundColor,
          "StampBackgroundColor": stampBackgroundColor,
          "StampColor": stampColor,
          "SelectedStampName": stampName,
          "StampTextColor": rewardTextColor,
          "OutlineButtonBorderColor": outlineButtonBorderColor,
          "OutlineButtonTextColor": outlineButtonTextColor
        }
        console.log(requestBody)
        const data = await ApiServiceClass.CreateStampReward(requestBody);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // fetchData();
  };

  const handleSubmitCompanyBasic = async (e) => {
    const body = {
      "CompanyId": companyDetails.Id,
      "CompanyLogoFileType": companyLogoFileType,
      "CompanyLogo": companyLogo,
      "SplashBackgroundColour": splashBackgroundColour,
      "CompanyName": companyName,
      "RequesterUserId": loginData.UserId,
    }
    console.log(body)
    const data = await ApiServiceClass.SetupCompanyBasicTheme(body);
  };

  const handleSave = async () => {
    console.log("topBannerFont: ", topBannerFont)
    handleSubmitCompanyBasic();
    handleSubmitCompanyMain();
    saveReward();
    toast.success('Design changes saved');
  }

  const getCompanyMainTheme = async () => {
    try {
      const body = {
        "CompanyId": companyDetails.Id,
      }
      const data = await ApiServiceClass.GetCompanyMainTheme(body);
      console.log('getCompanyMainTheme')
      console.log(data)
      setTopBannerBackgroundColour(data.TopBannerBackgroundColour)
      setTopBannerFontColour(data.TopBannerFontColour)
      setTopBannerFontStyle(data.TopBannerFontStyle)
      setTopBannerFont(data.TopBannerFont)

      setMainBackgroundColour(data.MainBackgroundColour)
      setMainBackgroundFontColour(data.MainBackgroundFontColour)
      setMainBackgroundFont(data.MainBackgroundFont)
      setMainBackgroundFontStyle(data.MainBackgroundFontStyle)

      setButtonBackgroundColour(data.ButtonBackgroundColour)
      setButtonFontColour(data.ButtonFontColour)
      setButtonFont(data.ButtonFont)
      setButtonFontStyle(data.ButtonFontStyle)

      setTabBackgroundColor(data.TabBarBackgroundColor)
      setTabSelectedColor(data.TabBarSelectedColor)
      setTabDeselectedColor(data.TabBarUnselectedColor)
      setRewardCardBackgroundColor(data.RewardCardBackgroundColor)
      setRewardCardTextColor(data.RewardCardTextColor)
      setOutlineButtonBorderColor(data.OutlineButtonBorderColor)
      setOutlineButtonTextColor(data.OutlineButtonTextColor)

    } catch (error) {
      console.error("error in getCompanyMainTheme", error)
    }
  }

  const handleSubmitCompanyMain = async (e) => {
    console.log(loginData.UserId)
    const body = {
      "CompanyId": companyDetails.Id,
      "RequesterUserId": loginData.UserId,
      "TopBannerBackgroundColour": topBannerBackgroundColour,
      "TopBannerFontColour": topBannerFontColour,
      "TopBannerFontStyle": topBannerFontStyle,
      "TopBannerFont": topBannerFont,
      "MainBackgroundColour": mainBackgroundColour,
      "MainBackgroundFontColour": mainBackgroundFontColour,
      "MainBackgroundFont": mainBackgroundFont,
      "MainBackgroundFontStyle": mainBackgroundFontStyle,
      "ButtonBackgroundColour": buttonBackgroundColour,
      "ButtonFontColour": buttonFontColour,
      "ButtonFont": buttonFont,
      "ButtonFontStyle": buttonFontStyle,
      "TabBarSelectedColor": tabSelectedColor,
      "TabBarUnselectedColor": tabDeselectedColor,
      "TabBarBackgroundColor": tabBackgroundColor,
      "RewardCardBackgroundColor": rewardCardBackgroundColor,
      "RewardCardTextColor": rewardCardTextColor,
      "OutlineButtonBorderColor": outlineButtonBorderColor,
      "OutlineButtonTextColor": outlineButtonTextColor
    }
    console.log(body)
    const data = await ApiServiceClass.SetupCompanyMainTheme(body);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleBack = () => {
    if (tabNumber > 0) {
      setTabNumber(tabNumber - 1);
    }
  }

  const noTabs = 5;
  const handleNext = () => {
    if (tabNumber < noTabs) {
      setTabNumber(tabNumber + 1);
    }
  }

  const AppDownloadInfo = ({ isSaved, companyDetails, appstore, googleplay }) => {
    return (
      isSaved && (
        <div>
          <div>
            <div style={{ marginTop: "50px", textAlign: "center" }}>
              <p>Your App will soon be available on the AppStore and IOS store with demo code <strong>{companyDetails.DemoCode}</strong></p>
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
              <p>Email: demo@rewardspace.co.uk</p>
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
              <p>Password: Tester123!</p>
            </div>
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <p>Download the app and enter the demo code, email and password to view a demonstration of your app</p>
            </div>
          </div>
          <div style={{ flexWrap: 'wrap', marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', margin: '10px', height: '80px' }}>
              <img src={appstore} alt="App Store" style={{ width: '250px', height: '100%', cursor: 'pointer' }} />
            </div>
            <div style={{ textAlign: 'center', margin: '10px', height: '80px' }}>
              <img src={googleplay} alt="Google Play" style={{ width: '250px', height: '100%', cursor: 'pointer' }} />
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <Navbar name="Design" title="Design Your App">
      <Box component="main"
        sx={{
          width: '100%',
          backgroundColor: "#FFFFFF",
          borderRadius: '25px',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
        }}>
        <div className="tabsContainer" name="tc1" style={{ textAlign: "center" }}>
          <Tabs
            value={tabNumber}
            onChange={(event, newTabNumber) => {
              setTabNumber(newTabNumber)
            }}
            aria-label="Register Tabs"
            sx={{ minWidth: '120px', display: 'none' }}
          >
            <Tab
              className="tab"
              name="Tab0"
              label="Instructions"
              value={0}
              sx={{ marginLeft: 3 }}
            />
            <Tab
              className="tab"
              name="Tab1"
              label="Splash"
              value={1}
              sx={{ marginLeft: 3 }}
            />
            <Tab
              className="tab"
              name="Tab2"
              label="Login"
              value={2}
              sx={{ marginLeft: 3 }}
            />
            <Tab
              className="tab"
              name="Tab3"
              label="MyCard"
              value={3}
              sx={{ marginLeft: 3 }}
            />
            <Tab
              className="tab"
              name="Tab4"
              label="Home"
              value={4}
              sx={{ marginLeft: 3 }}
            />
            <Tab
              className="tab"
              name="Tab5"
              label="LastTab"
              value={5}
              sx={{ marginLeft: 3 }}
            />
          </Tabs>
        </div>

        <div>
          {/* Tab 0 */}
          <TabPanel value={tabNumber} index={0}>
            <h2 style={{ textAlign: "center", }}>Information</h2>
            <Box sx={{
              display: "flex",
              justifyContent: 'center',
              marginTop: '20px',
              textAlign: 'center',
              alignItems: 'center',
              padding: '10px',
            }}>
              <VideoContainer videoId={videoId} width={'68%'} />
            </Box>
          </TabPanel>

          {/* Tab 1 Splash Loading Screen*/}
          <DesignTab
            title={"Step 1"}
            tabNumber={tabNumber}
            index={1}
            app={
              <DesignAppSplash
                splashBackgroundColour={splashBackgroundColour}
                companyLogo={companyLogo}
                companyLogoFileType={companyLogoFileType}
              />}
            form={
              <DesignFormSplash
                companyLogoFileType={companyLogoFileType}
                companyLogo={companyLogo}
                uploadedFileName={uploadedFileName}
                splashBackgroundColour={splashBackgroundColour}
                setSplashBackgroundColour={setSplashBackgroundColour}
                companyName={companyName}
                setCompanyName={setCompanyName}
                handleFileUpload={handleFileUpload}
              />}
          />

          {/* Tab 2 Login Page */}
          <DesignTab
            title={"Step 2"}
            tabNumber={tabNumber}
            index={2}
            app={
              <DesignAppLogin
                topBannerBackgroundColour={topBannerBackgroundColour}
                topBannerFontColour={topBannerFontColour}
                topBannerFontStyle={topBannerFontStyle}
                mainBackgroundColour={mainBackgroundColour}
                mainBackgroundFontColour={mainBackgroundFontColour}
                mainBackgroundFontStyle={mainBackgroundFontStyle}
                tabBackgroundColor={tabBackgroundColor}
                tabSelectedColor={tabSelectedColor}
                tabDeselectedColor={tabDeselectedColor}
                buttonFontStyle={buttonFontStyle}
                buttonBackgroundColour={buttonBackgroundColour}
                buttonFontColour={buttonFontColour}
                companyLogo={companyLogo}
                companyLogoFileType={companyLogoFileType}
                availableFonts={availableFonts}
              />}
            form={
              <DesignFormLogin
                topBannerBackgroundColour={topBannerBackgroundColour}
                setTopBannerBackgroundColour={setTopBannerBackgroundColour}
                topBannerFontColour={topBannerFontColour}
                setTopBannerFontColour={setTopBannerFontColour}
                topBannerFontStyle={topBannerFontStyle}
                setTopBannerFontStyle={setTopBannerFontStyle}
                topBannerFont={topBannerFont}
                setTopBannerFont={setTopBannerFont}
                availableFonts={availableFonts}

                mainBackgroundColour={mainBackgroundColour}
                setMainBackgroundColour={setMainBackgroundColour}
                mainBackgroundFontColour={mainBackgroundFontColour}
                setMainBackgroundFontColour={setMainBackgroundFontColour}
                mainBackgroundFont={mainBackgroundFont}
                setMainBackgroundFont={setMainBackgroundFont}
                mainBackgroundFontStyle={mainBackgroundFontStyle}
                setMainBackgroundFontStyle={setMainBackgroundFontStyle}

                buttonFont={buttonFont}
                setButtonFont={setButtonFont}
                buttonFontStyle={buttonFontStyle}
                setButtonFontStyle={setButtonFontStyle}
                buttonBackgroundColour={buttonBackgroundColour}
                setButtonBackgroundColour={setButtonBackgroundColour}
                buttonFontColour={buttonFontColour}
                setButtonFontColour={setButtonFontColour}
              />}
          />

          {/* Tab 3 Reward Card */}
          <DesignTab
            title={"Step 3"}
            tabNumber={tabNumber}
            index={3}
            app={
              <DesignAppInteractive
                // splash
                companyLogo={companyLogo}
                companyLogoFileType={companyLogoFileType}
                // login 
                topBannerBackgroundColour={topBannerBackgroundColour}
                topBannerFontColour={topBannerFontColour}
                topBannerFontStyle={topBannerFontStyle}
                mainBackgroundFontColour={mainBackgroundFontColour}
                mainBackgroundFontStyle={mainBackgroundFontStyle}
                mainBackgroundColour={mainBackgroundColour}
                buttonBackgroundColour={buttonBackgroundColour}
                buttonFontColour={buttonFontColour}
                buttonFontStyle={buttonFontStyle}
                availableFonts={availableFonts}
                // mycard
                tabBackgroundColor={tabBackgroundColor}
                tabSelectedColor={tabSelectedColor}
                tabDeselectedColor={tabDeselectedColor}
                rewardCardBackgroundColor={rewardCardBackgroundColor}
                rewardCardTextColor={rewardCardTextColor}
                // home
                stamp={stamps[stampName]}
                stampName={stampName}
                stampColor={stampColor}
                stampBackgroundColor={stampBackgroundColor}
                backgroundColor={backgroundColor}
                rewardName={rewardName}
                rewardSubtitle={rewardSubtitle}
                rewardTextColor={rewardTextColor}
                noPurchases={noPurchases}
                // other
                initialTab={2} />
            }
            form={
              <DesignFormCard
                rewardCardTextColor={rewardCardTextColor}
                setRewardCardTextColor={setRewardCardTextColor}
                rewardCardBackgroundColor={rewardCardBackgroundColor}
                setRewardCardBackgroundColor={setRewardCardBackgroundColor}
                tabSelectedColor={tabSelectedColor}
                setTabSelectedColor={setTabSelectedColor}
                tabDeselectedColor={tabDeselectedColor}
                setTabDeselectedColor={setTabDeselectedColor}
                tabBackgroundColor={tabBackgroundColor}
                setTabBackgroundColor={setTabBackgroundColor}
              />}
          />

          {/* Tab 4 Home Page */}
          <DesignTab
            title={"Step 4"}
            tabNumber={tabNumber}
            index={4}
            app={
              <DesignAppInteractive
                // splash
                companyLogo={companyLogo}
                companyLogoFileType={companyLogoFileType}
                // login 
                topBannerBackgroundColour={topBannerBackgroundColour}
                topBannerFontColour={topBannerFontColour}
                topBannerFontStyle={topBannerFontStyle}
                mainBackgroundFontColour={mainBackgroundFontColour}
                mainBackgroundFontStyle={mainBackgroundFontStyle}
                mainBackgroundColour={mainBackgroundColour}
                buttonBackgroundColour={buttonBackgroundColour}
                buttonFontColour={buttonFontColour}
                buttonFontStyle={buttonFontStyle}
                availableFonts={availableFonts}
                // mycard
                tabBackgroundColor={tabBackgroundColor}
                tabSelectedColor={tabSelectedColor}
                tabDeselectedColor={tabDeselectedColor}
                rewardCardBackgroundColor={rewardCardBackgroundColor}
                rewardCardTextColor={rewardCardTextColor}
                // home
                stamp={stamps[stampName]}
                stampName={stampName}
                stampColor={stampColor}
                stampBackgroundColor={stampBackgroundColor}
                backgroundColor={backgroundColor}
                rewardName={rewardName}
                rewardSubtitle={rewardSubtitle}
                rewardTextColor={rewardTextColor}
                noPurchases={noPurchases}
                // other
                initialTab={1} />}
            form={
              <DesignFormHome
                stamps={stamps}
                rewardName={rewardName}
                setRewardName={setRewardName}
                rewardSubtitle={rewardSubtitle}
                setRewardSubtitle={setRewardSubtitle}
                stampName={stampName}
                setStampName={setStampName}
                noPurchases={noPurchases}
                setNoPurchases={setNoPurchases}
                rewardTextColor={rewardTextColor}
                setRewardTextColor={setRewardTextColor}
                outlineButtonBorderColor={outlineButtonBorderColor}
                setOutlineButtonBorderColor={setOutlineButtonBorderColor}
                outlineButtonTextColor={outlineButtonTextColor}
                setOutlineButtonTextColor={setOutlineButtonTextColor}

                backgroundColor={backgroundColor}
                setBackgroundColor={setBackgroundColor}
                stampColor={stampColor}
                setStampColor={setStampColor}
                stampBackgroundColor={stampBackgroundColor}
                setStampBackgroundColor={setStampBackgroundColor}

                buttonFont={buttonFont}
                setButtonFont={setButtonFont}
                buttonFontStyle={buttonFontStyle}
                setButtonFontStyle={setButtonFontStyle}
                buttonBackgroundColour={buttonBackgroundColour}
                setButtonBackgroundColour={setButtonBackgroundColour}
                buttonFontColour={buttonFontColour}
                setButtonFontColour={setButtonFontColour}

                availableFonts={availableFonts}
                maxWidth={window.innerWidth}
              />
            }
          />

          {/* Last Tab Review your app */}
          <DesignTab
            title={"Step 5"}
            tabNumber={tabNumber}
            index={5}
            app={
              <DesignAppInteractive
                // splash
                companyLogo={companyLogo}
                companyLogoFileType={companyLogoFileType}
                // login 
                topBannerBackgroundColour={topBannerBackgroundColour}
                topBannerFontColour={topBannerFontColour}
                topBannerFontStyle={topBannerFontStyle}
                mainBackgroundFontColour={mainBackgroundFontColour}
                mainBackgroundFontStyle={mainBackgroundFontStyle}
                mainBackgroundColour={mainBackgroundColour}
                buttonBackgroundColour={buttonBackgroundColour}
                buttonFontColour={buttonFontColour}
                buttonFontStyle={buttonFontStyle}
                availableFonts={availableFonts}
                // mycard
                tabBackgroundColor={tabBackgroundColor}
                tabSelectedColor={tabSelectedColor}
                tabDeselectedColor={tabDeselectedColor}
                rewardCardBackgroundColor={rewardCardBackgroundColor}
                rewardCardTextColor={rewardCardTextColor}
                // home
                stamp={stamps[stampName]}
                stampName={stampName}
                stampColor={stampColor}
                stampBackgroundColor={stampBackgroundColor}
                backgroundColor={backgroundColor}
                rewardName={rewardName}
                rewardSubtitle={rewardSubtitle}
                rewardTextColor={rewardTextColor}
                noPurchases={noPurchases}
                // other
                initialTab={1} />}
            form={
              <Box sx={{ display: 'flex', marginTop: '20px', flexDirection: 'column', textAlign: "center", width: '100%', maxWidth: '750px' }}>
                <div style={{ display: "center", width: '100%' }}>
                  <div style={{ marginTop: "50px" }}>
                    <p>Save your changes to update your app.</p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                    <Button sx={{
                      width: '100px',
                    }} onClick={handleSave}>
                      Save
                    </Button>
                  </div>
                </div>
                <div>
                  <AppDownloadInfo
                    isSaved={isSaved}
                    companyDetails={companyDetails}
                    appstore={appstore}
                    googleplay={googleplay}
                  />
                </div>
              </Box>
            } />
        </div>

        {/* Footer */}
        <div style={{ marginTop: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
            <div style={{ width: '50%', textAlign: 'center' }}>
              {tabNumber > 0 && (
                <Button sx={{ width: '100px' }} onClick={handleBack}>
                  Back
                </Button>)}
            </div>
            <Box sx={{ width: '50%', textAlign: 'center' }}>
              <LinearProgress variant="determinate" value={tabNumber / noTabs * 100} sx={{ width: '100%' }} />
              <Typography variant="caption">{100 * tabNumber / noTabs}%</Typography>
            </Box>
            <div style={{ width: '50%', textAlign: 'center' }}>
              {tabNumber < noTabs && (
                <Button sx={{ width: '100px' }} onClick={handleNext}>
                  Next
                </Button>
              )}
            </div>
          </Box>
        </div>
      </Box>
    </Navbar>
  );
};
