import { useEffect, useState } from "react";
import SidePanelCard from "./sidepanelcard";
import LogoContent from "./cards/LogoContent"; // Import the unique components
import LaunchScreenContent from "./cards/LaunchScreenContent";
import ColorsContent from "./cards/ColorsContent";
import FontsContent from "./cards/FontsContent";
import ButtonsContent from "./cards/ButtonsContent";
import NavigationContent from "./cards/NavigationContent";
import LoyaltyContent from "./cards/LoyaltyContent";
import RewardContent from "./cards/RewardContent";
// Import other components as needed
import { Button } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function DesignSidePanel({ onWidthChange }) {
  const [width, setWidth] = useState("30%"); // State to control panel width
  const [selectedCard, setSelectedCard] = useState(null);
  const [headerText, setHeaderText] = useState("Design your App");
  const [companyLogoLocked, setcompanyLogoLocked] = useState(false);
  const [launchScreenLocked, setlaunchScreenLocked] = useState(false);
  const [coloursLocked, setcoloursLocked] = useState(false);
  const [fontsLocked, setfontsLocked] = useState(false);
  const [buttonsLocked, setbuttonsLocked] = useState(false);
  const [navigationMenuLocked, setnavigationMenuLocked] = useState(false);
  const [loyaltyCardLocked, setloyaltyCardLockedLocked] = useState(false);
  const [rewardSchemeLocked, setrewardSchemeLocked] = useState(false);
  const [autoNavigate, setAutoNavigate] = useState(true);

  const handleAutoNavigateChange = (event) => {
    setAutoNavigate(event.target.checked);
  };

  const togglePanelToMin = () => {
    setWidth("4%");
    onWidthChange("4%");
  };

  const togglePanelToDefault = () => {
    setWidth("30%");
    onWidthChange("30%");
  };

  const contentStyle = {
    padding: "20px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  };

  const panelStyle = {
    position: "fixed",
    right: 0,
    width: width,
    height: "100%",
    backgroundColor: "#f7f9fc",
    //backgroundColor: "#000000",
    boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
    transition: "width 0.3s",
    display: "flex",
    flexDirection: "column",
  };

  const sidepanelHeader = {
    width: "100%",
    maxHeight: "5%",
    minHeight: "5%",
    borderBottom: "1px solid #e0e0e0",
    display: "flex",
    flexDirection: "row", // Align children in a row
    justifyContent: "space-between", // Space between the child containers
    alignItems: "center", // Center items vertically
  };

  const leftContainerStyle = {
    width: "10%",
    //backgroundColor: "red",
    zIndex: 10,
    marginLeft: "-12%",
  };
  const rightContainerStyle = {
    width: "100%",
    //backgroundColor: "pink",
    marginLeft: "-25%",
  };

  const backButtonStyle = {
    color: "#007bff",
    cursor: "pointer",
    fontWeight: "bold",
    backgroundColor: "#0260FE", // Background color for visibility
    color: "#ffffff",
    borderRadius: "6px",
    fontSize: "16px",
    paddingLeft: "15%",
    paddingTop: "2%",
    minHeight: "30px",
    marginLeft: "0%",
    alignItems: "center", // Center vertically
  };

  const headerTextStyle = {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center", // Center text horizontally
  };

  const selectCard = (card) => {
    console.log(card);
    switch (card) {
      case "Company Logo":
        if (companyLogoLocked) {
          console.log("Company Logo is locked.");
          return; // Exit the function if the card is locked
        }
        break;
      case "Launch screen":
        if (launchScreenLocked) {
          console.log("Launch screen is locked.");
          return;
        }
        break;
      case "Colors":
        if (coloursLocked) {
          console.log("Colors are locked.");
          return;
        }
        break;
      case "Fonts":
        if (fontsLocked) {
          console.log("Fonts are locked.");
          return;
        }
        break;
      case "Buttons":
        if (buttonsLocked) {
          console.log("Buttons Menu is locked.");
          return;
        }
        break;
      case "Navigation Menu":
        if (navigationMenuLocked) {
          console.log("Navigation Menu is locked.");
          return;
        }
        break;
      case "Digital Loyalty Card":
        if (loyaltyCardLocked) {
          console.log("Digital Loyalty Card is locked.");
          return;
        }
        break;
      case "Reward Scheme":
        if (rewardSchemeLocked) {
          console.log("Reward Scheme is locked.");
          return;
        }
        break;
      case "Reset":
        alert("Are you sure you want to reset your current design?");
        return;

      default:
        alert("Unknown card selected.");
        return;
    }

    setSelectedCard(card);
    setHeaderText(card);
  };

  const handleCancel = () => {
    console.log("Cancel clicked");
    setSelectedCard(null);
    setHeaderText("Design your App");
  };

  const handleSave = () => {
    console.log("Save clicked");
  };

  const renderSelectedCard = () => {
    switch (selectedCard) {
      case "Company Logo":
        return <LogoContent onCancel={handleCancel} onSave={handleSave} />;
      case "Launch screen":
        return (
          <LaunchScreenContent onCancel={handleCancel} onSave={handleSave} />
        );
      case "Colors":
        return <ColorsContent onCancel={handleCancel} onSave={handleSave} />;
      case "Fonts":
        return <FontsContent onCancel={handleCancel} onSave={handleSave} />;
      case "Buttons":
        return <ButtonsContent onCancel={handleCancel} onSave={handleSave} />;
      case "Navigation Menu":
        return (
          <NavigationContent onCancel={handleCancel} onSave={handleSave} />
        );
      case "Digital Loyalty Card":
        return <LoyaltyContent onCancel={handleCancel} onSave={handleSave} />;
      case "Reward Scheme":
        return <RewardContent onCancel={handleCancel} onSave={handleSave} />;
      default:
        return null;
    }
  };

  const openPanelContent = () => {
    if (selectedCard === null) {
      return (
        <div style={contentStyle}>
          <SidePanelCard
            icon="icon"
            iconWidth="70px"
            iconHeight="70px"
            title="Company Logo"
            onClick={() => selectCard("Company Logo")}
          />
          <SidePanelCard
            icon="launch"
            iconWidth="70px"
            iconHeight="70px"
            title="Launch screen"
            isLocked={launchScreenLocked}
            onClick={() => selectCard("Launch screen")}
          />
          <SidePanelCard
            icon="paint"
            iconWidth="70px"
            iconHeight="70px"
            title="Colours"
            isLocked={coloursLocked}
            onClick={() => selectCard("Colors")}
          />
          <SidePanelCard
            icon="fonts"
            iconWidth="70px"
            iconHeight="70px"
            title="Fonts"
            isLocked={coloursLocked}
            onClick={() => selectCard("Fonts")}
          />
          <SidePanelCard
            icon="button"
            iconWidth="70px"
            iconHeight="70px"
            title="Buttons"
            isLocked={buttonsLocked}
            onClick={() => selectCard("Buttons")}
          />
          <SidePanelCard
            icon="navigation"
            iconWidth="70px"
            iconHeight="70px"
            title="Navigation Menu"
            isLocked={navigationMenuLocked}
            onClick={() => selectCard("Navigation Menu")}
          />
          <SidePanelCard
            icon="scanner"
            iconWidth="70px"
            iconHeight="70px"
            title="Digital Loyalty Card"
            isLocked={loyaltyCardLocked}
            onClick={() => selectCard("Digital Loyalty Card")}
          />
          <SidePanelCard
            icon="gift"
            iconWidth="70px"
            iconHeight="70px"
            title="Reward Scheme"
            isLocked={rewardSchemeLocked}
            onClick={() => selectCard("Reward Scheme")}
          />
          <SidePanelCard
            icon="reset"
            iconWidth="70px"
            iconHeight="70px"
            isLocked="true"
            title="Reset Design"
            isLocked={false}
            onClick={() => selectCard("Reset")}
          />
        </div>
      );
    }

    return renderSelectedCard();
  };

  const buttonStyleOpen = {
    position: "relative",
    left: "-10%",
    top: "-15px",
    height: "0px",
    width: "30px",
    zIndex: 10,
    backgroundColor: "#0260FE",
    color: "white",
    border: "none",
    cursor: "pointer",
  };

  const buttonStyleClosed = {
    position: "relative",
    left: "-70%",
    top: "35px",
    height: "30px",
    width: "30px",
    zIndex: 10,
    backgroundColor: "#0260FE",
    color: "white",
    border: "none",
    cursor: "pointer",
  };

  const buttonStyle = width === "30%" ? buttonStyleOpen : buttonStyleClosed;

  const renderContent = () => {
    if (width === "30%") {
      return (
        <div style={panelStyle}>
          <div style={sidepanelHeader}>
            <div style={buttonStyle}>
              <ChevronRight
                style={{
                  color: "#ffffff", // Set icon color
                  backgroundColor: "#0260FE",
                  fontSize: "32px",
                }}
                onClick={togglePanelToMin}
              />
            </div>
            <div style={leftContainerStyle}>
              {selectedCard !== null && (
                <div style={backButtonStyle} onClick={handleCancel}>
                  Back
                </div>
              )}
            </div>
            <div style={rightContainerStyle}>
              <div style={headerTextStyle}>{headerText}</div>
            </div>
          </div>
          <div>{openPanelContent()}</div>
          <div style={{ marginTop: "auto", padding: "10px 4%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoNavigate}
                  onChange={handleAutoNavigateChange}
                  name="checkedA"
                />
              }
              label="Auto navigate to selected component"
              style={{
                color: "#000000",
              }}
            />
          </div>
        </div>
      );
    } else if (width === "4%") {
      return (
        <div>
          <div style={buttonStyle}>
            <ChevronLeft
              style={{
                color: "#ffffff", // Set icon color
                fontSize: "32px",
              }}
              onClick={togglePanelToDefault}
            />
          </div>
          <div>
            <SidePanelCard
              icon="icon"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Company Logo");
              }}
            />
            <SidePanelCard
              icon="launch"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Launch screen");
              }}
            />
            <SidePanelCard
              icon="paint"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Colors");
              }}
            />
            <SidePanelCard
              icon="fonts"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Fonts");
              }}
            />
            <SidePanelCard
              icon="button"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Buttons");
              }}
            />
            <SidePanelCard
              icon="navigation"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Navigation Menu");
              }}
            />
            <SidePanelCard
              icon="scanner"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Digital Loyalty Card");
              }}
            />
            <SidePanelCard
              icon="gift"
              iconWidth="40px"
              iconHeight="35px"
              onClick={() => {
                togglePanelToDefault();
                selectCard("Reward Scheme");
              }}
            />
          </div>
        </div>
      );
    } else {
      // Return null or some fallback content if needed
      return null;
    }
  };

  return renderContent();
}

export default DesignSidePanel;
