import React from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import FontBox from "./sharedcomponents/FontBox";

function ButtonsContent({ onCancel, onSave }) {
  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Colours</div>
        <div style={Styles.content}>
          Background Colour
          <ColourBox />
          Font Colour
          <ColourBox />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.cancelBtn} onClick={onCancel}>
          Reset
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ButtonsContent;
