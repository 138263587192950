import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  TableFooter,
  CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

export const CustomersTable = (props) => {
  const { items = [], onPageChange, onRowsPerPageChange } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("FullName"); // Default column to sort by
  const [sortDirection, setSortDirection] = useState({
    SerialNumber: "asc",
    FullName: "asc",
    AgreedToMarketing: "asc",
    RewardsClaimed: "asc",
    LastStampDate: "asc",
    CreateDateFormatted: "asc",
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    onRowsPerPageChange(newRowsPerPage);
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === "asc" ? "desc" : "asc",
    });
  };

  const sortedItems =
    items &&
    [...items].sort((a, b) => {
      if (sortColumn) {
        switch (sortColumn) {
          case "SerialNumber":
            console.log(`Sorting by SerialNumber ${sortDirection[sortColumn]}`);
            if (sortDirection[sortColumn] === "asc") {
              return a[sortColumn] - b[sortColumn];
            } else {
              return b[sortColumn] - a[sortColumn];
            }
          case "FullName":
            console.log(`Sorting by FullName ${sortDirection[sortColumn]}`);
            if (sortDirection[sortColumn] === "asc") {
              return a[sortColumn].localeCompare(b[sortColumn]);
            } else {
              return b[sortColumn].localeCompare(a[sortColumn]);
            }
          case "AgreedToMarketing":
            console.log(
              `Sorting by AgreedToMarketing ${sortDirection[sortColumn]}`
            );
            if (sortDirection[sortColumn] === "asc") {
              return a[sortColumn] === b[sortColumn]
                ? 0
                : a[sortColumn]
                ? -1
                : 1;
            } else {
              return a[sortColumn] === b[sortColumn]
                ? 0
                : a[sortColumn]
                ? 1
                : -1;
            }
          case "RewardsClaimed":
            console.log(
              `Sorting by RewardsClaimed ${sortDirection[sortColumn]}`
            );
            if (sortDirection[sortColumn] === "asc") {
              return a[sortColumn] - b[sortColumn];
            } else {
              return b[sortColumn] - a[sortColumn];
            }
          case "LastStampDate":
            console.log(
              `Sorting by LastStampDate ${sortDirection[sortColumn]}`
            );
            const dateC = new Date(parseDate(a[sortColumn]));
            const dateD = new Date(parseDate(b[sortColumn]));
            if (sortDirection[sortColumn] === "asc") {
              return dateC - dateD;
            } else {
              return dateD - dateC;
            }
          case "CreateDateFormatted":
            console.log(
              `Sorting by CreateDateFormatted ${sortDirection[sortColumn]}`
            );
            const dateA = new Date(parseDate(a[sortColumn]));
            const dateB = new Date(parseDate(b[sortColumn]));
            if (sortDirection[sortColumn] === "asc") {
              return dateA - dateB;
            } else {
              return dateB - dateA;
            }
          default:
            return 0;
        }
      } else {
        return 0;
      }
    });

  function parseDate(dateString) {
    console.log("Original date string:", dateString);
    const [day, month, year] = dateString.split("/");
    const parsedDate = new Date(year, month - 1, day); // Months are 0-based
    console.log("Parsed date:", parsedDate);
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date:", parsedDate);
      return null; // or throw an error if preferred
    }
    return parsedDate;
  }

  if (!sortedItems || sortedItems.length === 0) {
    return (
      <Card style={{ marginLeft: "10px" }}>
        <Box style={{ padding: "16px" }}>
          <Typography variant="body1">No customers found</Typography>
        </Box>
      </Card>
    );
  }

  if (!sortedItems || sortedItems.length === 0) {
    return (
      <Card style={{ marginLeft: "10px" }}>
        <Box style={{ padding: "16px" }}>
          <Typography variant="body1">No customers found</Typography>
        </Box>
      </Card>
    );
  }

  return (
    <Card>
      <Box style={{ minWidth: "800px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort("SerialNumber")}
                style={{ textTransform: "none" }}
              >
                ID
                <Box component="span" sx={{ display: "inline-block", ml: 1 }}>
                  {sortDirection["SerialNumber"] === "asc" ? (
                    <ArrowUpward style={{ fontSize: "15px" }} />
                  ) : (
                    <ArrowDownward style={{ fontSize: "15px" }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                onClick={() => handleSort("FullName")}
                style={{ textTransform: "none" }}
              >
                Name
                <Box component="span" sx={{ display: "inline-block", ml: 1 }}>
                  {sortDirection["FullName"] === "asc" ? (
                    <ArrowUpward style={{ fontSize: "15px" }} />
                  ) : (
                    <ArrowDownward style={{ fontSize: "15px" }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                onClick={() => handleSort("AgreedToMarketing")}
                style={{ textTransform: "none" }}
              >
                Agreed To Marketing
                <Box component="span" sx={{ display: "inline-block", ml: 1 }}>
                  {sortDirection["AgreedToMarketing"] === "asc" ? (
                    <ArrowUpward style={{ fontSize: "15px" }} />
                  ) : (
                    <ArrowDownward style={{ fontSize: "15px" }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                onClick={() => handleSort("RewardsClaimed")}
                style={{ textTransform: "none" }}
              >
                Rewards Claimed
                <Box component="span" sx={{ display: "inline-block", ml: 1 }}>
                  {sortDirection["RewardsClaimed"] === "asc" ? (
                    <ArrowUpward style={{ fontSize: "15px" }} />
                  ) : (
                    <ArrowDownward style={{ fontSize: "15px" }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                onClick={() => handleSort("LastStampDate")}
                style={{ textTransform: "none" }}
              >
                Last Stamped Date
                <Box component="span" sx={{ display: "inline-block", ml: 1 }}>
                  {sortDirection["LastStampDate"] === "asc" ? (
                    <ArrowUpward style={{ fontSize: "15px" }} />
                  ) : (
                    <ArrowDownward style={{ fontSize: "15px" }} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                onClick={() => handleSort("CreateDateFormatted")}
                style={{ textTransform: "none" }}
              >
                Registration Date
                <Box component="span" sx={{ display: "inline-block", ml: 1 }}>
                  {sortDirection["CreateDateFormatted"] === "asc" ? (
                    <ArrowUpward style={{ fontSize: "15px" }} />
                  ) : (
                    <ArrowDownward style={{ fontSize: "15px" }} />
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((customer, index) => (
                <TableRow hover key={`${customer.FullName}-${index}`}>
                  <TableCell style={{ width: "150px" }}>
                    {customer.SerialNumber}
                  </TableCell>
                  <TableCell style={{ width: "350px" }}>
                    {customer.FullName}
                  </TableCell>
                  <TableCell style={{ minWidth: "100px" }}>
                    {customer.AgreedToMarketing.toString()}
                  </TableCell>
                  <TableCell style={{ minWidth: "100px" }}>
                    {customer.RewardsClaimed.toString()}
                  </TableCell>
                  <TableCell style={{ minWidth: "100px" }}>
                    {customer.LastStampDate.toString()}
                  </TableCell>
                  <TableCell style={{ minWidth: "100px" }}>
                    {customer.CreateDateFormatted}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={4}
                count={sortedItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Card>
  );
};

CustomersTable.propTypes = {
  items: PropTypes.array,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
