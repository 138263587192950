import { common } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { error, indigo, info, neutral, success, warning } from './colors';


export function createPalette() {
  return {
    rewardspace: {
      main: '#673AB7',       // purple
      accent: '#3498DB',     // light blue
      background: '#F4F4F4',
      drawer: '#0260FE',
      dark: '#2C3E50',
      action: '#FFC107',    // yellow
      lighterdark: '#465A73',
      highlightgreen: '#39FF14',
      highlightyellow: '#FFD700',
      selectediconcolor: '#9A67E5',
    },
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12)
    },
    background: {
      default: common.white,
      paper: common.white
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    primary: indigo,
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38)
    },
    warning
  };
}
