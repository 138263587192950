import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

class ApiServiceClass {
  static baseUrl = process.env.REACT_APP_API_BASE_URL;

  static getKey() {
    const rawLoginData = localStorage.getItem("loginData");
    const loginData = JSON.parse(rawLoginData);
    if (loginData.ApiKey !== "") {
      console.log("fetched api key: " + loginData.ApiKey);
      return loginData.ApiKey;
    } else {
      console.log("unable to fetch api key");
      localStorage.removeItem("companyDetails");
      localStorage.removeItem("loginData");
    }
  }

  static async request(endpoint, requestBody, queryParams = {}) {
    if (!this.baseUrl) {
      console.error("Base URL is not set. Check environment variable REACT_APP_API_BASE_URL.");
      return;
    }

    const url = new URL(`${this.baseUrl}${endpoint}`);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
    const apiUrl = url.toString();
    console.log(`Request URL: ${apiUrl}`); // Log the constructed URL for debugging

    const apiKey = this.getKey();
    if (!apiKey) {
      console.error("API key is not available.");
      return;
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-functions-key": apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseBody = await response.text();
        // return JSON.parse(responseBody);
        try {
          return JSON.parse(responseBody);
        } catch (jsonError) {
          return responseBody;
        }
      }
    } catch (error) {
      console.error("API Response Error:", error);
      throw error;
    }
  }

  static async CancelSubscription(requestBody) {
    return await this.request("/CancelSubscriptionHook", requestBody);
  }

  static async ContactUs(requestBody) {
    const apiUrl = `${this.baseUrl}/ContactUs`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const text = await response.text();
      return { status: response.status, text };
    } catch (error) {
      toast.error("Error sending message");
      console.error("Error sending message: ", error);
      throw error;
    }
  }

  static async CreateCheckoutSession(requestBody) {
    return await this.request("/CreateCheckoutSession", requestBody);
  }

  // static async CreateStaffMember(requestBody) {
  //   const apiUrl = `${this.baseUrl}/CreateStaffMember`;
  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestBody),
  //     });
  //     const text = await response.text();
  //     return { status: response.status, text };
  //   } catch (error) {
  //     toast.error("Error sending message");
  //     console.error("Error sending message: ", error);
  //     throw error;
  //   }
  // }

  // static async CreateStaffMember(requestBody) {
  //   console.log(requestBody);
  //   const endpoint = "/CreateStaffMember";
  //   // console.log("baseUrl: ", this.baseUrl)
  //   // const apiKey = this.getKey();
  //   // console.log("key: ", apiKey)
  //   console.log("endpoint: ", endpoint)
  //   // const url = new URL(`${this.baseUrl}${endpoint}`);
  //   // const apiUrl = url.toString();
  //   const apiUrl =
  //     "https://reward-space.azurewebsites.net/api/CreateStaffMember?code=-wVualyfWxunEwXg_uCbRyqdoZETBxqQzYGrq3BgE0fMAzFue08_9w==&clientId=default";
  //   console.log("url: ", apiUrl)
  //   try {
  //     // console.log(requestBody);
  //     const response = await fetch(apiUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         // "x-functions-key": apiKey,
  //       },
  //       body: JSON.stringify(requestBody),
  //     });
  //     console.log("response:", response);

  //     // if (!response.ok) {
  //     //   throw new Error("Failed");
  //     // }
  //     // try {
  //     //   return JSON.parse(response);
  //     // } catch (jsonError) {
  //       return response;
  //     // }
  //   } catch (error) {
  //     if (error.message.includes("409")) {
  //       toast.error("apiEmail is already in use");
  //     } else {
  //       toast.error("apiError");
  //       console.error("Error:", error);
  //     }
  //     throw error;
  //   }
  // }

  // static async CreateStaffMember(requestBody) {
  //   const apiUrl =
  //     "https://reward-space.azurewebsites.net/api/CreateStaffMember?code=-wVualyfWxunEwXg_uCbRyqdoZETBxqQzYGrq3BgE0fMAzFue08_9w==&clientId=default";

  //     const response = await fetch(apiUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestBody),
  //     });
  //     return response;
  // }

  static async CreateStaffMember(requestBody) {
    const apiUrl = `${this.baseUrl}/CreateStaffMember`;
    console.log("apiUrl: ", apiUrl)
    const apiKey = this.getKey()
    console.log("apiKey: ", apiKey)

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-functions-key": apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    return response;
  }

  static async CreateStampReward(requestBody) {
  return await this.request("/CreateStampReward", requestBody);
}

  static async DeleteAccount(requestBody) {
  const apiUrl = `${this.baseUrl}/DeleteRewardSpaceAccount`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": ApiServiceClass.apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const text = await response.text();
    return { status: response.status, text };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

  static async DeleteStaffMember(requestBody) {
  return await this.request("/DeleteStaffMember", requestBody);
}

  static async EditCompanyDetails(requestBody) {
  return await this.request("/EditCompanyDetails", requestBody);
}

  static async EditStaffMember(requestBody) {
  try {
    return await this.request("/EditStaffMember", requestBody);
  } catch (error) {
    if (error.message.includes("Failed") && error.message.includes("409")) {
      toast.error("Email is already in use");
    } else {
      toast.error("apiError");
      console.error("Error:", error);
    }
    throw error;
  }
}

  static async EditPaymentDetails(requestBody) {
  return await this.request("/ChangePaymentDetails", requestBody);
}

  static async EditStampReward(requestBody) {
  return await this.request("/EditStampReward", requestBody);
}

 
  static async ForgotPassword(requestBody) {
  const apiUrl = `${this.baseUrl}/ForgotCompanyUserPassword`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const text = await response.text();
    return { status: response.status, text };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

  static async GetAvailableFonts() {
  return await this.request("/GetAvailableFonts", {});
}

  static async GetCustomers(requestBody) {
  return await this.request("/GetCustomers", requestBody);
}

  static async GetBasicStatistics(requestBody) {
  return await this.request("/GetBasicStatistics", requestBody);
}

  static async GetBasicDashboardInformation(requestBody) {
  return await this.request("/GetBasicDashboardInformation", requestBody);
}

static async GetDashboardInformation(requestBody) {
  return await this.request("/GetDashboardInformation", requestBody);
}

  static async GetDashboardGraph(requestBody) {
  return await this.request("/GetDashboardGraph", requestBody);
}

  static async GetDetailedGraph(requestBody) {
  return await this.request("/GetDetailedGraph", requestBody);
}

  static async GetCompanyDetails(requestBody) {
  return await this.request("/GetCompanyDetails", requestBody);
}

  static async GetCompanyBasicTheme(requestBody) {
  return await this.request("/GetCompanyBasicTheme", requestBody);
}

  static async GetCompanyMainTheme(requestBody) {
  return await this.request("/GetCompanyMainTheme", requestBody);
}

  static async GetRewardDetails(requestBody) {
  return await this.request("/GetRewardDetails", requestBody);
}

  static async GetStaffMembers(requestBody) {
  return await this.request("/GetStaffMembers", requestBody);
}

  static async GetSubscription(requestBody) {
  return await this.request("/GetPaymentInformation", requestBody);
}

  static async ResetCompanyUserPasswordCheckCode(requestBody) {
  const apiUrl = `${this.baseUrl}/ResetCompanyUserPasswordCheckCode`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const text = await response.text();
    return { status: response.status, text };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

  static async ResetPassword(requestBody) {
  const apiUrl = `${this.baseUrl}/ForgotPasswordChangePassword`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const text = await response.text();
    return { status: response.status, text };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

  static async SetupCompanyBasicTheme(requestBody) {
  return await this.request("/SetupCompanyBasicTheme", requestBody);
}

  static async SetupCompanyMainTheme(requestBody) {
  return await this.request("/SetupCompanyMainTheme", requestBody);
}

  static async VerifyCaptcha(requestBody) {
  const apiUrl = `${this.baseUrl}/VerifyCaptcha`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": ApiServiceClass.apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const text = await response.text();
    return { status: response.status, text };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
}

export { ApiServiceClass };
