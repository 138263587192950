// src/components/news-content.js
//ADD NEW CONTENT TO BOTTOM FOR NEW UPDATES
class NewsContent {
  static getNewsItems() {
    return [
      {
        title: "New feature#1",
        date: "10 July 2022",
        category: "News",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Curabitur quis libero eget mauris scelerisque faucibus id sed turpis. Nullam in diam vitae erat efficitur condimentum",
      },
      {
        title: "News | New feature#2 - 10/10/2022",
        date: "10 July 2022",
        category: "News",
        content:
          "Suspendisse potenti. Sed ac quam nec dui eleifend tristique. Curabitur quis libero eget mauris scelerisque faucibus id sed turpis. Curabitur quis libero eget mauris scelerisque faucibus id sed turpis. Nullam in diam vitae erat efficitur condimentum. Nullam in diam vitae erat efficitur condimentum",
      },
      {
        title: "New feature#3",
        date: "10 July 2024",
        category: "News",
        content:
          "Curabitur quis libero eget mauris scelerisque faucibus id sed turpis.Curabitur quis libero eget mauris scelerisque faucibus id sed turpis. Curabitur quis libero eget mauris scelerisque faucibus id sed turpis.  Nullam in diam vitae erat efficitur condimentum. Mauris tincidunt odio vel eros ultricies, ac volutpat sapien venenatis. Curabitur quis libero eget mauris scelerisque faucibus id sed turpis. Nullam in diam vitae erat efficitur condimentum",
      },
      {
        title: "Downtime next weekend",
        date: "10 July 2024",
        category: "Important",
        content:
          "Next weekend, we will be scheduling essential downtime to implement a crucial system update. This maintenance period is necessary to enhance performance, introduce new features, and ensure the security of our platform. During this time, access to certain services may be temporarily unavailable. We understand the inconvenience this may cause and apologize in advance for any disruption. Our team is committed to completing the update as swiftly as possible while minimizing impact. We appreciate your patience and understanding as we work to improve your experience. Please plan accordingly and stay tuned for further updates on the maintenance schedule.",
      },
      {
        title: "Reward Space private beta now live!",
        date: "10 July 2024",
        category: "News",
        content:
          "Welcome to our private beta! During this phase, you might encounter some issues or unexpected behavior as we test and refine our system. Your observations and reports are invaluable in helping us improve. If you experience any problems or have suggestions, please don’t hesitate to reach out to our support team. We’re committed to addressing any bugs and making necessary adjustments based on your feedback. Thank you for your patience and contribution to enhancing our platform. Your involvement is crucial to ensuring a successful launch for all users.",
      },
    ];
  }
}

export default NewsContent;
