import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

// YouTube Embed Component
const YoutubeEmbed = ({ videoId, height, width, onLoad }) => {
  return (
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      onLoad={onLoad}
      style={{ display: 'block', borderRadius: "15px",}}
    />
  );
};

export const VideoContainer = ({ videoId, width}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleVideoLoad = () => {
    setIsLoaded(true);
  };

  return (
    <Box sx={{
      justifyContent: 'center',
      display: 'flex',
      width: width,
    }}>
      {!isLoaded && <CircularProgress />}
      <Box sx={{
        display: isLoaded ? 'block' : 'none',
        width: '100%',
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 aspect ratio */
      }}>
        <Box sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }}>
          <YoutubeEmbed
            videoId={videoId}
            width='100%'
            height='100%'
            onLoad={handleVideoLoad}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoContainer;
