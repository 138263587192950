import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
} from "@mui/material";
import MyColor from "../components/my-color.js";
import { MyTextField, MyNumberField } from "../components/my-textfield.js";
import { getFontTechnical } from "../components/font-select.js"
import RenderCompanyLogo from "../components/logo.js";
import MyTooltip from "../components/my-tooltip";
import IconDropdown from "../components/icon-dropdown.js";
import { fontStyles } from '../theme/styles/font.js';
// import { ReactComponent as BoxSVG } from '../tools/icons/box_dialog.svg';


export const DesignAppHome = ({
  topBannerBackgroundColour,
  topBannerFontColour,
  topBannerFontStyle,

  buttonBackgroundColour,
  buttonFontColour,
  buttonFont,
  buttonFontStyle,

  availableFonts,
  companyLogo,
  companyLogoFileType,
  mainBackgroundFontStyle,
  mainBackgroundFontColour,

  stamp,
  stampName,
  stampColor,
  stampBackgroundColor,
  backgroundColor,
  rewardName,
  rewardSubtitle,
  rewardTextColor,
  rewardCardTextColor,
  noPurchases,
}) => {

  const circleDiameter = 42;
  const itemHW = 28;
  const indent = (circleDiameter - itemHW) / 2
  const iconsArray = Array.from({ length: noPurchases }, (_, index) => index);

  const BoxSVG = ({ width, height, fill, style }) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 180.000000 120.000000"
      style={style}
      preserveAspectRatio="xMidYMid meet">
      <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
      </metadata>
      <g
        transform="translate(-35.000000,165.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none">
        <path d="M1260 1605 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7 -30 -15z m46 -16 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z" />
        <path d="M1124 1455 c-8 -21 2 -45 19 -45 8 0 20 7 27 15 10 12 10 18 0 30 -7 8 -19 15 -27 15 -7 0 -16 -7 -19 -15z" />
        <path d="M1340 1443 c0 -3 7 -15 17 -25 15 -17 15 -19 0 -36 -19 -20 -22 -42 -8 -42 6 0 17 10 25 22 l16 22 23 -22 c13 -12 27 -19 30 -15 4 3 -3 17 -15 30 l-22 23 22 16 c12 8 22 19 22 25 0 14 -22 11 -42 -8 -17 -15 -19 -15 -36 0 -19 18 -32 22 -32 10z" />
        <path d="M1000 1347 c0 -7 6 -19 13 -26 10 -11 10 -14 0 -18 -14 -5 -18 -33 -5 -33 4 0 13 5 20 12 8 8 15 8 27 -2 20 -16 33 -4 19 18 -8 12 -8 22 0 34 15 25 -3 37 -20 13 -14 -19 -16 -19 -25 -2 -12 20 -29 23 -29 4z" />
        <path d="M1557 1334 c-10 -11 -9 -42 3 -49 20 -12 51 6 48 28 -3 22 -36 35 -51 21z" />
        <path d="M960 1174 c-85 -24 -163 -43 -173 -44 -10 0 -20 -7 -24 -16 -4 -12 9 -30 45 -61 l52 -45 -66 -79 c-36 -43 -63 -83 -60 -87 3 -5 32 -17 65 -26 l60 -17 3 -121 3 -121 192 -59 193 -58 193 58 192 59 5 123 5 124 46 12 c52 13 69 21 69 34 0 4 -27 41 -60 82 l-61 73 51 46 c53 49 61 65 33 73 -52 15 -352 96 -358 96 -3 0 -31 -23 -61 -52 l-54 -51 -28 25 c-69 63 -87 78 -97 77 -5 -1 -80 -21 -165 -45z m219 -25 c24 -21 42 -41 40 -42 -4 -4 -325 -87 -333 -87 -3 0 -26 20 -53 45 -39 36 -44 45 -28 45 11 1 83 18 160 40 77 21 147 39 155 39 8 0 35 -18 59 -40z m359 -1 c89 -24 162 -46 162 -49 0 -4 -20 -23 -45 -43 l-44 -37 -163 42 c-90 24 -165 44 -168 47 -5 3 79 81 90 82 3 1 78 -18 168 -42z m-287 -158 c5 0 9 22 9 48 l0 48 153 -39 c83 -22 159 -42 167 -44 14 -5 -95 -36 -270 -79 l-65 -16 -151 41 c-84 22 -160 41 -170 41 -37 0 -1 12 152 53 l159 42 3 -47 c2 -27 7 -48 13 -48z m-196 -56 l174 -47 -52 -61 c-28 -34 -53 -63 -54 -65 -3 -4 -89 18 -285 70 l-77 21 52 63 c29 35 56 64 60 64 4 1 86 -20 182 -45z m623 -16 c43 -53 48 -64 34 -70 -28 -12 -333 -91 -335 -87 -1 2 -26 31 -54 65 l-52 61 167 46 c92 25 172 46 178 46 6 1 33 -27 62 -61z m-369 -112 c29 -36 57 -66 64 -69 6 -2 59 9 117 25 58 15 111 28 117 28 9 0 12 -28 10 -107 l-2 -107 -175 -52 c-96 -30 -176 -54 -177 -54 -2 0 -3 61 -3 135 0 83 -4 135 -10 135 -6 0 -10 -52 -10 -135 0 -74 -1 -135 -2 -135 -2 0 -81 24 -177 54 l-175 53 0 107 c-1 58 1 106 4 106 3 0 56 -14 119 -31 130 -34 119 -38 192 57 23 30 45 54 49 54 5 0 31 -29 59 -64z" />
        <path d="M1522 669 c-35 -14 -18 -29 21 -18 17 5 33 9 34 9 2 0 3 5 3 10 0 12 -27 12 -58 -1z" />
        <path d="M1508 613 c-27 -8 -48 -18 -48 -24 0 -12 5 -11 63 4 27 7 48 17 48 22 1 14 -12 14 -63 -2z" />
      </g>
    </svg>

  );

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      <style>{fontStyles}</style>

      {/* Top Banner */}
      <Box
        style={{
          height: "80px",
          backgroundColor: topBannerBackgroundColour,
          width: "100%",
          position: "absolute",
          border: 'none',
          top: 0,
          zIndex: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography className={getFontTechnical(availableFonts, topBannerFontStyle)}
          style={{ 
            color: topBannerFontColour, 
            marginTop: '35px',
            }}>
          Home
        </Typography>
      </Box>

      {/* Reward Panel */}
      <Box sx={{
        backgroundColor: backgroundColor,
        borderRadius: '15px',
        width: '75%',
        position: 'absolute',
        top: '90px',
        display: 'flex',
        height: '290px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: `2px solid ${stampBackgroundColor}`,
        borderBottomWidth: '4px',
        // boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.4)',
        zIndex: 2,
      }}>
        {/* Logo */}
        <RenderCompanyLogo
          companyLogo={companyLogo}
          companyLogoFileType={companyLogoFileType}
          maxH={"60px"}
          maxW={"60px"}
          style={{
            position: "absolute",
            top: "5px",
            zIndex: 10,
          }}
        />
        <div style={{ position: 'absolute', marginTop: "65px", top: '0px' }}>
          <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
            color: rewardTextColor,
            fontSize: '16px',
            fontWeight: 'bold'
          }}>
            {rewardName}
          </Typography>
        </div>
        <div style={{ position: 'absolute', marginTop: "90px", top: '0px' }}>
          <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
            color: rewardTextColor,
            fontSize: '12px',
            fontWeight: 'bold',
            opacity: 0.6
          }}>
            {rewardSubtitle}
          </Typography>
        </div>
        <table style={{ marginTop: '130px', marginBottom: '10px' }}>
          <tbody>
            {iconsArray.reduce((rows, index) => {
              if (index % 4 === 0) rows.push([]);
              rows[rows.length - 1].push(
                <td key={`${rows.length - 1}-${index}`} style={{
                  marginLeft: "4px",
                  marginRight: "4px",
                  position: "relative",
                  display: "inline-block"
                }}>
                  <svg width={circleDiameter} height={circleDiameter} style={{ zIndex: 2 }}>
                    <circle
                      cx={circleDiameter / 2}
                      cy={circleDiameter / 2}
                      r={circleDiameter / 2 - 1}
                      fill="rgba(255, 255, 255, 0.5)"
                      // fill="#808080"
                      stroke={stampBackgroundColor}
                      strokeWidth="1"
                      strokeDasharray="3,3"
                    // opacity="0.3"
                    />
                  </svg>
                  <svg
                    width={itemHW}
                    height={itemHW}
                    fill={stampColor}
                    opacity="0.3"
                    style={{
                      position: "absolute",
                      top: `${indent}px`,
                      left: `${indent}px`,
                      zIndex: 4
                    }}>
                    {stamp}
                  </svg>
                </td>
              );
              return rows;
            }, [])
              .map((row, rowIndex) => (
                <tr key={rowIndex}>{row}</tr>
              ))}
          </tbody>
        </table>
      </Box>

      <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
        color: mainBackgroundFontColour,
        fontSize: '12px',
        position: 'absolute',
        left: '35px',
        top: '396px',
        fontWeight: 'bold',
        zIndex: 6,
      }}>
        Recent Transactions
      </Typography>
      <Button
        style={{
          height: "30px",
          position: "absolute",
          top: '390px',
          right: '40px',
          backgroundColor: buttonBackgroundColour,
          border: `1px solid ${buttonBackgroundColour}`,
          width: "50px",
          zIndex: 3,
          borderRadius: '15px',
          padding: 0,
        }}>
        <Typography className={getFontTechnical(availableFonts, buttonFontStyle)}
          style={{
            color: buttonFontColour,
            fontSize: '12px',
          }}>
          View all
        </Typography>
      </Button>
      <BoxSVG
        width="80px"
        height="80px"
        fill={mainBackgroundFontColour}
        style={{
          position: 'absolute',
          top: '415px',
          zIndex: 1000,
          opacity: 0.6,
        }} />
      <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
        color: mainBackgroundFontColour,
        fontSize: '12px',
        position: 'absolute',
        top: '490px',
        fontWeight: 'bold',
        zIndex: 6,
      }}>
        No Activity Yet
      </Typography>
      <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
        color: mainBackgroundFontColour,
        fontSize: '10px',
        position: 'absolute',
        top: '507px',
        zIndex: 6,
      }}>
        Your rewards history is currently empty
      </Typography>
    </Box>

  );
};


export const DesignFormHome = ({
  stamps,
  rewardName,
  setRewardName,
  rewardSubtitle,
  setRewardSubtitle,
  stampName,
  setStampName,
  noPurchases,
  setNoPurchases,
  rewardTextColor,
  setRewardTextColor,

  backgroundColor,
  setBackgroundColor,
  stampColor,
  setStampColor,
  stampBackgroundColor,
  setStampBackgroundColor,

  availableFonts,
  maxWidth = 1000,
}) => {
  const [showStampBackgroundColor, setShowStampBackgroundColor] = useState(false);
  const [showBackgroundColor, setShowBackgroundColor] = useState(false);
  const [showRewardTextColor, setShowRewardTextColor] = useState(false);
  const [showStampColor, setShowStampColor] = useState(false);
  const itemWidth = '250px'

  return (
    <div>
      <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Reward Details</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', marginTop: 'auto' }}>
            <MyTextField
              name="Reward Title"
              tooltip="The title of your reward"
              placeholder="Reward Title"
              text={rewardName}
              setText={setRewardName}
              id="rewardName"
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', marginTop: 'auto' }}>
            <MyTextField
              name="Reward Subtitle"
              tooltip="Reward subtitle or description. Describe your reward!"
              placeholder="Free Coffee"
              text={rewardSubtitle}
              setText={setRewardSubtitle}
              id="rewardSubtitle"
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', marginTop: 'auto' }}>
            <MyNumberField
              name="Purchases Required"
              tooltip="The number of stamps required to claim the reward"
              placeholder={8}
              value={noPurchases}
              setValue={setNoPurchases}
              id="noPurchases"
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Reward Text Colour"
              tooltip="The text colour of the title and subtitle"
              color={rewardTextColor}
              setColor={setRewardTextColor}
              showColorPicker={showRewardTextColor}
              setShowColorPicker={setShowRewardTextColor}
            />
          </Box>
        </div>
      </Box>

      <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Stamp Colours</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>

          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', textAlign: 'left', }}>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', }}>
              <div>Reward Stamp</div>
              <MyTooltip title="The image used for the reward. This image is shown when stamped by the employer" />
            </div>
            <IconDropdown
              stamps={stamps}
              stampName={stampName}
              setStampName={setStampName}
            />
          </Box>

          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Stamp Colour"
              tooltip="The colour of the stamp icon (svg)"
              color={stampColor}
              setColor={setStampColor}
              showColorPicker={showStampColor}
              setShowColorPicker={setShowStampColor}
            />
          </Box>

          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Stamp Border Colour"
              tooltip="The colour of the circular border around the stamp"
              color={stampBackgroundColor}
              setColor={setStampBackgroundColor}
              showColorPicker={showStampBackgroundColor}
              setShowColorPicker={setShowStampBackgroundColor}
            />
          </Box>

          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Panel Background Colour"
              tooltip="The background colour of the main reward panel"
              color={backgroundColor}
              setColor={setBackgroundColor}
              showColorPicker={showBackgroundColor}
              setShowColorPicker={setShowBackgroundColor}
            />
          </Box>
        </div>
      </Box>

      {/* <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Inverse Button</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>

          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Button Outline Colour"
              tooltip="The outline colour of the inverse button only shown on the home page"
              color={outlineButtonBorderColor}
              setColor={setOutlineButtonBorderColor}
              showColorPicker={showInverseButtonOutlineColour}
              setShowColorPicker={setShowInverseButtonOutlineColour}
            />
          </Box>

          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Button Text Colour"
              tooltip="The colour of the text in the inverse button only shown on the home page"
              color={outlineButtonTextColor}
              setColor={setOutlineButtonTextColor}
              showColorPicker={showInverseButtonTextColour}
              setShowColorPicker={setShowInverseButtonTextColour}
            />
          </Box>
        </div>
      </Box> */}
    </div>
  )
};
