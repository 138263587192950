import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Button,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";

const PricingList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

const highlightWords = (text, words) => {
  const parts = text.split(new RegExp(`(${words.join("|")})`, "gi"));
  return parts.map((part, index) => {
    if (words.includes(part.toLowerCase())) {
      return (
        <span
          key={index}
          style={{
            fontWeight: "bold",
            fontFamily: "Poppins-Regular",
            color: "#171663",
          }}
        >
          {part}
        </span>
      );
    } else {
      return (
        <span
          key={index}
          style={{ fontFamily: "Poppins-Regular", color: "#171663" }}
        >
          {part}
        </span>
      );
    }
  });
};

const wordsToBold = ["priority", "unlimited"];

const PricingCard = ({ tier, width, height, fade = 1 }) => {
  console.log('gell')

  const fadeWhite = `rgba(${fade * 255}, ${fade * 255}, ${fade * 255}, 1)`;
  const bannerFade = `rgba(${fade * 2}, ${fade * 96}, ${fade * 254}, 1)`;
  const navigate = useNavigate();

  const handleSubmit = (tierId) => {
    console.log(tierId);
    localStorage.setItem("price-plan-selected", tierId);
    navigate("/checkout");
  };

  return (
    <Card
      sx={{
        height: height,
        width: width,
        backgroundColor: fadeWhite,
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        borderColor: "#000000",
        margin: "20px"
      }}
    >
      <CardHeader
        title={tier.title}
        titleTypographyProps={{
          align: "center",
          color: fadeWhite,
          fontFamily: "Poppins-Semibold",
        }}
        sx={{
          height: "40px",
          padding: "10px",
          background: bannerFade,
        }}
        style={{ borderRadius: "0px" }}
      />
      <CardContent>
        <Box
          sx={{
            justifyContent: "center",
          }}
        >
          <Typography
            color="text.primary"
            style={{
              display: "flex",
              minWidth: "250px",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "32px",
              fontFamily: "Poppins-Semibold",
              color: "#171663",
            }}
          >
            {tier.monthlyPrice}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              mb: 4,
            }}>
              <Button
                type="button"
                onClick={() => handleSubmit(tier.id)}
                fontFamily="Poppins-Regular"
                sx={{
                  width: "50%",
                }}>
                Select Plan
              </Button>
          </Box>
          <PricingList>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                marginTop: "8px",
              }}>
              {tier.description.map((line, index) => (
                <Typography key={index}>
                  <DoneIcon
                    style={{
                      color: "green",
                      height: "25px",
                      width: "25px",
                      verticalAlign: "middle",
                    }}
                  />{" "}
                  {highlightWords(line, wordsToBold)}
                </Typography>
              ))}
            </Box>
          </PricingList>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
