const Styles = {
  panel: {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full viewport height
  },
  contentWrapper: {
    flex: "auto",
    overflowY: "auto",
    paddingBottom: "50px",
    // backgroundColor: "#b01025",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "1% 5% 1% 5%", //top, right , bottom, left
    //backgroundColor: "#000000",
    marginTop: "10px",
    color: "#000000",
    fontSize: "15px",
  },
  sectionTitle: {
    color: "#333333", // Dark gray text for better readability
    display: "flex",
    justifyContent: "flex-start", // Align content to the left
    alignItems: "center", // Vertically center content
    fontWeight: "bold",
    backgroundColor: "#e0e0e0", // Light gray background for contrast
    padding: "0 0 0 5.2%", // Top, right, bottom, left
    fontSize: "16px",
    borderBottom: "2px solid #cccccc", // Light gray bottom border
    topBorder: "2px solid #cccccc", // Light gray bottom border
  },
  colorBox: {
    width: "35%",
    height: "90px", // Ensure parent height is set
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "3%", // Margin outside the box
    marginTop: "1%", // Margin outside the box
    borderRadius: "12px",
    position: "relative",
    padding: "1%",
    color: "white",
    backgroundColor: "#476648",
    flexShrink: 0, // Prevents shrinking in flexbox layout
    //border: "2px solid black",
    boxSizing: "border-box", // Ensures padding is included in the width and height
  },
  pickerWrapper: {
    position: "absolute",
    right: "1230px", // Adjust this value based on the ChromePicker's width
    top: "80px",
    zIndex: 2, // Ensure the picker is above other elements
  },
  colorText: {
    color: "#000000",
  },
  colorCode: {
    fontSize: "14px",
    color: "#555",
  },
  penIcon: {
    position: "absolute",
    top: "5px",
    right: "10px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2%",
    borderTop: "1px solid #ccc",
    backgroundColor: "#f9f9f9",
    position: "sticky",
    bottom: 0,
  },
  cancelBtn: {
    backgroundColor: "#dc3545",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "20%",
  },
  applyBtn: {
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "20%",
  },
};

export default Styles;
