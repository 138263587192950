import Typography from '@mui/material/Typography';
import * as React from 'react';


export function Copyright(props) {
  return (
    <Typography variant="copyright" sx={{ textAlign: 'center' }} {...props}>
      {'Copyright © '}
      RewardSpace Ltd {new Date().getFullYear()}
    </Typography>
  );
}