import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Tab,
  CssBaseline,
  Tabs,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logoSvg from "../tools/icons/logo.svg";
import "../theme/index.css";
import { ApiServiceClass } from "../components/api-service.js";
import CircularProgress from "@mui/material/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ResetPassword() {
  let navigate = useNavigate();
  const [tabNumber, setTabNumber] = useState(0);
  const [errors, setErrors] = useState({});
  const [isEmailFound, setIsEmailFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const [textFields, setTextFields] = useState({
    email: "",
    code: "",
    password: "",
    password2: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handlePinCodeCheck = () => {
    console.log("handle pin reset");
    CheckPin();
  };

  const handleTabChange = async (event, newTabNumber) => {
    if (
      (newTabNumber === 1 && textFields.email === "") ||
      (tabNumber === 0 && textFields.email === "")
    ) {
      toast.error("Please enter an email address", { autoClose: 2000 });
      return;
    }

    if (newTabNumber === 2 && textFields.code === "") {
      toast.error("Please enter a pin code", { autoClose: 2000 });
      return;
    }

    if (newTabNumber === 2 && !isPinVerified) {
      toast.error("Please verify the pin code first", { autoClose: 2000 });
      return;
    }

    var isFormValid = Object.values(errors).every((error) => error === "");
    if (isFormValid) {
      if (tabNumber === 0 && newTabNumber === 1) {
        setIsLoading(true);
        console.log("Loading variable: " + isLoading);
        const data = await ForgotPassword();
        if (data.status !== 200) {
          toast.error("Account not found", { autoClose: 2000 });
        } else {
          setTabNumber(newTabNumber);
        }
        setIsLoading(false);
      } else {
        setTabNumber(newTabNumber);
      }
    } else {
      console.log("Form contains validation errors", errors);
      toast.error("Please check all fields", { autoClose: 1000 });
    }
  };

  const handleTextFieldChange =
    (fieldName, validationRegex, errorMessage) => (event) => {
      setTextFields((prevTextFields) => ({
        ...prevTextFields,
        [fieldName]: event.target.value,
      }));

      if (fieldName !== "password2")
        validateField(
          fieldName,
          event.target.value,
          validationRegex,
          errorMessage
        );
      console.log("field name: " + fieldName + " value: " + event.target.value);
    };

  const validateField = (fieldName, value, validationRegex, errorMessage) => {
    if (validationRegex && validationRegex.test) {
      if (!validationRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: errorMessage || "Invalid input",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: "",
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    }
  };

  const handleReset = async () => {
    var captcha = await verifyCaptcha();
    if (captcha) {
      console.log("sending reset");
      ResetPassword();
    }
  };

  useEffect(() => {
    validatePasswords();
  }, [textFields.password, textFields.password2]);

  const validatePasswords = () => {
    const passwordValue = textFields.password;
    const confirmPasswordValue = textFields.password2;
    if (passwordValue === confirmPasswordValue && passwordValue !== "") {
      console.log("passwords match");
      setIsChecked(true);
    }
    if (passwordValue !== confirmPasswordValue && textFields.password2 !== "") {
      setIsChecked(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        password2: "Passwords do not match",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password2: "",
      }));
    }
  };

  const verifyCaptcha = async (event) => {
    try {
      const request = {
        captcha: captchaValue,
      };

      console.log("calling captcha: ", request);
      const response = await ApiServiceClass.VerifyCaptcha(request);

      if (response.status === 200) {
        console.log("Success: Captcha verified successfully");
        return true; // Return true indicating successful captcha verification
      } else {
        console.log("Error: Captcha verification failed");
        toast.error("Captcha verification failed");
        return false; // Return false indicating failed captcha verification
      }
    } catch (error) {
      console.error("Error during captcha verification:", error);
      // Handle the error here, e.g., show an error message to the user
      toast.error("Error during captcha verification");
      return false; // Return false indicating failed captcha verification due to error
    }
  };

  const ForgotPassword = async () => {
    try {
      var requestBody = {
        EmailAddress: textFields.email,
      };
      return await ApiServiceClass.ForgotPassword(requestBody);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CheckPin = async () => {
    try {
      var requestBody = {
        EmailAddress: textFields.email,
        PinCode: textFields.code,
      };
      const data = await ApiServiceClass.ResetCompanyUserPasswordCheckCode(
        requestBody
      );

      if (data.status !== 200) {
        toast.error("Pin Incorrect", { autoClose: 2000 });
      } else {
        toast.success("Pin verified.", { autoClose: 2000 });
        setIsPinVerified(true);
        setTabNumber(2);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ResetPassword = async () => {
    try {
      var requestBody = {
        EmailAddress: textFields.email,
        PinCode: textFields.code,
        NewPassword: textFields.password,
      };

      const data = await ApiServiceClass.ResetPassword(requestBody);
      console.log(data);
      if (data.status !== 200) {
        toast.error("Unexpected error occurred: " + data.status, {
          autoClose: 2000,
        });
      } else {
        toast.success("Password changed", { autoClose: 2000 });
        navigate("/login");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="100%"
      className="background-container-wave"
      sx={{ paddingBottom: "2%" }}
    >
      <CssBaseline />

      <Link to="/home" style={{ textDecoration: "none", color: "inherit" }}>
        <Box
          sx={{
            padding: 3,
            width: "100%",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Center vertically
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            style={{ marginRight: "10px" }} // Adjusted margin between the SVG and text
          >
            <image href={logoSvg} width="45" height="45" alt="Logo" />
          </svg>

          <span
            style={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "25px",
              color: "#0260FE",
              whiteSpace: "nowrap",
            }}
          >
            Reward Space
          </span>
        </Box>
      </Link>

      <Box
        sx={{
          paddingTop: 3,
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: 0,
          display: "flex",
          maxWidth: "sm",
          margin: "auto",
          bgcolor: "#FFFFFF",
          border: "1px solid #2C3E50",
          borderRadius: "15px",
          flexDirection: "column",
          alignItems: "center",
          "@media (max-width: 768px)": {
            maxWidth: "90%", // Adjust the percentage for smaller screens
          },
        }}
      >
        <span
          style={{
            fontSize: "20px",
            whiteSpace: "nowrap",
            paddingBottom: 10,
            // backgroundColor:'#eeeeee'
          }}
        >
          Reset Your Password
        </span>

        <Box sx={{ width: "100%", backgrounColor: "#444444" }}>
          <Box sx={{ borderColor: "divider" }}>
            <div className="tabsContainer">
              <Tabs
                value={tabNumber}
                onChange={handleTabChange}
                aria-label="Tabs"
              >
                <Tab
                  className="tab"
                  name="Tab1"
                  label="Email Address"
                  {...a11yProps(0)}
                  // style={{ display: tabNumber === 0 ? "none" : "" }}
                />
                <Tab
                  className="tab"
                  name="Tab2"
                  label="2FA Code"
                  {...a11yProps(1)}
                  // style={{ display: tabNumber === 0 ? "none" : "" }}
                />
                <Tab
                  className="tab"
                  name="Tab2"
                  label="New password"
                  {...a11yProps(2)}
                  // style={{ display: tabNumber === 0 ? "none" : "" }}
                />
              </Tabs>
            </div>
          </Box>
          <CustomTabPanel
            value={tabNumber}
            index={0}
            sx={{ backgroundColor: "#444444" }}
          >
            <Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={textFields.email}
                onChange={handleTextFieldChange(
                  "email",
                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  "Invalid email format"
                )}
                error={!!errors.email}
                helperText={errors.email}
              />
              <>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={() => handleTabChange(null, 1)}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 2,
                      width: "50%",
                    }}
                  >
                    Next
                  </Button>
                )}
              </>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabNumber} index={1}>
            <Box>
              <span
                style={{
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  paddingBottom: 10,
                }}
              >
                We just sent an email to you with a code to reset your password!
              </span>

              <TextField
                margin="normal"
                required
                fullWidth
                name="2FA"
                label="Enter your code"
                type="2FA"
                id="2FA"
                value={textFields.code}
                onChange={handleTextFieldChange(
                  "code",
                  /^\d{4}$/,
                  "The 2FA code must be 4 digits"
                )}
                error={!!errors.code}
                helperText={errors.code}
              />
            </Box>

            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={textFields.code == ""}
                onClick={() => handlePinCodeCheck()}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 2,
                  width: "50%",
                }}
              >
                Next
              </Button>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={tabNumber} index={2}>
            <Box>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleTextFieldChange(
                  "password",
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  "Passwords must be atleast 8 characters with an uppercase"
                )}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Confirm Password"
                type="password"
                onChange={handleTextFieldChange(
                  "password2",
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  "Invalid password"
                )}
                error={!!errors.password2}
                helperText={errors.password2}
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <ReCAPTCHA
                sitekey="6LcGsgwqAAAAAK28rAWl89KR5j7xBHdBOQvkNpJW"
                onChange={handleCaptchaChange}
              />
            </div>
            <Button
              disabled={!isChecked || !captchaValue}
              type="submit"
              onClick={handleReset}
              variant="contained"
              sx={{
                mt: 2,
                mb: 2,
                width: "50%",
              }}
            >
              Reset your password
            </Button>
          </CustomTabPanel>
          <div style={{ paddingBottom: "20px" }}>
            <Link href="/login">Back to login</Link>
          </div>
        </Box>
      </Box>
    </Container>
  );
}
