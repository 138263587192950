import React from "react";

const NewCard = ({
  title = "title",
  subtext = "Not published",
  iconSrc = "/path/to/pwa-logo.png",
  buttonText = "Publish your PWA",
  buttonColour = "#16B07AE6",
  imageWidth = "40px",
  imageHeight = "40px",
  buttonHref = "#",
}) => {
  const styles = {
    card: {
      border: "1px solid #e0e0e0",
      borderRadius: "10px",
      padding: "22px",
      textAlign: "center",
      minWidth: "360px",
      height: "220px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      margin: "10px auto",
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between", // Ensures that the space is distributed between elements
      alignItems: "center", // Centers the items horizontally
    },
    image: {
      width: imageWidth,
      height: imageHeight,
    },
    title: {
      margin: "2px 0 6px",
      fontSize: "16px",
    },
    subtext: {
      margin: "0 0 2px",
      color: "#888",
      fontSize: "15px",
    },
    publishBtn: {
      backgroundColor: buttonColour,
      color: "white",
      border: "none",
      borderRadius: "16px",
      padding: "8px 12px",
      width: "190px",
      fontSize: "13px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none", // Ensure text-decoration is none for <a> elements
      margin: "0 auto", // Center the button horizontally
    },
  };

  return (
    <div style={styles.card}>
      <img src={iconSrc} alt="icon" style={styles.image} />
      <div>
        <div style={styles.title}>{title}</div>
        <div style={styles.subtext}>{subtext}</div>
      </div>
      {buttonHref ? (
        <a href={buttonHref} style={styles.publishBtn}>
          {buttonText}
        </a>
      ) : (
        <button style={styles.publishBtn}>{buttonText}</button>
      )}
    </div>
  );
};

export default NewCard;
