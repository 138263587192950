import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const IconDropdown = ({ stamps, stampName, setStampName }) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (name) => {
        setStampName(name);
        handleClose();
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredStamps = Object.entries(stamps).filter(([key]) =>
        key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ 
            border: 'none',
            minWidth: '40%',
            textAlign: 'left'
        }}>
            <div
                onClick={handleClickOpen}
                style={{
                    cursor: 'pointer',
                    position: "relative", 
                    border: "1px solid black", 
                    borderRadius: "5px",
                    padding: "5px",
                    width: '230px',
                    height: "51px",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center' // Center content vertically
                }}
            >
                <svg width="40px" height="40px">
                    {stamps[stampName]}
                </svg>
            </div>
            <Dialog 
                open={open} 
                onClose={handleClose} 
                maxWidth="md" 
                fullWidth
                height="50%"
                PaperProps={{
                    sx: {
                        width: '75%',
                        height: '60%',
                    },
                }}
            >
                <DialogTitle>Select a Stamp</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Search"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{ marginBottom: '20px' }}
                    />
                    <Box sx={{ 
                        display: 'grid', 
                        gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))', 
                        gap: '10px', 
                        padding: '10px' 
                    }}>
                        {filteredStamps.map(([key, value]) => (
                            <MenuItem
                                key={key}
                                onClick={() => handleSelect(key)}
                                style={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center', 
                                    minWidth: '60px', 
                                    textAlign: 'center' 
                                }}
                            >
                                <svg width="40px" height="40px">
                                    {value}
                                </svg>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        maxWidth: '60px', // Match the minWidth of the grid cell
                                        whiteSpace: 'normal', // Allow wrapping
                                        overflow: 'hidden', // Hide overflow
                                        textAlign: 'center', // Center the text
                                    }}
                                >
                                    {key.replace(/_/g, ' ')} {/* Replace underscores with spaces */}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default IconDropdown;
