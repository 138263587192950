import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Navbar from "./navbar.js";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ApiServiceClass } from "../components/api-service.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DesignSidePanel from "../components/myapp/designsidepanel.js";
import iPhoneImage from "../components/myapp/resources/iphone.png"; // Adjust the path to your actual image file
import cursorImage from "../components/myapp/resources/cursor.png"; // Ensure the path is correct

import {
  Avatar,
  CardContent,
  Stack,
  SvgIcon,
  createTheme,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
export default function MyApp() {
  const [dashboard, setDashboard] = useState([]);
  const [dashboardGraph, setDashboardGraph] = useState([]);
  const [width, setWidth] = useState(100); // Initial width value
  console.log(cursorImage);
  let navigate = useNavigate();

  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);

  const handleWidthChange = (newWidth) => {
    setWidth(newWidth);
    console.log("My app width: ", newWidth);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (companyDetails === null || loginData === null) {
        console.log("redirecting to /Login");
        navigate("/Login");
      }

      //await GetBasicDashboardInformation();
    };

    fetchData();
  }, []);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const attachedButtonStyle = {
    position: "relative", // Use fixed positioning to attach it to the viewport
    top: "20px", // Adjust vertical positioning as needed
    cursor: "pointer",
    padding: "10px 15px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000, // Ensure the button stays on top of other elements
    transition: "right 0.3s", // This ensures the button moves smoothly when the panel changes width
  };

  return (
    <Navbar name="My App">
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          color: "#2596be",
          //backgroundColor: "#000000",
          flexDirection: "row", // Align children horizontally
          overflow: "hidden",
        }}
      >
        <div
          style={{
            //backgroundColor: "#3A92D2",
            width: "100%",
            height: "100%",
            marginTop: "4%",
            marginLeft: width == "4%" ? "36%" : "20%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={iPhoneImage}
            alt="iPhone"
            draggable="false" // Prevents dragging the image
            onDragStart={(e) => e.preventDefault()} // Prevents dragging via JavaScript
            style={{
              maxWidth: "83%",
              maxHeight: "83%",
              //backgroundColor: "#3A92D2",
              cursor: `url(${cursorImage}) 8 8, auto`, // Set the custom cursor with a hotspot
              userSelect: "none", // Prevents selecting the image
            }}
          />
        </div>
        <div
          style={{
            justifyContent: "center", // Center the design panel horizontally within the div
            alignItems: "center", // Center the design panel vertically within the div
          }}
        >
          <DesignSidePanel onWidthChange={handleWidthChange} />
        </div>
      </div>
    </Navbar>
  );
}
