import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import { ApiServiceClass } from "../components/api-service.js";
import { useNavigate } from "react-router-dom";
import logoSvg from "../tools/icons/logo.svg";
import { Copyright } from "../components/copyright.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../theme/index.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    // Check if the current location has state and if it's from the register page
    const isFromRegister = location?.state?.fromRegister;

    if (isFromRegister) 
      {
      toast.success("Welcome! You have successfully registered.");
    }
  }, [location]);

  const verifyCaptcha = async (event) => {
    try {
      const request = {
        captcha: captchaValue,
      };

      const response = await ApiServiceClass.VerifyCaptcha(request);
      setLoading(false);
      if (response.status === 200) {
        return true; // Return true indicating successful captcha verification
      } else {
        toast.error("Captcha verification failed");
        return false; // Return false indicating failed captcha verification
      }
    } catch (error) {
      console.error("Error during captcha verification:", error);
      // Handle the error here, e.g., show an error message to the user
      toast.error("Error during captcha verification");
      return false; // Return false indicating failed captcha verification due to error
    }
  };

  const handleLogin = async () => {
    var captcha = await verifyCaptcha();
    if (captcha) {
      try {
        setLoading(true);
        const payload = {
          EmailAddress: email,
          Password: password,
        };
        const apiUrl = process.env.REACT_APP_API_BASE_URL + "/Login";
        const response = await axios.post(apiUrl, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          setLoading(false);
          localStorage.setItem("loginData", JSON.stringify(response.data));
          const loginData = JSON.stringify(response.data);
          const body = {
            CompanyId: response.data.CompanyId,
            RequesterUserId: response.data.UserId,
          };

          const companyDetails = await ApiServiceClass.GetCompanyDetails(body);
          localStorage.setItem("companyData", JSON.stringify(companyDetails));
          navigate("/Dashboard");
        } else {
          setLoading(false);
          toast.error("Invalid username or password");
        }
      } catch (error) {
        console.error("Error during login:", error.message);
        const errorMessage = error.response
          ? error.response.data
          : "An error occurred";
        toast.error(errorMessage, {
          autoClose: 2000,
        });
        setLoading(false);
        setPassword("");
        recaptchaRef.current.reset();
        setCaptchaValue(null);
      }
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleLogin();
  };

  return (
    <Container
      component="main"
      maxWidth="100%"
      className="background-container-wave"
      sx={{ paddingBottom: "2%" }}
    >
      <CssBaseline />

      <Link to="/home" style={{ textDecoration: "none", color: "inherit" }}>
        <Box
          sx={{
            padding: 3,
            width: "100%",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Center vertically
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            style={{ marginRight: "10px" }} // Adjusted margin between the SVG and text
          >
            <image href={logoSvg} width="45" height="45" alt="Logo" />
          </svg>

          <span
            style={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "25px",
              color: "#0260FE",
              whiteSpace: "nowrap",
            }}
          >
            Reward Space
          </span>
        </Box>
      </Link>

      <Box
        sx={{
          paddingTop: 3,
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: 0,
          display: "flex",
          maxWidth: "sm",
          margin: "auto",
          bgcolor: "#FFFFFF",
          border: "1px solid #2C3E50",
          borderRadius: "15px",
          flexDirection: "column",
          alignItems: "center",
          "@media (max-width: 768px)": {
            maxWidth: "90%", // Adjust the percentage for smaller screens
          },
        }}
      >
        <span
          style={{
            fontFamily: "Poppins-Medium",
            fontSize: "17px",
            whiteSpace: "nowrap",
          }}
        >
          Log in to RewardSpace
        </span>

        <Box
          noValidate
          textAlign="center"
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
            width: "100%",
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            value={email}
            sx={{ alignItems: "flex-start", pb: 1 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            type="password"
            name="password"
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3%",
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcGsgwqAAAAAK28rAWl89KR5j7xBHdBOQvkNpJW"
              onChange={handleCaptchaChange}
            />
          </div>
          <Button
            type="submit"
            fontFamily="Poppins-Medium"
            onClick={handleSubmit}
            disabled={!captchaValue}
            variant="contained"
            sx={{
              mt: 2,
              mb: 4,
              width: "50%",
            }}
          >
            Log In
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "black",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
          <Grid container sx={{ marginBottom: "8%" }}>
            <Grid item xs align="left">
              <Link fontFamily="Poppins-Medium" to="/Reset">
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs align="right">
              <Link fontFamily="Poppins-Medium" to="/Register">
                Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid item xs align="center" marginTop={2}>
        <Copyright color="#FFFFFF" fontFamily="Poppins-Medium" />
      </Grid>
    </Container>
  );
}
