import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Navbar from "./navbar.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";


export default function MarketingVouchers() {
  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);
  let navigate = useNavigate();

  useEffect(() => {
    if (companyDetails === null || !loginData) {
      console.log("redirecting to /Login");
      navigate("/Login");
    }
  }, []);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Navbar name="MarketingVouchers">
      <div style={{ paddingBottom: "20px" }}></div>
    </Navbar>
  );
}
