import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./navbar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import lottie from "lottie-web";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Rocket from "../tools/animation/rocket.json";

export default function PublishCenter() {
  const rawCompanyDetails = localStorage.getItem("companyData");
  const [value, setValue] = useState(0);
  const companyDetails = JSON.parse(rawCompanyDetails);
  const navigate = useNavigate();
  const lottieContainer = useRef(null);
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);

  const handleUpgrade = async () => {
    navigate("/Upgrade");
  };

  // Load Rocket animation outside the loop
  lottie.loadAnimation({
    container: document.getElementById("lottie-container-rocket"),
    renderer: "svg",
    loop: true,
    autoplay: true,
    animationData: Rocket,
  });



  useEffect(() => {
    if (companyDetails === null || loginData === null) {
      console.log("redirecting to /Login");
      navigate("/Login");
    } else {
      companyDetails.IsDemoMode = false;
      console.log(companyDetails.IsDemoMode);


      const anim = lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: Rocket,
      });




    }
  }, []);

  if (companyDetails == null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Navbar name="Publish Center"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ p: 2 }}>
        <div>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#171663",
              fontFamily: "Poppins-Semibold",
              fontSize: "18px",
              marginLeft: "20px",
              marginTop: "0px",
            }}
          >
            Publish Center
          </Typography>
        </div>

        <Grid
          container
          direction="column"
          sx={{
            justifyContent: "start",
            alignItems: "flex-start", // Align items at the top
            width: "100%",
            flexWrap: "wrap",
            padding: "20px",
          }}
        >

          <Box
            ref={lottieContainer}
            sx={{
              width: "12%",
              height: "12%",
            }}
          ></Box>

          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#171663",
              fontFamily: "Poppins-Semibold",
              fontSize: "28px",
              marginTop: "0px",
            }}
          >
            Seamlessly Publish and Manage Your App
          </Typography>




          <Typography
            variant="body1"
            component="div"
            style={{
              color: "#171663",
              fontFamily: "Poppins-Regular",
              fontSize: "16px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            <ul>
              <li>Easy integration with app stores</li>
              <li>Automated build and deploy process</li>
              <li>Real-time analytics and monitoring</li>
              <li>Comprehensive documentation and support</li>
            </ul>
          </Typography>

          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 8,
              mb: 4,
              maxWidth: "300px",
            }}
            onClick={handleUpgrade}
          >
            Upgrade
          </Button>

        </Grid>
      </Box>
    </Navbar>
  );


}
