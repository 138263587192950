import React, { useState, useRef } from "react";
import barcode from "../tools/pngs/barcode.png";
import {
  Box,
  Typography,
} from "@mui/material";
import MyColor from "../components/my-color.js";
import { getFontTechnical } from "../components/font-select.js"
import { fontStyles } from '../theme/styles/font.js';
import RenderCompanyLogo from "../components/logo.js";


export const DesignAppCard = ({
  topBannerBackgroundColour,
  topBannerFontColour,
  topBannerFontStyle,
  
  mainBackgroundFontColour,
  mainBackgroundFontStyle,
  rewardCardBackgroundColor,
  rewardCardTextColor,
  availableFonts,
  companyLogo,
  companyLogoFileType,
}) => {

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        border: "none",
      }}
    >
      <style>{fontStyles}</style>

      {/* Top Banner */}
      <Box
        style={{
          height: "80px",
          backgroundColor: topBannerBackgroundColour,
          width: "100%",
          position: "absolute",
          border: 'none',
          top: 0,
          zIndex: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography className={getFontTechnical(availableFonts, topBannerFontStyle)}
          style={{ color: topBannerFontColour, marginTop: '35px' }}>
          My Card
        </Typography>
      </Box>

      {/* Reward Card */}
      <Box sx={{
        backgroundColor: rewardCardBackgroundColor,
        marginTop: '0px',
        width: '240px',
        height: '164px',
        position: "absolute",
        top: "100px",
        borderRadius: '8px',
        zIndex: 8
      }}>
        {/* Logo */}
        <RenderCompanyLogo
          companyLogo={companyLogo}
          companyLogoFileType={companyLogoFileType}
          maxH={"50px"}
          maxW={"50px"}
          style={{
            top: "5px",
            left: "15px",
            position: "absolute",
            zIndex: 10
          }}
        />
        <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)}
          style={{
            color: rewardCardTextColor,
            position: 'absolute',
            top: '57px',
            left: '17px',
            fontSize: '11px',
            fontWeight: 'bold',
            zIndex: 4
          }}>
          Demo Account
        </Typography>
      </Box>

      {/* barcode */}
      <Box sx={{
        width: "72%",
        top: "175px",
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',            // Center vertically
        justifyContent: 'center',        // Center horizontally
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        height: '56px',
        zIndex: 8,
      }}>
        <img
          src={barcode}
          alt="Barcode"
          style={{
            width: "210px",
            height: "46px",
            objectFit: "cover",
            zIndex: 10,
          }}
        />
      </Box>

      <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
        position: 'absolute',
        top: '234px',
        fontSize: '11px',
        color: rewardCardTextColor,
        zIndex: 22,
      }}>
        38176015024
      </Typography>
      <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
        position: 'absolute',
        top: '280px',
        fontSize: '12px',
        color: mainBackgroundFontColour,
        zIndex: 22,
        fontWeight: 'bold',
      }}>
        How to use my card
      </Typography>
    </Box>

  );
};


export const DesignFormCard = ({
  rewardCardTextColor,
  rewardCardBackgroundColor,
  setRewardCardTextColor,
  setRewardCardBackgroundColor,

  tabSelectedColor,
  setTabSelectedColor,
  tabDeselectedColor,
  setTabDeselectedColor,
  tabBackgroundColor,
  setTabBackgroundColor,

  maxWidth = 1000,
}) => {
  const [showCardTextColour, setShowCardTextColour] = useState(false);
  const [showCardBackgroundColour, setShowCardBackgroundColour] = useState(false);
  const [showTabBackgroundColor, setShowTabBackgroundColor] = useState(false);
  const [showTabSelectedColor, setShowTabSelectedColor] = useState(false);
  const [showTabDeselectedColor, setShowTabDeselectedColor] = useState(false);
  const itemWidth = '250px'

  return (
    <div>
      <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Rewards Card</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Background Colour"
              tooltip="The background colour of the reward card"
              color={rewardCardBackgroundColor}
              setColor={setRewardCardBackgroundColor}
              showColorPicker={showCardBackgroundColour}
              setShowColorPicker={setShowCardBackgroundColour}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Text Colour"
              tooltip="The text colour of the reward card"
              color={rewardCardTextColor}
              setColor={setRewardCardTextColor}
              showColorPicker={showCardTextColour}
              setShowColorPicker={setShowCardTextColour}
            />
          </Box>
        </div>
      </Box>

      <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Navigation Bar</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Background Color"
              tooltip="The background colour of the navigation bar"
              color={tabBackgroundColor}
              setColor={setTabBackgroundColor}
              showColorPicker={showTabBackgroundColor}
              setShowColorPicker={setShowTabBackgroundColor}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Selected Color"
              tooltip="The colour of the icon in the navigation bar for the selected tab"
              color={tabSelectedColor}
              setColor={setTabSelectedColor}
              showColorPicker={showTabSelectedColor}
              setShowColorPicker={setShowTabSelectedColor}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Deselected Color"
              tooltip="The colour of icons in the navigation bar that are unselected"
              color={tabDeselectedColor}
              setColor={setTabDeselectedColor}
              showColorPicker={showTabDeselectedColor}
              setShowColorPicker={setShowTabDeselectedColor}
            />
          </Box>
        </div>
      </Box>
    </div>
  )
};
