import { Box } from "@mui/material";
import TabPanel from "./tab-panel.js";


const DesignTab = ({ title, tabNumber, index, app, form }) => {
    return (
        <TabPanel value={tabNumber} index={index}>
            <h2 style={{ textAlign: "center", paddingBottom: "20px" }}>{title}</h2>
            <Box sx={{
                display: "flex",
                flexDirection: window.innerWidth < 700 ? "column" : "row",
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    width: 330,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    margin: '10px',
                }}>
                    {app}
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 330,
                    width: '65%',
                    textAlign: "center",
                    alignItems: 'flex-start',
                    margin: '10px',
                    marginTop: '15px',
                }}>
                    {form}
                </Box>
            </Box>
        </TabPanel>
    );
};

export default DesignTab