import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import MyColor from "../components/my-color.js";
import { FontSelect, getFontTechnical, getFontFriendly } from "../components/font-select.js"
import appImage642x349 from "../tools/pngs/Branding613x305.png";
import RenderCompanyLogo from "../components/logo.js";


export const DesignAppLogin = ({
  topBannerBackgroundColour,
  topBannerFontColour,
  topBannerFontStyle,
  mainBackgroundColour,
  mainBackgroundFontStyle,
  mainBackgroundFontColour,
  buttonFontStyle,
  buttonBackgroundColour,
  buttonFontColour,
  companyLogo,
  companyLogoFileType,
  availableFonts,
}) => {
  console.log('mainBackgroundFontStyle', mainBackgroundFontStyle)

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        border: "none",
      }}
    >
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            border: "none",
        }}>
            <Box
                sx={{
                    position: "relative",
                    width: "305px",
                    height: "614px",
                    backgroundColor: mainBackgroundColour,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 0
                }}>
                {/* phone body */}
                <img
                    src={appImage642x349}
                    alt="App Background"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        zIndex: 10,
                    }} />

                {/* top banner */}
                <Box
                    style={{
                        height: "80px",
                        backgroundColor: topBannerBackgroundColour,
                        width: "100%",
                        position: "absolute",
                        border: 'none',
                        top: 0,
                        zIndex: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Typography className={getFontTechnical(availableFonts, topBannerFontStyle)}
                        style={{ color: topBannerFontColour, marginTop: '35px' }}>
                        Log In
                    </Typography>
                </Box>

                {/* Logo */}
                <Box
                  style={{
                    position: "absolute",
                    top: '16%',
                  }}>
                    <RenderCompanyLogo 
                      companyLogo={companyLogo}
                      companyLogoFileType={companyLogoFileType}
                      maxH={"110px"}      
                      maxW={"110px"}
                    />
                </Box>

                <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)}
                style={{
                  position: 'absolute', 
                  top: '240px',
                  left: '50px',
                  fontSize: '10px',
                  color: mainBackgroundFontColour,
                  zIndex: 22,
                  }}>
                  Email Address
                </Typography>
                <div style={{
                  position: 'absolute',
                  top: '260px',
                  width: '100%',
                  textAlign: 'center'
                  }}>
                  <input
                    type="text"
                    placeholder=""
                    style={{
                      width: '210px',
                      height: '35px',
                      padding: '10px',
                      fontSize: '16px',
                      borderRadius: '5px',
                      border: '1px solid #ccc'
                    }}
                  />
                </div>

                <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)}
                style={{
                  position: 'absolute', 
                  top: '315px',
                  left: '50px',
                  fontSize: '10px',
                  color: mainBackgroundFontColour,
                  zIndex: 22,
                  }}>
                  Password
                </Typography>
                <div style={{
                  position: 'absolute',
                  top: '335px',
                  width: '100%',
                  textAlign: 'center'
                  }}>
                  <input
                    type="text"
                    placeholder=""
                    style={{
                      width: '210px',
                      height: '35px',
                      padding: '10px',
                      fontSize: '16px',
                      borderRadius: '5px',
                      border: '1px solid #ccc'
                    }}
                  />
                </div>

                {/* Button */}
                <Button
                  style={{
                    height: "30px",
                    position: "absolute",
                    bottom: '185px',
                    backgroundColor: buttonBackgroundColour,
                    border: `1px solid ${buttonBackgroundColour}`,
                    color: buttonFontColour,
                    width: "210px",
                    zIndex: 3,
                    borderRadius: '15px',
                  }}>
                  <Typography className={getFontTechnical(availableFonts, buttonFontStyle)}
                    style={{ color: buttonFontColour, fontSize: '11px' }}>
                    Sign In
                  </Typography>
                </Button>
                
                <Typography className={getFontTechnical(availableFonts, mainBackgroundFontStyle)} style={{
                  position: 'absolute', 
                  bottom: '155px',
                  fontSize: '9px',
                  color: mainBackgroundFontColour,
                  zIndex: 22,
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  }}>
                  Don't have an Account? Register Here!
                </Typography>
            </Box>
        </Box>
    </Box>
  );
};

export const DesignFormLogin = ({
  topBannerBackgroundColour,
  setTopBannerBackgroundColour,
  topBannerFontColour,
  setTopBannerFontColour,
  topBannerFont,
  setTopBannerFont,
  topBannerFontStyle,
  setTopBannerFontStyle,

  mainBackgroundColour,
  setMainBackgroundColour,
  mainBackgroundFontColour,
  setMainBackgroundFontColour,
  mainBackgroundFont,
  setMainBackgroundFont,
  mainBackgroundFontStyle,
  setMainBackgroundFontStyle,

  buttonFont,
  setButtonFont,
  buttonFontStyle,
  setButtonFontStyle,
  buttonBackgroundColour,
  setButtonBackgroundColour,
  buttonFontColour,
  setButtonFontColour,

  availableFonts,
  maxWidth = 1000,
}) => {
  const [showTopBannerBackgroundColour, setShowTopBannerBackgroundColour] = useState(false);
  const [showTopBannerFontColour, setShowTopBannerFontColour] = useState(false);
  const [showMainBackgroundColour, setShowMainBackgroundColour] = useState(false);
  const [showMainBackgroundFontColour, setShowMainBackgroundFontColour] = useState(false);
  const [showButtonBackgroundColour, setShowButtonBackgroundColour] = useState(false);
  const [showButtonFontColour, setShowButtonFontColour] = useState(false);
  const itemWidth = '250px'

  return (
    <div>
      <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Top Banner</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Background Colour"
              tooltip="The background colour of the top banner"
              color={topBannerBackgroundColour}
              setColor={setTopBannerBackgroundColour}
              showColorPicker={showTopBannerBackgroundColour}
              setShowColorPicker={setShowTopBannerBackgroundColour}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Font Colour"
              tooltip="The colour of the font in the top banner"
              color={topBannerFontColour}
              setColor={setTopBannerFontColour}
              showColorPicker={showTopBannerFontColour}
              setShowColorPicker={setShowTopBannerFontColour}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <FontSelect
              name={"Font"}
              tooltip={"Choose the text style for the top banner"}
              availableFonts={availableFonts}
              font={topBannerFont}
              setFont={setTopBannerFont}
              fontStyle={topBannerFontStyle}
              setFontStyle={setTopBannerFontStyle}
            />
          </Box>
        </div>
      </Box>


      <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Main Section</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Background Colour"
              tooltip="The main background colour"
              color={mainBackgroundColour}
              setColor={setMainBackgroundColour}
              showColorPicker={showMainBackgroundColour}
              setShowColorPicker={setShowMainBackgroundColour}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Background Font Colour"
              tooltip="The main background font colour"
              color={mainBackgroundFontColour}
              setColor={setMainBackgroundFontColour}
              showColorPicker={showMainBackgroundFontColour}
              setShowColorPicker={setShowMainBackgroundFontColour}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <FontSelect
              name={"Font"}
              tooltip={"Choose the style of text used in the main window across the app"}
              availableFonts={availableFonts}
              font={mainBackgroundFont}
              setFont={setMainBackgroundFont}
              fontStyle={mainBackgroundFontStyle}
              setFontStyle={setMainBackgroundFontStyle}
            />
          </Box>
        </div>
      </Box>

      <Box sx={{
        border: "1px solid rgb(200, 200, 200)",
        margin: '5px',
        marginBottom: '25px',
        maxWidth: { maxWidth }
      }}>
        <Box sx={{ textAlign: "left", padding: '10px' }}>
          <strong>Button Style</strong>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Background Colour"
              tooltip="The background colour of buttons across the app"
              color={buttonBackgroundColour}
              setColor={setButtonBackgroundColour}
              showColorPicker={showButtonBackgroundColour}
              setShowColorPicker={setShowButtonBackgroundColour}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <MyColor
              name="Font Colour"
              tooltip="The font colour of buttons across the app"
              color={buttonFontColour}
              setColor={setButtonFontColour}
              showColorPicker={showButtonFontColour}
              setShowColorPicker={setShowButtonFontColour}
            />
          </Box>
          <Box sx={{ border: "none", minWidth: itemWidth, padding: '10px', }}>
            <FontSelect
              name={"Font"}
              tooltip={"Choose a font for your buttons"}
              availableFonts={availableFonts}
              font={buttonFont}
              setFont={setButtonFont}
              fontStyle={buttonFontStyle}
              setFontStyle={setButtonFontStyle}
            />
          </Box>
        </div>
      </Box>

    </div>
  )
};
