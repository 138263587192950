import React, { useState } from "react";
import { Button, SvgIcon } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Styles from "../resources/styles";
import ImageBox from "./sharedcomponents/ImageBox";
import Icon from "../resources/question.svg";

function LogoContent({ onCancel, onSave }) {
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [imageSrc, setImageSrc] = useState(null); // State for the image source

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result); // Set the image source to the uploaded image
      };
      reader.readAsDataURL(file);
      setUploadedFileName(file.name); // Store the file name
    }
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Image</div>
        <div style={Styles.content}>
          <ImageBox />
          {uploadedFileName && <p>{uploadedFileName}</p>}{" "}
        </div>

        <div style={Styles.content}>
          <div>
            Click the upload image button above to upload your own logo. We
            support
            <strong> .svg .png .jpg</strong> file types. We strongly recommend
            using .svg or .png files as they are higher quality.
          </div>
          <div style={{ paddingBottom: "20px" }}></div>
          <input
            type="file"
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.cancelBtn} onClick={onCancel}>
          Reset
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default LogoContent;
