import React, { useState } from "react";
import Styles from "../resources/styles";
import { ReactComponent as PenIcon } from "../resources/pen.svg";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";

function LaunchScreenContent({ onCancel, onSave }) {
  const [selectedColour, setSelectedColour] = useState("#b0a223"); // Default color
  const textColor = selectedColour === "#ffffff" ? "black" : "white";

  const handleChangeComplete = (color) => {
    setSelectedColour(color.hex);
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Background</div>
        <div style={Styles.content}>
          <ColourBox />
        </div>
        <div style={Styles.sectionTitle}>Image</div>
        <div style={Styles.content}>
          <ImageBox />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.cancelBtn} onClick={onCancel}>
          Reset
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default LaunchScreenContent;
