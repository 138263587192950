import { ApiServiceClass } from "../components/api-service.js";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { HomeToolBar } from "../components/home-toolbar.js";
import { validateEmail } from "../components/validation.js";
import { Box, Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactUs() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [validForm, setValidForm] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    console.log(email, subject, message);
    var captcha = await verifyCaptcha();
    if (captcha) {
      checkForm();
      if (email === "" || subject === "" || message === "") {
        toast.error("Please complete all fields", {
          autoClose: 2000,
        });
      } else if (!validForm) {
        toast.error("Form invalid. Please check your email");
      } else {
        try {
          const requestBody = {
            EmailAddress: email,
            Subject: subject,
            Message: message,
          };
          console.log(requestBody);

          const data = await ApiServiceClass.ContactUs(requestBody);
          console.log(data);
          if (data.status == 200) {
            toast.success("Message sent successfully!", {
              autoClose: 2000,
            });
          }

          // Reset fields after successful submission
          setEmail("");
          setSubject("");
          setMessage("");
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  useEffect(() => {
    checkForm();
  }, [message, email, subject]);

  const checkForm = () => {
    console.log("checking form");
    const emailmsg = validateEmail(email);
    if (!emailmsg && subject != "" && message != "") {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
    console.log(validForm);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const verifyCaptcha = async (event) => {
    try {
      const request = {
        captcha: captchaValue,
      };

      console.log("calling captcha: ", request);
      const response = await ApiServiceClass.VerifyCaptcha(request);
      if (response.status === 200) {
        console.log("Success: Captcha verified successfully");
        return true; // Return true indicating successful captcha verification
      } else {
        console.log("Error: Captcha verification failed");
        toast.error("Captcha verification failed");
        return false; // Return false indicating failed captcha verification
      }
    } catch (error) {
      console.error("Error during captcha verification:", error);
      // Handle the error here, e.g., show an error message to the user
      toast.error("Error during captcha verification");
      return false; // Return false indicating failed captcha verification due to error
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        maxHeight: "100%",
        minHeight: "100vh",
        minWidth: "360px",
        background: "#FFFFFF",
        display: "flex",
        overflowX: "hidden",
        overflowY: "hidden",
        flexDirection: "column",
      }}
    >
      {/* ToolBar */}
      <HomeToolBar />
      <div style={{ paddingBottom: "10px" }}></div>
      <div
        style={{
          width: "101%", // Set to 100% to fit page width
          minHeight: "780px",
          background: "linear-gradient(to bottom right, #EDF0FA, #EDF0FA)",
          backgroundSize: "cover", // Ensure background covers the entire area
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          transform: "rotate(-2deg)",
          transition: "all 0.5s ease",
          boxSizing: "border-box", // Ensure box-sizing
          marginTop: 40,
          marginLeft: "-10px",
        }}
      >
        <Box
          sx={{
            // width: "600px",
            // backgroundColor: '#333333',
            padding: "15px",
            margin: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // marginLeft: "0 auto",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{
              transform: "rotate(2deg)",
              color: "#171663",
              fontFamily: "Poppins-Semibold",
              fontSize: "28x",
              paddingBottom: "0px",
              marginTop: "25px",
            }}
          >
            Please use our contact form below
          </Typography>
        </Box>
        <Box
          sx={{
            transform: "rotate(2deg)",
            width: "90%",
            maxWidth: "1200px",
            padding: "15px",
            bgcolor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "left",
            borderRadius: "10px",
          }}
        >
          <TextField
            margin="normal"
            required
            id="email"
            label="Email address"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            value={email}
            sx={{ width: "100%", maxWidth: "600px" }}
          />

          <TextField
            margin="normal"
            required
            id="subject"
            label="Subject"
            type="subject"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            sx={{ width: "100%", maxWidth: "600px" }}
          />

          <TextField
            margin="normal"
            required
            id="message"
            label="Message"
            type="text" // "type" prop can be omitted for multiline text field
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            rows={7} // Specifies the default number of rows for the multiline input
            style={{ width: "100%" }}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2px",
              }}
            >
              <ReCAPTCHA
                sitekey="6LcGsgwqAAAAAK28rAWl89KR5j7xBHdBOQvkNpJW"
                onChange={handleCaptchaChange}
              />
            </div>
            <Button
              disabled={!captchaValue}
              type="submit"
              fontFamily="Poppins-Medium"
              onClick={handleSubmit}
              sx={{
                mt: 1,
                mb: 1,
                width: "26%",
                height: "50px",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}
