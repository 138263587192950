import React, { useState } from "react";
import {
  Box,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import DesignAppBackground from "../components/design-app-background.js";
import TabPanel from "../components/tab-panel.js";
import { DesignAppHome } from "./design-home.js";
import { DesignAppCard } from "./design-mycard.js";
import { DesignAppOffers } from "./design-offers.js";
import { DesignAppSettings } from "./design-settings.js";
import { fontStyles } from '../theme/styles/font.js';
import { getFont } from "../components/font-select.js"


export const DesignAppInteractive = ({
  // splash
  companyLogo,
  companyLogoFileType,
  // login 
  topBannerBackgroundColour,
  topBannerFontColour,
  topBannerFontStyle,
  mainBackgroundFontColour,
  mainBackgroundFontStyle,
  mainBackgroundColour,
  buttonBackgroundColour,
  buttonFontColour,
  buttonFontStyle,
  availableFonts,
  // mycard
  tabBackgroundColor,
  tabSelectedColor,
  tabDeselectedColor,
  rewardCardBackgroundColor,
  rewardCardTextColor,
  // home
  stamp,
  stampName,
  stampColor,
  stampBackgroundColor,
  backgroundColor,
  rewardName,
  rewardSubtitle,
  rewardTextColor,
  noPurchases,
  // other
  initialTab=1,
}) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  return (
    <Box
      sx={{
        backgroundColor: "test.2",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        border: "none",
      }}
    >
      <style>{fontStyles}</style>
      {/* Tabs */}
      <Tabs
        value={activeTab}
        onChange={(event, newTabNumber) => {
          setActiveTab(newTabNumber);
        }}
        aria-label="Register Tabs"
        sx={{ minWidth: "120px", display: "none" }}
      >
        <Tab
          className="tab"
          name="Tab1"
          label="Home"
          value={1}
          sx={{ marginLeft: 3 }}
        />
        <Tab
          className="tab"
          name="Tab2"
          label="MyCard"
          value={2}
          sx={{ marginLeft: 3 }}
        />
        <Tab
          className="tab"
          name="Tab3"
          label="Offers"
          value={3}
          sx={{ marginLeft: 3 }}
        />
        <Tab
          className="tab"
          name="Tab4"
          label="Settings"
          value={4}
          sx={{ marginLeft: 3 }}
        />
      </Tabs>

      <DesignAppBackground
        mainBackgroundColour={mainBackgroundColour}
        tabBackgroundColor={tabBackgroundColor}
        tabSelectedColor={tabSelectedColor}
        tabDeselectedColor={tabDeselectedColor}
        availableFonts={availableFonts}
        setActiveTab={setActiveTab}
        selectedTab={activeTab}
      >

        <TabPanel value={activeTab} index={1}>
          <Box
            sx={{
              display: "flex",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            <DesignAppHome
              topBannerBackgroundColour={topBannerBackgroundColour}
              topBannerFontColour={topBannerFontColour}
              topBannerFontStyle={topBannerFontStyle}
              buttonBackgroundColour={buttonBackgroundColour}
              buttonFontColour={buttonFontColour}
              // buttonFont={buttonFont}
              buttonFontStyle={buttonFontStyle}
              mainBackgroundFontStyle={mainBackgroundFontStyle}
              availableFonts={availableFonts}
              companyLogo={companyLogo}
              companyLogoFileType={companyLogoFileType}
              stamp={stamp}
              stampName={stampName}
              stampColor={stampColor}
              stampBackgroundColor={stampBackgroundColor}
              backgroundColor={backgroundColor}
              rewardName={rewardName}
              rewardSubtitle={rewardSubtitle}
              rewardTextColor={rewardTextColor}
              rewardCardTextColor={rewardCardTextColor}
              // outlineButtonBorderColor={outlineButtonBorderColor}
              // outlineButtonTextColor={outlineButtonTextColor}
              noPurchases={noPurchases}
            />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Box
            sx={{
              display: "flex",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            <DesignAppCard
              topBannerBackgroundColour={topBannerBackgroundColour}
              topBannerFontColour={topBannerFontColour}
              topBannerFontStyle={topBannerFontStyle}
              mainBackgroundFontColour={mainBackgroundFontColour}
              mainBackgroundFontStyle={mainBackgroundFontStyle}
              rewardCardBackgroundColor={rewardCardBackgroundColor}
              rewardCardTextColor={rewardCardTextColor}
              availableFonts={availableFonts}
              companyLogo={companyLogo}
              companyLogoFileType={companyLogoFileType}
            />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Box
            sx={{
              display: "flex",
              marginTop: "5%",
              textAlign: "center",
            }}
          >
            <DesignAppOffers
                topBannerBackgroundColour={topBannerBackgroundColour}
                topBannerFontColour={topBannerFontColour}
                topBannerFontStyle={topBannerFontStyle}
                availableFonts={availableFonts}
            />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              width: "100%",
              top: '0px',
              left: '0px',
              // paddingTop: '20px',
            }}
          >
            <DesignAppSettings
                topBannerBackgroundColour={topBannerBackgroundColour}
                topBannerFontColour={topBannerFontColour}
                topBannerFontStyle={topBannerFontStyle}
                availableFonts={availableFonts}
                mainBackgroundFontStyle={mainBackgroundFontStyle}
            />
          </Box>
        </TabPanel>
      </DesignAppBackground>
    </Box>
  );
};
