import {
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Select,
  MenuItem,
  SvgIcon,
} from "@mui/material";

import { SettingsAccountPanel } from "../components/settings-account-panel.js";
import { SettingsSubscriptionPanel } from "../components/settings-subscriptions-panel.js";
import { SettingsPaymentsPanel } from "../components/settings-payments-panel.js";
import { SettingsOtherPanel } from "../components/settings-other-panel.js";
import Navbar from "./navbar.js";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { styled } from "@mui/system";
import { ApiServiceClass } from "../components/api-service.js";
import { toast } from "react-toastify";

export default function Settings() {
  let navigate = useNavigate();
  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);

  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDeleteOpen = () => setDeleteDialogOpen(true);
  const handleDeleteClose = () => setDeleteDialogOpen(false);

  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
  const handleCancelOpen = () => setCancelDialogOpen(true);
  const handleCancelClose = () => setCancelDialogOpen(false);

  const [visible, setVisible] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState("Month");
  const [selectedYear, setSelectedYear] = useState("Year");
  const [subscriptionId, setSubscriptionId] = useState("");

  const [subscriptionName, setSubscriptionName] = useState("");
  const [hasBeenCancelled, setHasBeenCancelled] = useState("");
  const [SubscriptionEndDateTime, setSubscriptionEndDateTime] = useState("");
  const [IsSubscriptionActive, setIsSubscriptionActive] = useState("");
  const [LastInvoiceDate, setLastInvoiceDate] = useState("");
  const CenteredDialogActions = styled(DialogActions)({
    display: "flex",
    justifyContent: "center",
  });

  const handleDelete = async () => {
    console.log("Handling delete account");
    var requestBody = {
      UserId: loginData.UserId,
    };
    console.log(requestBody);
    try {
      var response = await ApiServiceClass.DeleteAccount(requestBody);
      console.log(response); // Log the response for debugging
      if (response.status === 200) {
        toast.success("Account deleted");
        localStorage.removeItem("companyData");
        localStorage.removeItem("loginData");
        navigate("/Login");
      } else {
        toast.error("Error: " + response.text); // Display the error message
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error("Error deleting account: " + error.message);
    }
  };

  const handleCancelYes = async () => {
    try {
      var requestBody = {
        CompanyId: companyDetails.Id,
      };
      console.log("Cancelling subscription");
      await ApiServiceClass.CancelSubscription(requestBody);
      setCancelDialogOpen(false);
      toast.success("Subscription cancelled", {
        autoClose: 2500,
      });
      await GetSubscription();
      setHasBeenCancelled(true);
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      toast.error("Failed to cancel subscription", {
        autoClose: 2500,
      });
    }
  };

  useEffect(() => {
    if (companyDetails === null || loginData === null) {
      console.log("redirecting to /Login");
      navigate("/Login");
    }

    console.log(companyDetails);
    if (companyDetails.IsDemoMode === false) {
      console.log("Getting subscription info");
      GetSubscription();
    }
  }, [setHasBeenCancelled, setSubscriptionId]);

  const CancelSubscription = async () => {
    try {
      var requestBody = {
        CompanyId: companyDetails.Id,
      };
      const data = await ApiServiceClass.CancelSubscription(requestBody);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const GetSubscription = async () => {
    try {
      const requestBody = {
        CompanyId: companyDetails.Id,
      };
      setLoading(true);
      const data = await ApiServiceClass.GetSubscription(requestBody);
      console.log("Full Response Data:", data);
      setSubscriptionName(data.SubscriptionTypeName);
      setIsSubscriptionActive(data.IsSubscriptionActive);
      setHasBeenCancelled(data.HasBeenCancelled);
      setSubscriptionEndDateTime(data.SubscriptionEndDateTime);
      setLastInvoiceDate(data.LastInvoiceDate);
      setSubscriptionId(data.StripeSubscriptionId);
      console.log("sub ID: ", data.StripeSubscriptionId);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePasswordChange = () => {
    // Navigate to the internal page when the button is clicked
    navigate("/reset"); // Replace "/your-internal-page" with the actual path of your internal page
  };

  const handleClickDelete = () => {
    console.log("Handle click delete");
    setDeleteDialogOpen(true);
  };

  const handleCancelSubscription = () => {
    console.log("Handle cancel subscription");
    setCancelDialogOpen(true);
  };

  const handleUpgradeClick = () => {
    console.log("Upgrade");
    navigate("/upgrade");
  };

  if (companyDetails == null || loading == true) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Navbar name="Settings">
      <SettingsAccountPanel
        title={"Account Settings"}
        subheader={"Manage your account"}
        emailAddress={loginData.EmailAddress}
        handlePasswordChange={handlePasswordChange}
        handleClickDelete={handleClickDelete}
      ></SettingsAccountPanel>
      <SettingsSubscriptionPanel
        title={"Subscription Settings"}
        subheader={"Manage your subscription"}
        handleUpgradeClick={handleUpgradeClick}
        handleCancelSubscription={handleCancelSubscription}
        SubscriptionPlan={subscriptionName}
        hasBeenCancelled={hasBeenCancelled}
        isDemoMode={companyDetails.IsDemoMode}
        SubscriptionEndDateTime={SubscriptionEndDateTime}
        IsSubscriptionActive={IsSubscriptionActive}
        LastInvoiceDate={LastInvoiceDate}
      ></SettingsSubscriptionPanel>
      <SettingsOtherPanel
        title={"Other Settings"}
        subheader={"Manage any other settings"}
      ></SettingsOtherPanel>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteClose}
        PaperProps={{ style: { borderRadius: "5px", width: "400px" } }}
      >
        <DialogTitle style={{ paddingBottom: "5px", textAlign: "center" }}>
          Warning!
        </DialogTitle>
        <DialogContent
          style={{
            paddingBottom: "15px",
            textAlign: "center", // Aligns text horizontally in the center
          }}
        >
          Are you sure you want to delete your account?
          <br />
          <br></br>
          <b>This action cannot be undone</b>
        </DialogContent>
        <CenteredDialogActions
          style={{
            paddingBottom: "25px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center", // Aligns text horizontally in the center
          }}
        >
          <Button
            onClick={handleDelete}
            color="primary"
            style={{ width: "30%", marginRight: "35px", borderRadius: "5px" }}
          >
            Yes
          </Button>
          <Button
            onClick={handleDeleteClose}
            color="primary"
            style={{ width: "30%", borderRadius: "5px" }}
          >
            No
          </Button>
        </CenteredDialogActions>
      </Dialog>

      <Dialog
        open={isCancelDialogOpen}
        onClose={handleCancelClose}
        PaperProps={{ style: { borderRadius: "5px", width: "400px" } }}
      >
        <DialogTitle style={{ paddingBottom: "5px", textAlign: "center" }}>
          Are you sure?
        </DialogTitle>
        <DialogContent
          style={{
            paddingBottom: "15px",
            textAlign: "center", // Aligns text horizontally in the center
          }}
        >
          Are you sure you want to cancel your subscription?
          <br />
        </DialogContent>
        <CenteredDialogActions
          style={{
            paddingBottom: "25px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center", // Aligns text horizontally in the center
          }}
        >
          <Button
            onClick={handleCancelYes}
            color="primary"
            style={{ width: "30%", marginRight: "35px", borderRadius: "5px" }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCancelClose}
            color="primary"
            style={{ width: "30%", borderRadius: "5px" }}
          >
            No
          </Button>
        </CenteredDialogActions>
      </Dialog>
    </Navbar>
  );
}
