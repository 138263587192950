import React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { OverviewGraph } from "../sections/dashboard-graph.js";
const StatsGraph = ({ axis = [], values = [], CompanyId, graphTitle }) => {
  return (
    <OverviewGraph
      graphName={graphTitle}
      xAxisCategories={axis}
      chartSeries={[
        {
          name: "Total",
          data: values,
        },
      ]}
    />
  );
};

export default StatsGraph;
