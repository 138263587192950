import React from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import TextBox from "./sharedcomponents/TextBox";
import NumberBox from "./sharedcomponents/NumberBox";

function RewardContent({ onCancel, onSave }) {
  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Reward Details</div>
        <div style={Styles.content}>
          Reward Title
          <TextBox defaultText="Reward title" />
          Reward Subtitle
          <TextBox defaultText="Reward subtitle" />
          Purchases Required
          <NumberBox />
          Reward Text Colour
          <ColourBox />
        </div>
        <div style={Styles.sectionTitle}>Stamp Details</div>
        <div style={Styles.content}>
          Stamp Icon rrrr -- Stamp Colour
          <ColourBox />
          Stamp Border Colour
          <ColourBox />
          Panel Background Colour
          <ColourBox />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.cancelBtn} onClick={onCancel}>
          Reset
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}
export default RewardContent;
