import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SketchPicker } from "react-color";

function FontBox({ onCancel, onSave }) {
  const [selectedFont, setSelectedFont] = useState("Arial"); // Default font

  const handleFontChange = (e) => {
    setSelectedFont(e.target.value);
  };

  return (
    <div>
      <FormControl
        style={{ width: "40%", marginBottom: "10px", marginTop: "5px" }}
      >
        {/* <InputLabel id="font-select-label">Font</InputLabel> */}

        <Select
          labelId="font-select-label"
          id="font-select"
          value={selectedFont}
          style={{ backgroundColor: "#fff" }} // Apply color styling here
          onChange={handleFontChange}
        >
          <MenuItem value="Arial">Arial</MenuItem>
          <MenuItem value="Courier New">Courier New</MenuItem>
          <MenuItem value="Georgia">Georgia</MenuItem>
          <MenuItem value="Times New Roman">Times New Roman</MenuItem>
          <MenuItem value="Verdana">Verdana</MenuItem>
          {/* Add more fonts as needed */}
        </Select>
      </FormControl>
    </div>
  );
}

export default FontBox;
